

import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Schedule_State from "../../../../lib/state/Schedule_State.mjs";
import * as Schedule_Header_FilterComponent from "./Schedule_Header_FilterComponent.mjs";
import * as Schedule_Header_FilterModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/header/right/filter/Schedule_Header_Filter.module.css";

var styles = Schedule_Header_FilterModuleCss;

var territories$p = Utils.Translations.tr("js.schedule.v2.filter.territories");

var selectedTerritories$p = Utils.Translations.tr("js.schedule.v2.filter.selected_territories");

var emptyTerritories$p = Utils.Translations.tr("js.schedule.v2.filter.empty.territories");

var territoriesLabel$p = Utils.Translations.tr("js.schedule.v2.filter.territories_label");

function Schedule_Header_FilterByTerritories(Props) {
  var filterVariants = Props.filterVariants;
  var param = Props.wire;
  var ctx = param.ctx;
  var filter = Schedule_State.useFilter();
  var setFilterTerritories = Schedule_State.useSetFilterTerritories();
  return React.createElement(Schedule_Header_FilterComponent.make, {
              filterVariants: filterVariants,
              selectedFilterVariants: filter.territories,
              setFilter: setFilterTerritories,
              label: territories$p(ctx),
              labelSelected: selectedTerritories$p(ctx),
              labelEmpty: emptyTerritories$p(ctx),
              children: React.createElement("label", {
                    className: styles.label
                  }, territoriesLabel$p(ctx))
            });
}

var make = Schedule_Header_FilterByTerritories;

export {
  make ,
}
/* styles Not a pure module */
