

import * as El from "../../../../libraries/El.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../common/Optional.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Context_Types from "../../../../context/Context_Types.mjs";
import * as Schedule_State from "../../lib/state/Schedule_State.mjs";
import * as Schedule_UnscheduledJobs from "./components/Schedule_UnscheduledJobs.mjs";
import * as Schedule_UnscheduledHooks from "./Schedule_UnscheduledHooks.mjs";
import * as Schedule_UnscheduledButton from "./components/Schedule_UnscheduledButton.mjs";
import * as Schedule_UnscheduledFilterByTemplates from "./components/filter/Schedule_UnscheduledFilterByTemplates.mjs";
import * as Schedule_UnscheduledModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/uscheduled_jobs/Schedule_Unscheduled.module.css";

var unscheduled$p = Utils.Translations.tr("js.schedule.v2.jobs.unscheduled");

var styles = Schedule_UnscheduledModuleCss;

function Schedule_Unscheduled(Props) {
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var visible = Schedule_State.useShowUnscheduledJobs();
  var toggleVisible = Schedule_State.useToggleUnscheduledJobs();
  var toggleBlock = function (_event) {
    toggleVisible();
  };
  var match$1 = Schedule_UnscheduledHooks.Droppable.use();
  var dropRef = match$1[0];
  Schedule_UnscheduledHooks.UpdateRightPadding.use(dropRef);
  var className = El.Cn.concat(El.Cn.concat(styles.container, match$1[1] ? styles.hovered : ""), visible ? styles.visible : "");
  return React.createElement("div", {
              className: styles.wrapper
            }, React.createElement(Schedule_UnscheduledButton.make, {
                  toggleBlock: toggleBlock
                }), React.createElement("div", {
                  ref: dropRef,
                  className: className
                }, React.createElement("div", {
                      className: styles.content
                    }, React.createElement("div", {
                          className: styles.header
                        }, unscheduled$p(ctx), React.createElement(Optional.make, {
                              show: Context_Types.Features.hasFlag("scheduleTemplateFilter", ctx.features),
                              children: React.createElement(Schedule_UnscheduledFilterByTemplates.make, {})
                            })), React.createElement(Schedule_UnscheduledJobs.make, {}))));
}

var make = React.memo(Schedule_Unscheduled);

export {
  make ,
}
/* unscheduled' Not a pure module */
