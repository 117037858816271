import React from 'react'
import { validateForm, scrollToError, formHasError } from './validators'
import JobReport from './job_report'
import JobCatalog from './job_catalog'
import RelatedEntities from 'rscrpt/components/common/RelatedEntities/RelatedEntities.mjs'
import {
  ListType,
  PageType,
} from 'rscrpt/components/common/RelatedEntities/RelatedEntitiesTypes.mjs'
import Settings from 'rscrpt/components/admin/template/Template_Settings.mjs'
import TemplateFooter from 'rscrpt/components/admin/template/Template_Footer.mjs'
import TemplateHeading from 'rscrpt/components/admin/template/Template_Heading.mjs'
import TemplateResolutions from 'rscrpt/components/admin/template/Template_Resolutions.mjs'
import TemplateJobInfo from 'rscrpt/components/admin/template/Template_Job_Info.mjs'
import { Template } from 'rscrpt/components/admin/template/Template_Types.mjs'
import Hint from 'planado/components/hint'
import CustomFieldsLink from 'planado/containers/admin/templates/custom_fields_link'
import { withContext } from 'planado/utils/contextConsumer.jsx'

export default withContext(
  ({
    template,
    types,
    possibleAssignees,
    actions,
    resolutions,
    possibleResolutionIds,
    customFields,
    errors,
    reportFields,
    wire,
    ctx,
    deleteTemplateRe,
    onSubmitTemplateRe,
    showServerErrorMsgRe,
    showFormErrorMsgRe,
    redirectToIndexRe,
    availableTerritories,
    territoryUuid,
    skillUuids,
    formDisabled,
    foremanError
  }) => {
    const numberOfFields = customFields.filter(
      (field) => !field.isDestroyed
    ).length

    const sumbitHandler = (e) => {
      const updatedErrors = validateForm(
        errors,
        template,
        customFields,
        reportFields,
        resolutions,
        actions,
        ctx
      )

      e.preventDefault()

      if (formHasError(updatedErrors)) {
        scrollToError(updatedErrors)
        showFormErrorMsgRe()
      } else if (!formDisabled) {
        actions.submitTemplate({
          onSuccess: onSubmitTemplateRe,
          onFailure: showServerErrorMsgRe,
          onNotFound: redirectToIndexRe,
          ctx,
        })
      }
    }
    return (
      <div>
        <form
          className="form form_template"
          method="post"
          onSubmit={sumbitHandler}
          id={
            template.isNewRecord
              ? 'new_template'
              : `edit_template_${template.uuid}`
          }
        >
          <TemplateHeading
            name={template.name || ''}
            isNewRecord={template.isNewRecord}
          />

          <TemplateJobInfo
            template={Template.fromJs(template)}
            types={types}
            possibleAssignees={possibleAssignees}
            setField={actions.setField}
            setError={(k, error) => actions.setError('template', {
              [k]: [error],
            })}
            changeSkills={actions.changeSkills}
            checkTemplateName={(value, uuid, isNew) =>
              actions.checkTemplateName(ctx, value, uuid, isNew)
            }
            errors={errors}
            skillUuids={skillUuids}
            territoryUuid={territoryUuid}
            availableTerritories={availableTerritories}
            changeTerritory={actions.changeTerritory}
            setAssignees={actions.setAssignees}
          >
            <h2 style={{
              margin: '15px 5px 20px',
              padding: 0,
              fontSize: '13px',
              fontWeight: 700,
              lineHeight: 1.2,
              color: '#000'
            }}>
              {ctx.t('js.templates.info.titles.custom_fields')}
              {numberOfFields === 0 ? null : (
                <Hint
                  content={ctx.t('js.templates.info.hints.custom_fields')}
                  modifiers={{ hint_title: true }}
                />
              )}
            </h2>
            <CustomFieldsLink />
          </TemplateJobInfo>

          <JobReport />

          {ctx.features.flags.includes('catalog') &&
            <JobCatalog
              setServices={actions.setServices}
              setCategories={actions.setCategories}
            />
          }

          <Settings
            wire={wire}
            trackMovements={template.trackMovements}
            setTrackMovements={actions.setTrackMovements}
            canBeUsedOnMobile={template.canBeUsedOnMobile}
            setCanBeUsedOnMobile={actions.setCanBeUsedOnMobile}
          />

          <TemplateResolutions
            wire={wire}
            resolutions={resolutions}
            errors={errors.resolutions}
            possibleResolutionIds={possibleResolutionIds}
            moveResolution={actions.moveResolution}
            deleteResolution={actions.deleteResolution}
            setError={actions.setError}
            addResolutions={actions.addResolutions}
          />

          <TemplateFooter
            isNewRecord={template.isNewRecord}
            uuid={template.uuid}
            recurringJobs={template.recurringJobs}
            formDisabled={formDisabled}
            foremanError={foremanError}
            deleteTemplate={deleteTemplateRe}
          />
        </form>

        {
          template.isNewRecord ? null : (
            <RelatedEntities
              uuid={template.uuid}
              listType={ListType.fromString('jobs_and_rec')}
              pageType={PageType.fromString('template')}
              wire={wire}
            />
          )
        }
      </div >
    )
  }
)
