


function reducer(state, action) {
  if (typeof action !== "object") {
    switch (action) {
      case "ToggleUnscheduledJobs" :
          var init = state.settings;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: init.filter,
                      businessHours: init.businessHours,
                      showUnscheduledJobs: !state.settings.showUnscheduledJobs,
                      locked: init.locked,
                      mode: init.mode,
                      jobDuration: init.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "LockSchedule" :
          var init$1 = state.settings;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: init$1.filter,
                      businessHours: init$1.businessHours,
                      showUnscheduledJobs: init$1.showUnscheduledJobs,
                      locked: true,
                      mode: init$1.mode,
                      jobDuration: init$1.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "UnlockSchedule" :
          var init$2 = state.settings;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: init$2.filter,
                      businessHours: init$2.businessHours,
                      showUnscheduledJobs: init$2.showUnscheduledJobs,
                      locked: false,
                      mode: init$2.mode,
                      jobDuration: init$2.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "ShowJobsWithScheduledDuration" :
          var init$3 = state.settings;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: init$3.filter,
                      businessHours: init$3.businessHours,
                      showUnscheduledJobs: init$3.showUnscheduledJobs,
                      locked: init$3.locked,
                      mode: init$3.mode,
                      jobDuration: "Scheduled"
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "ShowJobsWithActualDuration" :
          var init$4 = state.settings;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: init$4.filter,
                      businessHours: init$4.businessHours,
                      showUnscheduledJobs: init$4.showUnscheduledJobs,
                      locked: init$4.locked,
                      mode: init$4.mode,
                      jobDuration: "Actual"
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "ToggleDayTimelineMode" :
          var init$5 = state.settings;
          var init$6 = state.settings.mode;
          var match = state.settings.mode.day;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: init$5.filter,
                      businessHours: init$5.businessHours,
                      showUnscheduledJobs: init$5.showUnscheduledJobs,
                      locked: init$5.locked,
                      mode: {
                        day: match === "Old" ? "New" : "Old",
                        week: init$6.week
                      },
                      jobDuration: init$5.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "ToggleWeekTimelineMode" :
          var init$7 = state.settings;
          var init$8 = state.settings.mode;
          var match$1 = state.settings.mode.week;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: init$7.filter,
                      businessHours: init$7.businessHours,
                      showUnscheduledJobs: init$7.showUnscheduledJobs,
                      locked: init$7.locked,
                      mode: {
                        day: init$8.day,
                        week: match$1 === "Old" ? "New" : "Old"
                      },
                      jobDuration: init$7.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      
    }
  } else {
    switch (action.TAG) {
      case "SetBusinessHours" :
          var init$9 = state.settings;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: init$9.filter,
                      businessHours: action._0,
                      showUnscheduledJobs: init$9.showUnscheduledJobs,
                      locked: init$9.locked,
                      mode: init$9.mode,
                      jobDuration: init$9.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "SetFilterTemplates" :
          var init$10 = state.settings;
          var init$11 = state.settings.filter;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: {
                        templates: action._0,
                        territories: init$11.territories,
                        skills: init$11.skills,
                        period: init$11.period,
                        assignees: init$11.assignees
                      },
                      businessHours: init$10.businessHours,
                      showUnscheduledJobs: init$10.showUnscheduledJobs,
                      locked: init$10.locked,
                      mode: init$10.mode,
                      jobDuration: init$10.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "SetFilterTerritories" :
          var init$12 = state.settings;
          var init$13 = state.settings.filter;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: {
                        templates: init$13.templates,
                        territories: action._0,
                        skills: init$13.skills,
                        period: init$13.period,
                        assignees: init$13.assignees
                      },
                      businessHours: init$12.businessHours,
                      showUnscheduledJobs: init$12.showUnscheduledJobs,
                      locked: init$12.locked,
                      mode: init$12.mode,
                      jobDuration: init$12.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "SetFilterSkills" :
          var init$14 = state.settings;
          var init$15 = state.settings.filter;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: {
                        templates: init$15.templates,
                        territories: init$15.territories,
                        skills: action._0,
                        period: init$15.period,
                        assignees: init$15.assignees
                      },
                      businessHours: init$14.businessHours,
                      showUnscheduledJobs: init$14.showUnscheduledJobs,
                      locked: init$14.locked,
                      mode: init$14.mode,
                      jobDuration: init$14.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "SetFilterSkillsUuids" :
          var init$16 = state.settings;
          var init$17 = state.settings.filter;
          var init$18 = state.settings.filter.skills;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: {
                        templates: init$17.templates,
                        territories: init$17.territories,
                        skills: {
                          uuids: action._0,
                          mode: init$18.mode
                        },
                        period: init$17.period,
                        assignees: init$17.assignees
                      },
                      businessHours: init$16.businessHours,
                      showUnscheduledJobs: init$16.showUnscheduledJobs,
                      locked: init$16.locked,
                      mode: init$16.mode,
                      jobDuration: init$16.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "SetFilterSkillsMode" :
          var init$19 = state.settings;
          var init$20 = state.settings.filter;
          var init$21 = state.settings.filter.skills;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: {
                        templates: init$20.templates,
                        territories: init$20.territories,
                        skills: {
                          uuids: init$21.uuids,
                          mode: action._0
                        },
                        period: init$20.period,
                        assignees: init$20.assignees
                      },
                      businessHours: init$19.businessHours,
                      showUnscheduledJobs: init$19.showUnscheduledJobs,
                      locked: init$19.locked,
                      mode: init$19.mode,
                      jobDuration: init$19.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "SetFilterPeriod" :
          var init$22 = state.settings;
          var init$23 = state.settings.filter;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: {
                        templates: init$23.templates,
                        territories: init$23.territories,
                        skills: init$23.skills,
                        period: action._0,
                        assignees: init$23.assignees
                      },
                      businessHours: init$22.businessHours,
                      showUnscheduledJobs: init$22.showUnscheduledJobs,
                      locked: init$22.locked,
                      mode: init$22.mode,
                      jobDuration: init$22.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "SetMode" :
          var init$24 = state.settings;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: init$24.filter,
                      businessHours: init$24.businessHours,
                      showUnscheduledJobs: init$24.showUnscheduledJobs,
                      locked: init$24.locked,
                      mode: action._0,
                      jobDuration: init$24.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      case "SetAssigneesFilter" :
          var init$25 = state.settings;
          var init$26 = state.settings.filter;
          return {
                  TAG: "Update",
                  _0: {
                    scheduledJobs: state.scheduledJobs,
                    unscheduledJobs: state.unscheduledJobs,
                    settings: {
                      filter: {
                        templates: init$26.templates,
                        territories: init$26.territories,
                        skills: init$26.skills,
                        period: init$26.period,
                        assignees: action._0
                      },
                      businessHours: init$25.businessHours,
                      showUnscheduledJobs: init$25.showUnscheduledJobs,
                      locked: init$25.locked,
                      mode: init$25.mode,
                      jobDuration: init$25.jobDuration
                    },
                    scheduleTimelines: state.scheduleTimelines
                  }
                };
      
    }
  }
}

export {
  reducer ,
}
/* No side effect */
