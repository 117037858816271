

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as Types_Date from "../../types/Types_Date.mjs";

function fromString(s) {
  switch (s) {
    case "client_removed_web" :
        return "clientRemovedWeb";
    case "invite_sent" :
        return "inviteSent";
    case "job_removed_web" :
        return "jobRemovedWeb";
    case "jobs_exported" :
        return "jobsExported";
    case "login_web" :
        return "loginWeb";
    case "logout_web" :
        return "logoutWeb";
    case "password_reset" :
        return "passwordReset";
    case "site_removed_web" :
        return "siteRemovedWeb";
    case "tenant_activated" :
        return "tenantActivated";
    case "user_activated" :
        return "userActivated";
    case "user_created_web" :
        return "userCreatedWeb";
    case "user_removed_web" :
        return "userRemovedWeb";
    case "user_updated_web" :
        return "userUpdatedWeb";
    default:
      return ;
  }
}

function toString(a) {
  if (a === "tenantActivated") {
    return "tenant_activated";
  } else if (a === "userRemovedWeb") {
    return "user_removed_web";
  } else if (a === "siteRemovedWeb") {
    return "site_removed_web";
  } else if (a === "userCreatedWeb") {
    return "user_created_web";
  } else if (a === "jobRemovedWeb") {
    return "job_removed_web";
  } else if (a === "passwordReset") {
    return "password_reset";
  } else if (a === "logoutWeb") {
    return "logout_web";
  } else if (a === "userActivated") {
    return "user_activated";
  } else if (a === "loginWeb") {
    return "login_web";
  } else if (a === "userUpdatedWeb") {
    return "user_updated_web";
  } else if (a === "inviteSent") {
    return "invite_sent";
  } else if (a === "clientRemovedWeb") {
    return "client_removed_web";
  } else {
    return "jobs_exported";
  }
}

var all = [
  "loginWeb",
  "logoutWeb",
  "jobRemovedWeb",
  "clientRemovedWeb",
  "siteRemovedWeb",
  "jobsExported",
  "userCreatedWeb",
  "userUpdatedWeb",
  "userRemovedWeb",
  "inviteSent",
  "userActivated",
  "passwordReset",
  "tenantActivated"
];

var decode = JsonDecode.fromVariant(JsonDecode.string, fromString);

var Action = {
  fromString: fromString,
  toString: toString,
  all: all,
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var User = {
  decode: decode$1
};

var decode$2 = JsonDecode.object(function (field) {
      return {
              uuid: field.optional("uuid", Uuid.decode),
              name: field.optional("name", JsonDecode.string),
              serialNo: field.optional("serialNo", JsonDecode.$$int)
            };
    });

var Target = {
  decode: decode$2
};

var decode$3 = JsonDecode.object(function (field) {
      return {
              fileUuid: field.optional("fileUuid", Uuid.decode)
            };
    });

var Result = {
  decode: decode$3
};

var decode$4 = JsonDecode.object(function (field) {
      return {
              target: field.optional("target", decode$2),
              result: field.optional("result", decode$3)
            };
    });

var Payload = {
  Target: Target,
  Result: Result,
  decode: decode$4
};

var decode$5 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              action: field.required("action", decode),
              user: field.required("user", decode$1),
              ipAddress: field.required("ipAddress", JsonDecode.string),
              userAgent: field.required("userAgent", JsonDecode.string),
              createdAt: field.required("createdAt", Types_Date.decode),
              payload: field.required("payload", JsonDecode.option(decode$4))
            };
    });

function target(l) {
  var match = l.payload;
  if (match === undefined) {
    return {
            uuid: undefined,
            name: undefined,
            serialNo: undefined
          };
  }
  var t = match.target;
  if (t !== undefined) {
    return t;
  } else {
    return {
            uuid: undefined,
            name: undefined,
            serialNo: undefined
          };
  }
}

function result(l) {
  var match = l.payload;
  if (match === undefined) {
    return {
            fileUuid: undefined
          };
  }
  var r = match.result;
  if (r !== undefined) {
    return r;
  } else {
    return {
            fileUuid: undefined
          };
  }
}

var Log = {
  Action: Action,
  User: User,
  Payload: Payload,
  decode: decode$5,
  target: target,
  result: result
};

var CreatedAt = {};

var Filter = {
  CreatedAt: CreatedAt
};

var SearchParams = {};

export {
  Log ,
  Filter ,
  SearchParams ,
}
/* decode Not a pure module */
