

import * as Url from "../../../bindings/url/Url.mjs";
import * as Types from "../../../types/Types.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as PromiseF from "../../../libraries/PromiseF.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Billing_Types from "./Billing_Types.mjs";
import * as Backend_Headers from "../../../libraries/backend/Backend_Headers.mjs";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Encode.mjs";

function encode(comment) {
  return Json_Encode$JsonCombinators.object([[
                "comment",
                comment
              ]]);
}

function create(wire, comment) {
  return Backend.post(undefined, undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Billing",
                VAL: "RequestPricing"
              }
            }, {
              NAME: "Json",
              VAL: encode(comment)
            });
}

var POST = {
  create: create
};

function encode$1(result) {
  if (result === "Success") {
    return "success";
  } else {
    return "insufficient_funds";
  }
}

var $$Error = {};

var decodeError = JsonDecode.object(function (field) {
      return field.required("message", JsonDecode.string);
    });

function decode(response) {
  if (response.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
  var match = response._0;
  if (typeof match !== "object") {
    return {
            TAG: "Error",
            _0: "CallError"
          };
  }
  if (match.TAG !== "HTTPError") {
    return {
            TAG: "Error",
            _0: "CallError"
          };
  }
  var match$1 = match._0;
  var tmp = match$1[0];
  if (typeof tmp === "object") {
    return {
            TAG: "Error",
            _0: "CallError"
          };
  }
  if (tmp !== "PaymentRequired") {
    return {
            TAG: "Error",
            _0: "CallError"
          };
  }
  var json;
  try {
    json = JSON.parse(match$1[1]);
  }
  catch (raw_parseError){
    var parseError = Caml_js_exceptions.internalToOCamlException(raw_parseError);
    return {
            TAG: "Error",
            _0: {
              TAG: "ParseError",
              _0: parseError
            }
          };
  }
  var message = Json_Decode$JsonCombinators.decode(json, decodeError);
  if (message.TAG === "Ok") {
    return {
            TAG: "Error",
            _0: {
              TAG: "PaymentError",
              _0: message._0
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: {
              TAG: "JSONDecodeError",
              _0: message._0
            }
          };
  }
}

var EmptyResponse = {
  $$Error: $$Error,
  decodeError: decodeError,
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              availablePlanTypes: field.required("availablePlanTypes", JsonDecode.array(Billing_Types.AvailablePlanType.decode)),
              currentSeats: field.required("currentSeats", JsonDecode.$$int),
              currentOrder: field.required("order", JsonDecode.option(Billing_Types.Order.decode)),
              order: field.required("order", JsonDecode.option(Billing_Types.Order.decode)),
              subscription: field.required("subscription", JsonDecode.option(Billing_Types.Subscription.decode)),
              unpaidBill: field.required("unpaidBill", JsonDecode.option(Billing_Types.UnpaidBill.decode)),
              latestPayments: field.required("latestPayments", JsonDecode.array(Billing_Types.Payment.decode)),
              canChangePlan: field.required("canChangePlan", JsonDecode.bool),
              card: field.required("card", JsonDecode.option(Billing_Types.Card.decode)),
              availablePaymentMethods: field.required("availablePaymentMethods", JsonDecode.array(Billing_Types.PaymentMethod.decode)),
              cryptoChillSettings: field.required("cryptochill", JsonDecode.option(Billing_Types.CryptoChillSettings.decode)),
              paddleSettings: field.required("paddle", JsonDecode.option(Billing_Types.PaddleSettings.decode)),
              paddleSubscription: field.required("paddleSubscription", JsonDecode.option(Billing_Types.PaddleSubscription.decode)),
              actualPriceList: field.required("actualPriceList", Billing_Types.PriceList.decode)
            };
    });

function show(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Billing",
                    VAL: "Index"
                  }
                }), decode$1);
}

var GET = {
  show: show
};

function encode$2(order, fakeResult) {
  return Json_Encode$JsonCombinators.object(Belt_Array.concatMany([
                  [
                    [
                      "plan_type_id",
                      Types.Id.encode(order.planTypeId)
                    ],
                    [
                      "months_cycle",
                      Billing_Types.Order.Cycle.encode(order.monthsCycle)
                    ],
                    [
                      "seats",
                      order.paidSeats
                    ]
                  ],
                  fakeResult !== undefined ? [[
                        "fake_result",
                        encode$1(fakeResult)
                      ]] : []
                ]));
}

function create$1(fakeResult, wire, order) {
  return PromiseF.map(Backend.post(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Billing",
                    VAL: "Index"
                  }
                }, {
                  NAME: "Json",
                  VAL: encode$2(order, fakeResult)
                }), decode);
}

function encode$3(planTypeId, monthsCycle, paddleSubscriptionId, seats) {
  return Json_Encode$JsonCombinators.object([
              [
                "plan_type_id",
                Types.Id.encode(planTypeId)
              ],
              [
                "months_cycle",
                Billing_Types.Order.Cycle.encode(monthsCycle)
              ],
              [
                "paddle_subscription_id",
                Types.Id.encode(paddleSubscriptionId)
              ],
              [
                "seats",
                seats
              ]
            ]);
}

function update(wire, planTypeId, monthsCycle, paddleSubscriptionId, seats) {
  return PromiseF.map(Backend.patch(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Billing",
                    VAL: "PaddleSubscription"
                  }
                }, {
                  NAME: "Json",
                  VAL: encode$3(planTypeId, monthsCycle, paddleSubscriptionId, seats)
                }), decode);
}

var decode$2 = JsonDecode.object(function (field) {
      return {
              bill: field.required("bill", JsonDecode.option(Billing_Types.Bill.decode)),
              checkoutUrl: field.required("checkoutUrl", JsonDecode.option(Url.decode))
            };
    });

var $$Response = {
  decode: decode$2
};

var encode$4 = Billing_Types.Order.encode;

function create$2(wire) {
  return function (order) {
    return Backend.decode(Backend.post(undefined, undefined, undefined, undefined, wire, {
                    NAME: "API",
                    VAL: {
                      NAME: "Billing",
                      VAL: "Wizard"
                    }
                  }, {
                    NAME: "Json",
                    VAL: encode$4(order)
                  }), decode$2);
  };
}

function preview(wire) {
  return function (order) {
    return Backend.decode(Backend.post(Backend_Headers.contentJsonOverridden, undefined, undefined, undefined, wire, {
                    NAME: "API",
                    VAL: {
                      NAME: "Billing",
                      VAL: "Preview"
                    }
                  }, {
                    NAME: "Json",
                    VAL: encode$4(order)
                  }), decode$2);
  };
}

function encode$5(fakeResult) {
  return Json_Encode$JsonCombinators.object([[
                "fake_result",
                Json_Encode$JsonCombinators.option(encode$1)(fakeResult)
              ]]);
}

function pay(fakeResult, wire, uuid) {
  return PromiseF.map(Backend.patch(undefined, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Billing",
                    VAL: {
                      NAME: "Bills",
                      VAL: {
                        NAME: "Pay",
                        VAL: uuid
                      }
                    }
                  }
                }, {
                  NAME: "Json",
                  VAL: encode$5(fakeResult)
                }), decode);
}

var RequestPrice = {
  POST: POST
};

var FakeResult = {};

var Subscription_Response = {};

var Subscription_POST = {
  create: create$1
};

var Subscription_PATCH = {
  update: update
};

var Subscription = {
  $$Response: Subscription_Response,
  GET: GET,
  POST: Subscription_POST,
  PATCH: Subscription_PATCH
};

var Order_POST = {
  create: create$2,
  preview: preview
};

var Order = {
  $$Response: $$Response,
  POST: Order_POST
};

var Bills = {
  PATCH: {
    pay: pay
  }
};

export {
  RequestPrice ,
  FakeResult ,
  EmptyResponse ,
  Subscription ,
  Order ,
  Bills ,
}
/* decodeError Not a pure module */
