/* eslint-disable no-undef */
import classNames from 'classnames'
import { loadScript, debounce } from 'planado/utils/index.js'
import { getAccuracy, hasLocation } from 'planado/utils/map'
import { scheduledTime } from 'planado/utils/time/index.js'

const locales = {
  ru: 'ru_RU',
  en: 'en_US'
}
const url = ({ localizator: { lang }, yandexGeocoderApiKey }) =>
  `//api-maps.yandex.ru/2.1/?lang=${locales[lang] || locales['en']}&apikey=${yandexGeocoderApiKey}`

const geolocationOptions = {
  provider: 'yandex',
  mapStateAutoApply: true,
  autoReverseGeocode: false
}

function setupControls(map, flags) {
  const rightMargin = 10
  const position = t => ({ right: rightMargin, top: t })

  map.controls
    .add('searchControl', { float: 'right' })
    .add('zoomControl', { float: 'none', position: position(50) })
    .add('routeEditor', {
      size: 'small',
      float: 'none',
      position: position(270)
    })
    .add('trafficControl', {
      size: 'small',
      float: 'right',
      position: position(310)
    })

  if (flags.includes('yandexSatelliteView')) {
    map.controls.add('typeSelector', { size: 'small', float: 'right' })
  } else {
    // noop
  }
}

function processCommand(type, command, engine) {
  switch (type) {
    case 'ADD_LOCATION':
      engine.addLocation(command.location)
      break
    case 'REMOVE_LOCATION':
      engine.removeLocation(command.location)
      break
    case 'HIGHLIGHT_LOCATION':
      engine.highlightLocation(command.location, command.measurementSystem)
      break
    case 'REMOVE_LOCATION_HIGHLIGHT':
      engine.removeLocationHighlight(command.location)
      break
    case 'ADD_JOB':
      engine.addJob(command.job, command.options)
      break
    case 'ADD_WORKER_JOB':
      engine.addWorkerJob(command.job)
      break
    case 'REMOVE_WORKER_JOB':
      engine.removeWorkerJob(command.job)
      break
    case 'REMOVE_JOB':
      engine.removeJob(command.job)
      break
    case 'ADD_WORKER':
      engine.addWorker(command.worker, command.location)
      break
    case 'REMOVE_WORKER':
      engine.removeWorker(command.worker, command.location)
      break
    case 'LOCATE_WORKER':
      engine.locateWorker(command.worker, command.location)
      break
    case 'LOCATE_JOB':
    case 'LOCATE_WORKER_JOB':
      engine.locateJob(command.job)
      break
    case 'SHOW_LOCATIONS':
      engine.showLocations(command)
      break
    case 'HIGHLIGHT_WORKER':
      engine.highlightWorker(command.worker, command.location)
      break
    case 'REMOVE_WORKER_HIGHLIGHT':
      engine.removeWorkerHighlight(command.worker, command.location)
      break
    case 'UPDATE_CONTEXT':
      engine.updateContext(command.ctx)
      break
    default:
      console.error('Unhandled command', command)
  }
}

function renderMap(viewpoint, flags) {
  return new Promise((resolve, _reject) => {
    ymaps.ready(() => {
      ymaps.geolocation.get(geolocationOptions).done(location => {
        const map = new ymaps.Map(viewpoint, {
          center: location.geoObjects.position,
          zoom: 13,
          controls: []
        })

        ymaps.option.presetStorage.add('planado#mapMarker', {
          iconLayout: ymaps.templateLayoutFactory.createClass(
            '<div class="ymaps-map-marker $[properties.precision]"><i class="fa fa-map-marker-alt"></i></div>'
          )
        })

        setupControls(map, flags)
        resolve(map)
      })
    })
  })
}

const locationRecord = (location, measurementSystem) => {
  return {
    uuid: location.compositeId,
    type: 'location',
    location: location,
    objects: [
      new ymaps.Placemark([location.latitude, location.longitude], {
        precision: getAccuracy(location, measurementSystem)
      })
    ]
  }
}

const highlightedLocationRecord = (location, measurementSystem) => {
  return {
    uuid: location.compositeId,
    type: 'highlightedLocation',
    location: location,
    objects: [
      new ymaps.Circle(
        [[location.latitude, location.longitude], location.precision],
        {},
        {
          fillColor: '#c1b4a740',
          strokeWidth: 0
        }
      ),
      new ymaps.Placemark(
        [location.latitude, location.longitude],
        {
          precision: getAccuracy(location, measurementSystem)
        },
        { preset: 'planado#mapMarker' }
      )
    ]
  }
}

const jobRecord = (ctx, job, options = {}) => {
  const { focused = false, today = true } = options
  const style = classNames({ focused })
  const timeParams = {
    scheduledAt: job.scheduledAt,
    scheduledFinishAt: job.scheduledFinishAt,
    ctx,
    today
  }

  return {
    uuid: job.uuid,
    type: 'job',
    job: job,
    options,
    objects: [
      new ymaps.Placemark([job.location.latitude, job.location.longitude], {
        uuid: job.uuid,
        style,
        serialNo: ctx.t('js.map.serial_no', job),
        status: job.colorEnabled ? job.status : null,
        assigneeName: job.assigneeName,
        scheduledTime: scheduledTime(timeParams)
      })
    ]
  }
}

const workerJobRecord = (ctx, job) => {
  const timeParams = {
    scheduledAt: job.scheduledAt,
    scheduledFinishAt: job.scheduledFinishAt,
    ctx,
    today: false
  }
  return {
    uuid: job.uuid,
    type: 'job',
    job: job,
    objects: [
      new ymaps.Placemark([job.location.latitude, job.location.longitude], {
        job,
        serialNo: ctx.t('js.map.serial_no', job),
        assigneeName: job.assigneeName,
        scheduledTime: scheduledTime(timeParams)
      })
    ]
  }
}

const workerRecord = (worker, location, top = false) => {
  return {
    uuid: worker.uuid,
    type: 'worker',
    worker: worker,
    location: location,
    top,
    objects: [
      new ymaps.Placemark([location.latitude, location.longitude], {
        name: worker.name,
        style: top ? 'top' : ''
      })
    ]
  }
}

const highlightedWorkerRecord = (worker, location) => {
  return {
    uuid: worker.uuid,
    type: 'highlightedWorker',
    worker: worker,
    location: location,
    objects: [
      new ymaps.Circle(
        [[location.latitude, location.longitude], location.precision],
        {},
        {
          fillColor: '#c1b4a740',
          strokeWidth: 0
        }
      )
    ]
  }
}

const emptyCollection = () => ({
  records: {},
  collection: new ymaps.GeoObjectCollection()
})

const boundsWithPoint = (
  [[leftBottomX, leftBottomY], [rightTopX, rightTopY]],
  [pointX, pointY]
) => {
  return [
    [Math.min(leftBottomX, pointX), Math.min(leftBottomY, pointY)],
    [Math.max(rightTopX, pointX), Math.max(rightTopY, pointY)]
  ]
}

class YandexEngine {
  constructor({ ctx, onJobClick, onWorkersJobClick, onBoundsChange }) {
    this.ctx = ctx
    this.map = null
    this.currentWorker = null
    this.onJobClick = onJobClick
    this.onWorkersJobClick = onWorkersJobClick
    this.onBoundsChange = onBoundsChange

    this.onBoundsChange = debounce(onBoundsChange, 500)
  }

  start(viewpoint, channel) {
    const render = () => {
      this.renderTo(viewpoint)
        .then(this.initCollections)
        .then(this.createLayouts)
        .then(this.addCollections)
        .then(() =>
          channel.subscribe(c => {
            this.execute(c)
          })
        )
        .then(this.centerMap)
        .then(this.addBoundsListener)
    }

    if (!YandexEngine.activated) {
      loadScript(url(this.ctx)).then(render)
      YandexEngine.activated = true
    } else {
      render()
    }
  }

  handleBounds = (bounds = null) => {
    if (bounds !== null) {
      const [[ymin, xmin], [ymax, xmax]] = bounds

      this.onBoundsChange({
        southWest: {
          longitude: xmin,
          latitude: ymin
        },
        northEast: {
          longitude: xmax,
          latitude: ymax
        }
      })
    }
  }

  addBoundsListener = () => {
    const bounds = this.map.getBounds()

    this.handleBounds(bounds)

    this.map.events.add('boundschange', event => {
      const bounds = event.get('newBounds')
      this.handleBounds(bounds)
    })
  }

  createLayouts = () => {
    const jobMarker = ymaps.templateLayoutFactory.createClass(
      `<div class="ymaps-job-marker $[properties.style] [if properties.status] $[properties.status] [endif]">
         <div class="bubble $[properties.bubbleClass]">
           <span class="title">
             [if properties.scheduledTime]
             $[properties.scheduledTime]
             [else]
             $[properties.serialNo]
             [endif]
             <br/>$[properties.assigneeName]
           </span>
           <span class="pointer"></span>
         </div>
       </div>`,
      {
        build: function () {
          jobMarker.superclass.build.call(this)
          this._el = this.getParentElement().querySelector(
            '.ymaps-job-marker .bubble'
          )
        },

        getShape: function () {
          return new ymaps.shape.Rectangle(
            new ymaps.geometry.pixel.Rectangle([
              [20, -13],
              [20 - this._el.offsetWidth, -13 - this._el.offsetHeight]
            ])
          )
        }
      }
    )
    this.jobs.collection.options.set('iconLayout', jobMarker)

    const locationIcon = ymaps.templateLayoutFactory.createClass(
      '<div class="ymaps-location-icon $[properties.precision]"></div>'
    )
    this.locations.collection.options.set('iconLayout', locationIcon)

    const workerMarker = ymaps.templateLayoutFactory.createClass(
      `<div class="ymaps-worker-marker $[properties.style]">
         <span class="bubble">
           $[properties.name]
           <span class="pointer-back"></span>
           <span class="pointer"></span>
         </span>
       </div>`
    )
    this.workers.collection.options.set('iconLayout', workerMarker)

    const workerJobMarker = ymaps.templateLayoutFactory.createClass(
      `<div class="ymaps-worker-job-marker">
         <div class="bubble $[properties.bubbleClass]"">
           [if properties.scheduledTime]
           $[properties.scheduledTime]
           [else]
           $[properties.serialNo]
           [endif]
           <span class="pointer"></span>
         </div>
       </div>`,
      {
        build: function () {
          workerJobMarker.superclass.build.call(this)
          this._el = this.getParentElement().querySelector('.bubble')
        },

        getShape: function () {
          return new ymaps.shape.Rectangle(
            new ymaps.geometry.pixel.Rectangle([
              [20, -13],
              [20 - this._el.offsetWidth, -13 - this._el.offsetHeight]
            ])
          )
        }
      }
    )
    this.workerJobs.collection.options.set('iconLayout', workerJobMarker)
  }

  initCollections = () => {
    this.locations = emptyCollection()
    this.highlightedLocations = emptyCollection()
    this.jobs = emptyCollection()

    this.workers = emptyCollection()
    this.workerJobs = emptyCollection()
    this.highlightedWorkers = emptyCollection()
  }

  addCollections = _channel => {
    const workersPanel = new ymaps.pane.MovablePane(this.map, { zIndex: 401 })
    this.map.panes.append('workers', workersPanel)
    this.map.geoObjects.add(this.highlightedLocations.collection)
    this.map.geoObjects.add(this.jobs.collection)
    this.map.geoObjects.add(this.workerJobs.collection)

    const props = evt => evt.get('target') && evt.get('target').properties
    this.jobs.collection.events.add('mouseenter', e => {
      const ps = props(e)
      if (ps) {
        ps.set('bubbleClass', 'hovered')
      }
    })
    this.jobs.collection.events.add('mouseleave', e => {
      const ps = props(e)
      if (ps) {
        ps.set('bubbleClass', '')
      }
    })
    this.jobs.collection.events.add('click', e => {
      const ps = props(e)
      if (ps && this.onJobClick) {
        this.onJobClick(ps.get('uuid'))
      }
    })

    this.workerJobs.collection.events.add('mouseenter', e => {
      const ps = props(e)
      if (ps) {
        ps.set('bubbleClass', 'hovered')
      }
    })
    this.workerJobs.collection.events.add('mouseleave', e => {
      const ps = props(e)
      if (ps) {
        ps.set('bubbleClass', '')
      }
    })
    this.workerJobs.collection.events.add('click', e => {
      const ps = props(e)
      this.onJobClick(null)
      if (ps) {
        this.onWorkersJobClick(ps.get('job'))
      }
    })

    this.map.geoObjects.add(this.workers.collection)
    this.map.geoObjects.add(this.locations.collection)
    this.map.geoObjects.add(this.highlightedWorkers.collection)
  }

  centerMap = () => {
    this.setBounds(this.workers.collection.getBounds())
  }

  setBounds(bounds) {
    this.goTo(bounds, true)
  }

  goTo(bounds, ignoreCurrentZoom) {
    if (bounds !== null) {
      const coord = ymaps.util.bounds.getCenterAndZoom(
        bounds,
        this.map.container.getSize(),
        this.map.options.get('projection'),
        {
          margin: [100, 100]
        }
      )
      const zoom = ignoreCurrentZoom
        ? coord.zoom
        : Math.min(coord.zoom, this.map.getZoom())
      this.map.setCenter(coord.center, Math.min(zoom, 14), { duration: 400 })
    }
  }

  renderTo(viewpoint) {
    return renderMap(viewpoint, this.ctx.features.flags).then(
      map => (this.map = map)
    )
  }

  execute(command) {
    processCommand(command.type, command, this)
  }

  addLocation(location) {
    if (!(location.compositeId in this.highlightedLocations)) {
      this.addRecord(
        locationRecord(location, this.ctx.localizator.measurementSystem),
        this.locations
      )
    }
  }

  removeLocation(location) {
    this.removeRecord(location.compositeId, this.locations)
  }

  highlightLocation(location) {
    this.removeLocation(location)
    this.addRecord(
      highlightedLocationRecord(location, this.ctx.localizator.measurementSystem),
      this.highlightedLocations
    )

    if (!this.isVisible(location)) {
      this.map.panTo([location.latitude, location.longitude])
    }
  }

  removeLocationHighlight(location) {
    this.removeRecord(location.compositeId, this.highlightedLocations)
    this.addLocation(location)
  }

  highlightWorker(worker, location) {
    this.addRecord(
      highlightedWorkerRecord(worker, location),
      this.highlightedWorkers
    )
  }

  removeWorkerHighlight(worker, _location) {
    this.removeRecord(worker.uuid, this.highlightedWorkers)
  }

  addJob(job, options = {}) {
    this.addRecord(jobRecord(this.ctx, job, options), this.jobs)
  }

  removeJob({ uuid }) {
    this.removeRecord(uuid, this.jobs)
  }

  addWorkerJob(job) {
    this.addRecord(workerJobRecord(this.ctx, job), this.workerJobs)
  }

  removeWorkerJob(job) {
    this.removeRecord(job.uuid, this.workerJobs)
  }

  addWorker(worker, location, top = false) {
    this.addRecord(workerRecord(worker, location, top), this.workers)
  }

  removeWorker(worker, _location) {
    this.removeRecord(worker.uuid, this.workers)
  }

  redrawWorker({ worker, location }, top = false) {
    this.removeWorker(worker, location)
    this.addWorker(worker, location, top)
  }

  locateWorker(worker, location) {
    this.map.panTo([location.latitude, location.longitude])
    if (this.currentWorker !== null) {
      this.redrawWorker(this.currentWorker)
    }
    this.currentWorker = { worker, location }
    this.redrawWorker(this.currentWorker, true)
  }

  locateJob(job) {
    this.map.panTo([job.location.latitude, job.location.longitude])
  }

  showLocations({ job, initial }) {
    const bounds = this.locations.collection.getBounds()

    if (hasLocation(job)) {
      this.goTo(
        boundsWithPoint(bounds, [
          job.location.latitude,
          job.location.longitude
        ]),
        initial
      )
    } else {
      this.goTo(bounds, initial)
    }
  }

  updateJobs() {
    Object.values(this.jobs.records).map(({ uuid, job, options }) => {
      this.removeRecord(uuid, this.jobs)
      this.addRecord(jobRecord(this.ctx, job, options), this.jobs)
    })
  }

  updateWorkers() {
    Object.values(this.workers.records).map(
      ({ uuid, worker, location, top }) => {
        this.removeRecord(uuid, this.workers)
        this.addRecord(workerRecord(worker, location, top), this.workers)
      }
    )
  }

  updateLocations() {
    Object.values(this.locations.records).map(({ location }) => {
      this.removeRecord(location.compositeId, this.locations)
      if (!(location.compositeId in this.highlightedLocations)) {
        this.addRecord(
          locationRecord(location, this.ctx.localizator.measurementSystem),
          this.locations
        )
      }
    })
  }

  updateWorkerJobs() {
    Object.values(this.workerJobs.records).map(({ uuid, job }) => {
      this.removeRecord(uuid, this.workerJobs)
      this.addRecord(workerJobRecord(this.ctx, job), this.workerJobs)
    })
  }

  updateContext(ctx) {
    this.ctx = ctx

    this.updateJobs()
    this.updateWorkers()
    this.updateLocations()
    this.updateWorkerJobs()
  }

  addRecord(record, { records, collection }) {
    const { uuid } = record
    if (!(uuid in records)) {
      record.objects.forEach(o => collection.add(o))
      records[uuid] = record
    }
  }

  removeRecord(uuid, { records, collection }) {
    if (uuid in records) {
      records[uuid].objects.forEach(o => collection.remove(o))
      delete records[uuid]
    }
  }

  isVisible({ longitude, latitude }) {
    return ymaps.util.bounds.containsPoint(this.map.getBounds(), [
      latitude,
      longitude
    ])
  }
}

YandexEngine.activated = false

export default YandexEngine
