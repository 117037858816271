

import * as Url from "../../../bindings/url/Url.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Types from "../../../types/Types.mjs";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Belt_Lib_Id from "../../../libraries/Belt_Lib_Id.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Float from "@rescript/core/lib/es6/src/Core__Float.mjs";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Types_FeatureFlag from "../../../types/Types_FeatureFlag.mjs";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Encode.mjs";

var ISODate = {
  decode: JsonDecode.string
};

function fromString(currency) {
  switch (currency) {
    case "rub" :
        return "rub";
    case "usd" :
        return "usd";
    default:
      return ;
  }
}

var decode = JsonDecode.fromVariant(JsonDecode.string, fromString);

function fromString$1(kind) {
  switch (kind) {
    case "custom" :
        return "custom";
    case "free" :
        return "free";
    case "regular" :
        return "regular";
    case "special" :
        return "special";
    case "trial" :
        return "trial";
    default:
      return ;
  }
}

var decode$1 = JsonDecode.fromVariant(JsonDecode.string, fromString$1);

var decode$2 = JsonDecode.map(JsonDecode.string, (function (s) {
        var f = Core__Float.fromString(s);
        if (f !== undefined) {
          return f;
        } else {
          return JsonDecode.cannotDecode("Expected valid float");
        }
      }));

var decodeInt = JsonDecode.map(JsonDecode.$$int, (function (i) {
        return i;
      }));

function fromInt(i) {
  return i;
}

function pos(p) {
  return p > 0.0;
}

function toInt(p) {
  return p | 0;
}

function applyDiscount(p, x) {
  return x - x * p / 100.0;
}

function make(amount, currency) {
  return {
          amount: amount,
          currency: currency
        };
}

function makeFromFloat(amount, currency) {
  return {
          amount: amount * 100.0 | 0,
          currency: currency
        };
}

var decode$3 = JsonDecode.object(function (field) {
      return {
              amount: field.required("amount", JsonDecode.$$float) * 100.0 | 0,
              currency: field.required("currency", decode)
            };
    });

function decodeFromString(currency) {
  return JsonDecode.map(JsonDecode.string, (function (amount) {
                var amount$1 = Core__Float.fromString(amount);
                if (amount$1 !== undefined) {
                  return {
                          amount: amount$1 * 100.0 | 0,
                          currency: currency
                        };
                } else {
                  return JsonDecode.cannotDecode("Expected valid float");
                }
              }));
}

function zero(currency) {
  return {
          amount: 0,
          currency: currency
        };
}

function assertCurrency(a, b) {
  if (a.currency !== b.currency) {
    return Js_exn.raiseError("Currency mismatch");
  }
  
}

function add(a, b) {
  assertCurrency(a, b);
  return {
          amount: a.amount + b.amount | 0,
          currency: a.currency
        };
}

function sub(a, b) {
  assertCurrency(a, b);
  return {
          amount: a.amount - b.amount | 0,
          currency: a.currency
        };
}

function mult(a, x) {
  return {
          amount: Math.imul(a.amount, x),
          currency: a.currency
        };
}

function pos$1(param) {
  return param.amount > 0;
}

function applyDiscounts(a, discounts) {
  var cents = a.amount;
  var discounted = RCore.$$Array.reduce(discounts, cents, (function (acc, d) {
          return applyDiscount(d, acc);
        }));
  return {
          amount: Js_math.floor(discounted),
          currency: a.currency
        };
}

var decode$4 = JsonDecode.object(function (field) {
      return {
              due: field.required("due", JsonDecode.string),
              periodStart: field.required("periodStart", JsonDecode.string),
              periodEnd: field.required("periodEnd", JsonDecode.string),
              totalAmount: field.required("totalAmount", decode$3)
            };
    });

var Bill = {
  decode: decode$4
};

function fromString$2(s) {
  switch (s) {
    case "due" :
        return "Due";
    case "prepared" :
        return "Prepared";
    default:
      return ;
  }
}

var decode$5 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              no: field.required("no", JsonDecode.string),
              totalAmount: field.required("totalAmount", decode$3),
              periodStart: field.required("periodStart", JsonDecode.string),
              periodEnd: field.required("periodEnd", JsonDecode.string),
              due: field.required("due", JsonDecode.string),
              status: field.required("status", JsonDecode.fromVariant(JsonDecode.string, fromString$2))
            };
    });

function hasFeature(feature, param) {
  return param.features.some(function (f) {
              return f === feature;
            });
}

function hasAnyFeature(fs, param) {
  var features = param.features;
  return fs.some(function (feature) {
              return features.some(function (f) {
                          return f === feature;
                        });
            });
}

var decode$6 = JsonDecode.object(function (field) {
      return {
              features: RCore.$$Array.filterMap(field.required("features", JsonDecode.array(Types_FeatureFlag.decode)), (function (x) {
                      return x;
                    })),
              smsPerSeat: field.required("smsPerSeat", JsonDecode.option(JsonDecode.$$int)),
              seatsLimit: RCore.$$Option.flatMap(field.optional("seatsLimit", JsonDecode.option(JsonDecode.$$int)), (function (x) {
                      return x;
                    }))
            };
    });

var Features = {
  hasFeature: hasFeature,
  hasAnyFeature: hasAnyFeature,
  decode: decode$6
};

function toString(paymentMethod) {
  switch (paymentMethod) {
    case "Invoice" :
        return "invoice";
    case "Card" :
        return "card";
    case "Crypto" :
        return "crypto";
    
  }
}

function fromString$3(paymentMethod) {
  switch (paymentMethod) {
    case "card" :
        return "Card";
    case "crypto" :
        return "Crypto";
    case "invoice" :
        return "Invoice";
    default:
      return ;
  }
}

var decode$7 = JsonDecode.fromVariant(JsonDecode.string, fromString$3);

function encode(x) {
  return toString(x);
}

function fromString$4(paymentStatus) {
  switch (paymentStatus) {
    case "checked" :
        return "Checked";
    case "failed" :
        return "Failed";
    case "prepared" :
        return "Prepared";
    case "reversed" :
        return "Reversed";
    case "succeeded" :
        return "Succeeded";
    default:
      return ;
  }
}

var decode$8 = JsonDecode.fromVariant(JsonDecode.string, fromString$4);

function make$1(s, account) {
  switch (s) {
    case "bank" :
        if (account.startsWith("407") || account.startsWith("408")) {
          return "BankRub";
        } else {
          return "BankUsd";
        }
    case "paddle" :
        return "Paddle";
    case "paypro" :
        return "Paypro";
    default:
      return "Other";
  }
}

function makeFromString(s) {
  var match = Js_string.splitByReAtMost(/\//, 2, s);
  if (match.length !== 2) {
    return ;
  }
  var prefix = match[0];
  if (prefix === undefined) {
    return ;
  }
  var account = match[1];
  if (account === undefined) {
    return ;
  }
  var accountType = make$1(prefix, account);
  if (accountType !== undefined) {
    return {
            accountType: accountType
          };
  }
  
}

var decode$9 = JsonDecode.map(JsonDecode.string, makeFromString);

var decode$10 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              status: field.required("status", decode$8),
              paymentScreenFileUuid: field.required("paymentScreenFileUuid", JsonDecode.option(Uuid.decode)),
              billUuid: field.required("billUuid", JsonDecode.option(Uuid.decode)),
              billNo: field.required("billNo", JsonDecode.option(JsonDecode.string)),
              billScreenFileUuid: field.required("billScreenFileUuid", JsonDecode.option(Uuid.decode)),
              billPaymentAccount: RCore.$$Option.flatMap(field.required("billPaymentAccount", JsonDecode.option(decode$9)), (function (x) {
                      return x;
                    })),
              invoiceUuid: field.required("invoiceUuid", JsonDecode.option(Uuid.decode)),
              invoiceNo: field.required("invoiceNo", JsonDecode.option(JsonDecode.string)),
              invoiceScreenFileUuid: field.required("invoiceScreenFileUuid", JsonDecode.option(Uuid.decode)),
              invoicePaymentAccount: RCore.$$Option.flatMap(field.required("invoicePaymentAccount", JsonDecode.option(decode$9)), (function (x) {
                      return x;
                    })),
              amount: makeFromFloat(field.required("amount", JsonDecode.$$float), field.required("currency", decode)),
              createdAt: field.required("createdAt", JsonDecode.string)
            };
    });

var decodeStepDefinition = JsonDecode.object(function (field) {
      return [
              field.required("seatsLimit", JsonDecode.$$int),
              field.required("discount", decode$2)
            ];
    });

function decodeTier(field, stepNo) {
  var match = field.optional("step" + stepNo.toString(), decodeStepDefinition);
  if (match !== undefined) {
    return {
            NAME: "tier",
            VAL: {
              seatsLimit: match[0],
              discount: match[1],
              next: decodeTier(field, stepNo + 1 | 0)
            }
          };
  } else {
    return {
            NAME: "over",
            VAL: field.required("overLimitDiscount", decode$2)
          };
  }
}

var decode$11 = JsonDecode.object(function (field) {
      var match = decodeTier(field, 1);
      if (match.NAME === "tier") {
        return match.VAL;
      } else {
        return JsonDecode.cannotDecode("Expected valid option: tier");
      }
    });

function forTier(tier, rest) {
  if (tier.NAME !== "tier") {
    return applyDiscount(tier.VAL, rest);
  }
  var match = tier.VAL;
  var discount = match.discount;
  var seatsLimit = match.seatsLimit;
  if (rest <= seatsLimit) {
    return applyDiscount(discount, rest);
  } else {
    return applyDiscount(discount, seatsLimit) + forTier(match.next, rest - seatsLimit | 0);
  }
}

function forSeats(d, seats) {
  if (d.TAG === "Flat") {
    return d._0;
  } else {
    return forTier({
                NAME: "tier",
                VAL: d._0
              }, seats) / seats;
  }
}

var decode$12 = JsonDecode.object(function (field) {
      var match = field.required("type", JsonDecode.string);
      switch (match) {
        case "flat" :
            return {
                    TAG: "Flat",
                    _0: field.required("flatDiscount", decode$2)
                  };
        case "volume" :
            return {
                    TAG: "Volume",
                    _0: field.required("volumeDiscount", decode$11)
                  };
        default:
          return JsonDecode.cannotDecode("Expected valid option: flat or volume");
      }
    });

var InvalidCycle = /* @__PURE__ */Caml_exceptions.create("Billing_Types.Order.Cycle.InvalidCycle");

function fromInt$1(i) {
  switch (i) {
    case 1 :
        return 1;
    case 3 :
        return 3;
    case 6 :
        return 6;
    case 12 :
        return 12;
    default:
      return ;
  }
}

function toInt$1(c) {
  return c;
}

var cmp = Caml.int_compare;

var Cmp = Belt_Lib_Id.MakeComparableU({
      cmp: cmp
    });

var decode$13 = JsonDecode.fromVariant(JsonDecode.$$int, fromInt$1);

function encode$1(x) {
  return x;
}

function allSeats(param) {
  return param.paidSeats + param.freeSeats | 0;
}

var decode$14 = JsonDecode.object(function (field) {
      return {
              planTypeId: field.required("planTypeId", Types.Id.decode),
              paidSeats: field.required("paidSeats", JsonDecode.$$int),
              freeSeats: field.required("freeSeats", JsonDecode.$$int),
              monthsCycle: field.required("monthsCycle", decode$13),
              organizationName: field.required("organizationName", JsonDecode.option(JsonDecode.string)),
              billingAddress: field.required("billingAddress", JsonDecode.option(JsonDecode.string)),
              paymentMethod: field.required("paymentMethod", decode$7),
              currency: field.required("currency", decode),
              technicalDiscount: field.required("technicalDiscountPercent", JsonDecode.option(decode$2)),
              saleDiscount: field.required("saleDiscount", JsonDecode.option(decode$12)),
              extraFeatures: field.required("extraFeatures", decode$6)
            };
    });

function encode$2(order) {
  return Json_Encode$JsonCombinators.object([
              [
                "plan_type_id",
                Types.Id.encode(order.planTypeId)
              ],
              [
                "months_cycle",
                order.monthsCycle
              ],
              [
                "seats",
                order.paidSeats
              ],
              [
                "organization_name",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(order.organizationName)
              ],
              [
                "billing_address",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(order.billingAddress)
              ],
              [
                "payment_method",
                toString(order.paymentMethod)
              ]
            ]);
}

function matchesOrder(s, o) {
  if (Caml_obj.equal(s.planTypeId, o.planTypeId) && s.monthsCycle === o.monthsCycle && s.paidSeats === o.paidSeats) {
    return Caml_obj.equal(s.extraFeatures, o.extraFeatures);
  } else {
    return false;
  }
}

var decode$15 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              kind: field.required("kind", decode$1),
              nextBilling: field.required("nextBilling", JsonDecode.string),
              billingDay: field.required("billingDay", JsonDecode.$$int),
              lastDayOfMonth: field.required("lastDayOfMonth", JsonDecode.bool),
              paidTill: field.required("paidTill", JsonDecode.string),
              nextBill: field.required("nextBill", JsonDecode.string),
              nextBillDue: field.required("nextBillDue", JsonDecode.string),
              paidSeats: field.required("paidSeats", JsonDecode.$$int),
              freeSeats: field.required("freeSeats", JsonDecode.$$int),
              planTypeId: field.required("planTypeId", Types.Id.decode),
              monthsCycle: field.required("monthsCycle", decode$13),
              monthlyPayment: field.required("monthlyPayment", decodeFromString(field.required("currency", decode))),
              extraFeatures: field.required("extraFeatures", decode$6)
            };
    });

var Subscription = {
  matchesOrder: matchesOrder,
  decode: decode$15
};

var format = /^(.+)(?:-(\d+))$/;

function fromString$5(input) {
  var test = format.exec(input);
  var match = RCore.$$Option.map(test === null ? undefined : Caml_option.some(test), (function (r) {
          return r;
        }));
  if (match === undefined) {
    return {
            code: input,
            suffix: undefined
          };
  }
  if (match.length !== 3) {
    return {
            code: input,
            suffix: undefined
          };
  }
  var code = match[1];
  var suffix = match[2];
  if (!(code == null) && !(suffix == null)) {
    return {
            code: code,
            suffix: suffix
          };
  } else {
    return {
            code: input,
            suffix: undefined
          };
  }
}

var decode$16 = JsonDecode.map(JsonDecode.string, fromString$5);

var decode$17 = JsonDecode.object(function (field) {
      return {
              pan: field.required("pan", JsonDecode.string),
              expiresAt: field.required("expiresAt", JsonDecode.string),
              cardType: field.required("cardType", JsonDecode.string)
            };
    });

function fromString$6(status) {
  switch (status) {
    case "active" :
        return "Active";
    case "initial" :
        return "Initial";
    case "removed" :
        return "Removed";
    default:
      return ;
  }
}

var decode$18 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              status: field.required("status", JsonDecode.fromVariant(JsonDecode.string, fromString$6)),
              details: field.optional("details", decode$17)
            };
    });

function decode$19(f) {
  return {
          pan: f.required("pan", JsonDecode.string),
          expiresAt: f.required("expiresAt", JsonDecode.string),
          cardType: f.required("cardType", JsonDecode.string)
        };
}

var empty = null;

var Paypal = {
  empty: empty
};

var decode$20 = JsonDecode.object(function (field) {
      var match = field.required("type", JsonDecode.string);
      switch (match) {
        case "card" :
            return {
                    TAG: "Card",
                    _0: decode$19(field)
                  };
        case "paypal" :
            return {
                    TAG: "Paypal",
                    _0: empty
                  };
        default:
          return JsonDecode.cannotDecode("Expected valid option: card or paypal");
      }
    });

var decode$21 = JsonDecode.object(function (field) {
      return {
              subscriptionId: field.required("subscriptionId", Types.Id.decode),
              updateUrl: field.required("updateUrl", Url.decode),
              cancelUrl: field.required("cancelUrl", Url.decode),
              paymentInformation: field.required("paymentInformation", decode$20)
            };
    });

var decode$22 = JsonDecode.object(function (field) {
      return {
              account: field.required("account", JsonDecode.string),
              profile: field.required("profile", JsonDecode.string),
              currency: field.required("currency", JsonDecode.string)
            };
    });

var CryptoChillSettings = {
  decode: decode$22
};

var decode$23 = JsonDecode.object(function (field) {
      return {
              vendor: field.required("vendor", JsonDecode.$$int)
            };
    });

var PaddleSettings = {
  decode: decode$23
};

var decode$24 = JsonDecode.object(function (field) {
      return {
              monthsCycle: field.required("monthsCycle", decode$13),
              discountPercent: field.required("discountPercent", decodeInt)
            };
    });

var decode$25 = JsonDecode.object(function (field) {
      var currency = field.required("currency", decode);
      return {
              name: field.required("name", decode$16),
              kind: field.required("kind", decode$1),
              id: field.required("planTypeId", Types.Id.decode),
              features: field.required("features", decode$6),
              combinedFeatures: field.required("combinedFeatures", decode$6),
              seatPrice: field.required("seatPrice", decodeFromString(currency)),
              periods: field.required("periods", JsonDecode.array(decode$24))
            };
    });

var AvailablePlanType = {
  decode: decode$25
};

function decode$26(currency) {
  return JsonDecode.object(function (field) {
              var feature = field.required("feature", Types_FeatureFlag.decode);
              if (feature !== undefined) {
                return {
                        feature: feature,
                        price: field.required("price", decodeFromString(currency))
                      };
              }
              
            });
}

function decode$27(currency) {
  return JsonDecode.object(function (field) {
              return {
                      features: RCore.$$Array.keepSome(field.required("features", JsonDecode.array(decode$26(currency))))
                    };
            });
}

var decode$28 = JsonDecode.object(function (field) {
      var currency = field.required("currency", decode);
      return {
              currency: currency,
              prices: field.required("prices", decode$27(currency))
            };
    });

var Billing = {};

var Show = {};

function intFromStep(step) {
  switch (step) {
    case "ChoosePlan" :
        return 1;
    case "ChoosePaymentMethod" :
        return 2;
    case "Confirm" :
        return 3;
    
  }
}

var Wizard = {
  intFromStep: intFromStep
};

var Id = Types.Id;

var PK = Types.PK;

var Currency = {};

var Kind = {};

var Percent = {
  fromInt: fromInt,
  toInt: toInt,
  pos: pos
};

var Money = {
  make: make,
  zero: zero,
  add: add,
  sub: sub,
  mult: mult,
  pos: pos$1,
  applyDiscounts: applyDiscounts
};

var UnpaidBill_Status = {};

var UnpaidBill = {
  Status: UnpaidBill_Status,
  decode: decode$5
};

var PaymentMethod = {
  toString: toString,
  decode: decode$7,
  encode: encode
};

var Payment_Status = {};

var Payment_PaymentAccount = {
  Type: {}
};

var Payment = {
  Status: Payment_Status,
  PaymentAccount: Payment_PaymentAccount,
  decode: decode$10
};

var SaleDiscount = {
  forSeats: forSeats
};

var Order_Cycle = {
  InvalidCycle: InvalidCycle,
  toInt: toInt$1,
  cmp: cmp,
  Cmp: Cmp,
  encode: encode$1
};

var Order = {
  Cycle: Order_Cycle,
  allSeats: allSeats,
  decode: decode$14,
  encode: encode$2
};

var PlanName = {
  fromString: fromString$5
};

var Card_Details = {};

var Card_Status = {};

var Card = {
  Details: Card_Details,
  Status: Card_Status,
  decode: decode$18
};

var PaddleSubscription_Card = {};

var PaddleSubscription_PaymentInformation = {};

var PaddleSubscription = {
  Card: PaddleSubscription_Card,
  Paypal: Paypal,
  PaymentInformation: PaddleSubscription_PaymentInformation,
  decode: decode$21
};

var Period = {};

var PriceList_Feature = {};

var PriceList_Prices = {};

var PriceList = {
  Feature: PriceList_Feature,
  Prices: PriceList_Prices,
  decode: decode$28
};

export {
  Id ,
  PK ,
  ISODate ,
  Currency ,
  Kind ,
  Percent ,
  Money ,
  Bill ,
  UnpaidBill ,
  Features ,
  PaymentMethod ,
  Payment ,
  SaleDiscount ,
  Order ,
  Subscription ,
  PlanName ,
  Card ,
  PaddleSubscription ,
  CryptoChillSettings ,
  PaddleSettings ,
  Period ,
  AvailablePlanType ,
  PriceList ,
  Billing ,
  Show ,
  Wizard ,
}
/* decode Not a pure module */
