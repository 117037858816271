

import * as RCore from "../../../libraries/RCore.mjs";
import * as React from "react";
import * as Locale from "../../../libraries/Locale.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DayPickerRangeController from "../../inputs/DayPickerRangeController.mjs";

function month$p(w) {
  return Locale.T.fmtM("MonthWithFullYear", w);
}

function DateRangeFilter_CalendarPopup(Props) {
  var focusedInput = Props.focusedInput;
  var value = Props.value;
  var buttons = Props.buttons;
  var isOutsideRange = Props.isOutsideRange;
  var onFocusChange = Props.onFocusChange;
  var onDatesChange = Props.onDatesChange;
  var onOutsideClick = Props.onOutsideClick;
  var wire = AppContext.useWire();
  var selectDatesHandler = function (param) {
    onDatesChange(Caml_option.nullable_to_opt(param.startDate), Caml_option.nullable_to_opt(param.endDate));
  };
  var classes = RCore.$$Option.isSome(focusedInput) ? "j-calendar is-active" : "j-calendar";
  var renderMonth = month$p(wire);
  var tmp = {
    startDate: value[0],
    endDate: value[1],
    onDatesChange: selectDatesHandler,
    onFocusChange: (function (v) {
        onFocusChange(DayPickerRangeController.FocusedInput.fromString(v));
      }),
    onOutsideClick: onOutsideClick,
    minimumNights: 0,
    numberOfMonths: 2,
    hideKeyboardShortcutsPanel: true,
    navPrev: React.createElement("span", undefined),
    navNext: React.createElement("span", undefined),
    daySize: 31,
    renderMonth: renderMonth
  };
  var tmp$1 = RCore.$$Option.map(focusedInput, DayPickerRangeController.FocusedInput.toString);
  if (tmp$1 !== undefined) {
    tmp.focusedInput = tmp$1;
  }
  if (isOutsideRange !== undefined) {
    tmp.isOutsideRange = Caml_option.valFromOption(isOutsideRange);
  }
  return React.createElement("div", {
              className: classes
            }, RCore.$$Option.getOr(buttons, null), React.createElement(DayPickerRangeController.make, tmp));
}

var make = DateRangeFilter_CalendarPopup;

export {
  make ,
}
/* react Not a pure module */
