

import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../../types/Types.mjs";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as EncodeJson from "../../../../../../libraries/EncodeJson.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobEdit_Types from "../../types/JobEdit_Types.mjs";
import * as Types_Address from "../../../../../../types/Types_Address.mjs";
import * as JobEdit_JobType from "../../types/JobEdit_JobType.mjs";
import * as Shared_Lib_Option from "../../../../../../shared/lib/Shared_Lib_Option.mjs";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Encode.mjs";

function encode(options) {
  var encodeCopy = function (job) {
    if (job.TAG === "Copy") {
      return Uuid.toString(job._0);
    }
    
  };
  var encodeNextJob = function (job) {
    if (job.TAG === "Next") {
      return Uuid.toString(job._0[0]);
    }
    
  };
  var encodeNextTemplate = function (job) {
    if (job.TAG !== "Next") {
      return ;
    }
    var templateUuid = job._0[1];
    if (templateUuid !== undefined) {
      return Uuid.toString(Caml_option.valFromOption(templateUuid));
    }
    
  };
  var encodeCompletionJob = function (job) {
    if (job.TAG === "Completion") {
      return Uuid.toString(job._0);
    }
    
  };
  var encodeScheduleJob = function (options) {
    if (options.TAG === "Schedule") {
      return options._0;
    }
    
  };
  var encodeRelatedEntityJob = function (options) {
    if (options.TAG === "RelatedEntity") {
      return options._0;
    }
    
  };
  var getTemplateUuid = function (options) {
    return Shared_Lib_Option.alt(RCore.$$Option.map(encodeScheduleJob(options), (function (job) {
                      return job.templateUuid;
                    })), RCore.$$Option.map(encodeRelatedEntityJob(options), (function (job) {
                      return job.templateUuid;
                    })));
  };
  return {
          next_job: Js_null.fromOption(encodeNextJob(options)),
          next_template: Js_null.fromOption(encodeNextTemplate(options)),
          completion_job: Js_null.fromOption(encodeCompletionJob(options)),
          copy: Js_null.fromOption(encodeCopy(options)),
          template: Js_null.fromOption(getTemplateUuid(options)),
          client: Js_null.fromOption(RCore.$$Option.map(encodeRelatedEntityJob(options), (function (job) {
                      return job.clientUuid;
                    }))),
          site: Js_null.fromOption(RCore.$$Option.map(encodeRelatedEntityJob(options), (function (job) {
                      return job.siteUuid;
                    }))),
          bar_id: Js_null.fromOption(RCore.$$Option.map(encodeScheduleJob(options), (function (job) {
                      return RCore.$$Option.map(job.assigneePk, Types.PK.encode);
                    }))),
          scheduled_at: Js_null.fromOption(RCore.$$Option.map(encodeScheduleJob(options), (function (job) {
                      return job.scheduledAt;
                    })))
        };
}

var FetchOptions = {
  encode: encode
};

function encodeAction(action) {
  return [[
            "booleanValue",
            EncodeJson.bool(action)
          ]];
}

function encodeBarcode(barcode) {
  return [[
            "stringValue",
            EncodeJson.string(barcode)
          ]];
}

function encodeButton(button) {
  return [[
            "urlValue",
            EncodeJson.nullableString(button.urlValue)
          ]];
}

function encodeCheckbox(checkbox) {
  return [[
            "booleanValue",
            EncodeJson.optionBool(checkbox)
          ]];
}

function encodeCurrency(currency) {
  return [[
            "currencyValue",
            EncodeJson.optionString(currency.value)
          ]];
}

function encodeDate(date) {
  return [[
            "dateValue",
            EncodeJson.optionString(RCore.$$Option.map(date, Locale.T.isoDate))
          ]];
}

function encodeDateTime(dateTime, wire) {
  return [[
            "datetimeValue",
            EncodeJson.optionString(Locale.T.concatDateWithTime(dateTime, wire))
          ]];
}

function encodeDictionary(dictionary) {
  return [[
            "dictionaryValueUuid",
            EncodeJson.optionUuid(dictionary.value)
          ]];
}

function encodeFiles(files) {
  var attachments = EncodeJson.jsonArray(files.map(function (file) {
            return EncodeJson.object([[
                          "uuid",
                          EncodeJson.uuid(file.uuid)
                        ]]);
          }));
  return [[
            "attachmentValues",
            attachments
          ]];
}

function encodeInput(input) {
  var variant = input.NAME;
  if (variant === "Integer") {
    return [[
              "integerValue",
              EncodeJson.nullableString(input.VAL)
            ]];
  } else if (variant === "Decimal") {
    return [[
              "decimalValue",
              EncodeJson.nullableString(input.VAL)
            ]];
  } else {
    return [[
              "stringValue",
              EncodeJson.nullableString(input.VAL)
            ]];
  }
}

function encodeLink(link) {
  return [[
            "urlValue",
            EncodeJson.nullableString(link)
          ]];
}

function encodeTime(time, wire) {
  return [[
            "timeValue",
            EncodeJson.option(EncodeJson.string)(RCore.$$Option.map(time, Locale.T.fmtM("TimeSeconds", wire)))
          ]];
}

function encode$1(fields, wire) {
  return EncodeJson.jsonArray(fields.map(function (field) {
                  var action = field.value;
                  var values;
                  switch (action.TAG) {
                    case "Action" :
                        values = encodeAction(action._0);
                        break;
                    case "Barcode" :
                        values = encodeBarcode(action._0);
                        break;
                    case "Button" :
                        values = encodeButton(action._0);
                        break;
                    case "Checkbox" :
                        values = encodeCheckbox(action._0);
                        break;
                    case "Currency" :
                        values = encodeCurrency(action._0);
                        break;
                    case "Date" :
                        values = encodeDate(action._0);
                        break;
                    case "DateTime" :
                        values = encodeDateTime(action._0, wire);
                        break;
                    case "Dictionary" :
                        values = encodeDictionary(action._0);
                        break;
                    case "File" :
                    case "Image" :
                        values = encodeFiles(action._0);
                        break;
                    case "Input" :
                        values = encodeInput(action._0);
                        break;
                    case "Link" :
                        values = encodeLink(action._0);
                        break;
                    case "Signature" :
                        values = encodeFiles(action._0.attachments);
                        break;
                    case "Time" :
                        values = encodeTime(action._0, wire);
                        break;
                    
                  }
                  return EncodeJson.object(Belt_Array.concatMany([
                                  [[
                                      "typeUuid",
                                      EncodeJson.uuid(field.typeUuid)
                                    ]],
                                  values
                                ]));
                }));
}

function encode$2(fields, wire) {
  return EncodeJson.jsonArray(fields.map(function (field) {
                  var button = field.value;
                  var values;
                  switch (button.TAG) {
                    case "Button" :
                        values = encodeButton(button._0);
                        break;
                    case "Currency" :
                        values = encodeCurrency(button._0);
                        break;
                    case "Date" :
                        values = encodeDate(button._0);
                        break;
                    case "DateTime" :
                        values = encodeDateTime(button._0, wire);
                        break;
                    case "Dictionary" :
                        values = encodeDictionary(button._0);
                        break;
                    case "File" :
                        values = encodeFiles(button._0);
                        break;
                    case "Input" :
                        values = encodeInput(button._0);
                        break;
                    case "Link" :
                        values = encodeLink(button._0);
                        break;
                    case "Time" :
                        values = encodeTime(button._0, wire);
                        break;
                    
                  }
                  return EncodeJson.object(Belt_Array.concatMany([
                                  [[
                                      "typeUuid",
                                      EncodeJson.uuid(field.typeUuid)
                                    ]],
                                  values
                                ]));
                }));
}

function encode$3(org) {
  return EncodeJson.object([
              [
                "organization_name",
                EncodeJson.optionString(org.name)
              ],
              [
                "organization",
                EncodeJson.bool(true)
              ]
            ]);
}

function encode$4(ind) {
  return EncodeJson.object([
              [
                "firstName",
                EncodeJson.optionString(ind.firstName)
              ],
              [
                "middleName",
                EncodeJson.optionString(ind.middleName)
              ],
              [
                "lastName",
                EncodeJson.optionString(ind.lastName)
              ],
              [
                "organization",
                EncodeJson.bool(false)
              ]
            ]);
}

function encode$5(clientSite) {
  var uuid;
  if (clientSite === undefined) {
    return [[
              "clientUuid",
              null
            ]];
  }
  if (clientSite.TAG !== "Client") {
    return [[
              "clientUuid",
              null
            ]];
  }
  var match = clientSite._0;
  if (match.TAG === "Organization") {
    var org = match._0;
    if (org.TAG !== "Edit") {
      return [[
                "client",
                encode$3(org._0)
              ]];
    }
    uuid = org._0.uuid;
  } else {
    var ind = match._0;
    if (ind.TAG !== "Edit") {
      return [[
                "client",
                encode$4(ind._0)
              ]];
    }
    uuid = ind._0.uuid;
  }
  return [[
            "clientUuid",
            EncodeJson.string(Uuid.toString(uuid))
          ]];
}

function encodeNew(site) {
  return EncodeJson.object([
              [
                "name",
                EncodeJson.optionString(site.name)
              ],
              [
                "description",
                EncodeJson.optionString(site.description)
              ]
            ]);
}

function encode$6(clientSite) {
  if (clientSite === undefined) {
    return [[
              "siteUuid",
              null
            ]];
  }
  if (clientSite.TAG === "Client") {
    return [[
              "siteUuid",
              null
            ]];
  }
  var site = clientSite._0;
  if (site.TAG === "Edit") {
    var match = site._0;
    var match$1 = match.client;
    var uuid = match.uuid;
    if (match$1 !== undefined) {
      return [
              [
                "siteUuid",
                EncodeJson.string(Uuid.toString(uuid))
              ],
              [
                "clientUuid",
                EncodeJson.string(Uuid.toString(match$1.uuid))
              ]
            ];
    } else {
      return [
              [
                "siteUuid",
                EncodeJson.string(Uuid.toString(uuid))
              ],
              [
                "clientUuid",
                null
              ]
            ];
    }
  }
  var site$1 = site._0;
  var match$2 = site$1.client;
  if (match$2 !== undefined) {
    return [
            [
              "site",
              encodeNew(site$1)
            ],
            [
              "clientUuid",
              EncodeJson.string(Uuid.toString(match$2.uuid))
            ]
          ];
  } else {
    return [
            [
              "site",
              encodeNew(site$1)
            ],
            [
              "clientUuid",
              null
            ]
          ];
  }
}

function encode$7(assignee) {
  if (assignee.TAG === "Team") {
    var match = assignee._0;
    var foreman = match.foreman;
    var uuid = match.uuid;
    var temporaryWorkerEntries = match.temporaryWorkers.map(function (w) {
          return EncodeJson.object([
                      [
                        "uuid",
                        EncodeJson.string(Uuid.toString(w.uuid))
                      ],
                      [
                        "access",
                        EncodeJson.string("view")
                      ],
                      [
                        "teamUuid",
                        EncodeJson.string(Uuid.toString(uuid))
                      ],
                      [
                        "permanent",
                        EncodeJson.bool(false)
                      ]
                    ]);
        });
    var foremanEntry = foreman !== undefined ? [EncodeJson.object([
              [
                "uuid",
                EncodeJson.string(Uuid.toString(foreman.uuid))
              ],
              [
                "access",
                EncodeJson.string("edit")
              ],
              [
                "teamUuid",
                EncodeJson.string(Uuid.toString(uuid))
              ],
              [
                "permanent",
                EncodeJson.bool(true)
              ]
            ])] : [];
    return Belt_Array.concatMany([
                match.workers.map(function (w) {
                      return EncodeJson.object([
                                  [
                                    "uuid",
                                    EncodeJson.string(Uuid.toString(w.uuid))
                                  ],
                                  [
                                    "access",
                                    EncodeJson.string("view")
                                  ],
                                  [
                                    "teamUuid",
                                    EncodeJson.string(Uuid.toString(uuid))
                                  ],
                                  [
                                    "permanent",
                                    EncodeJson.bool(true)
                                  ]
                                ]);
                    }),
                temporaryWorkerEntries,
                foremanEntry
              ]);
  }
  var w = assignee._0;
  if (w.TAG === "Edit") {
    return [EncodeJson.object([
                  [
                    "uuid",
                    EncodeJson.uuid(w._0.uuid)
                  ],
                  [
                    "access",
                    EncodeJson.string("edit")
                  ]
                ])];
  } else {
    return [EncodeJson.object([
                  [
                    "uuid",
                    EncodeJson.uuid(w._0.uuid)
                  ],
                  [
                    "access",
                    EncodeJson.string("view")
                  ]
                ])];
  }
}

function encode$8(contact) {
  if (contact.TAG === "Phone") {
    var match = contact._0;
    return EncodeJson.object([
                [
                  "name",
                  EncodeJson.option(EncodeJson.string)(match.name)
                ],
                [
                  "value",
                  EncodeJson.option(EncodeJson.string)(match.value)
                ],
                [
                  "type",
                  EncodeJson.string("phone")
                ]
              ]);
  }
  var match$1 = contact._0;
  return EncodeJson.object([
              [
                "name",
                EncodeJson.option(EncodeJson.string)(match$1.name)
              ],
              [
                "value",
                EncodeJson.option(EncodeJson.string)(match$1.value)
              ],
              [
                "type",
                EncodeJson.string("email")
              ]
            ]);
}

function encode$9(service) {
  return Json_Encode$JsonCombinators.object([
              [
                "providedServiceUuid",
                EncodeJson.uuid(service.uuid)
              ],
              [
                "serviceUuid",
                EncodeJson.uuid(service.serviceUuid)
              ],
              [
                "orderedServiceUuid",
                EncodeJson.optionUuid(service.orderedServiceUuid)
              ],
              [
                "currency",
                service.currency
              ],
              [
                "price",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(service.price)
              ],
              [
                "quantity",
                service.quantity
              ]
            ]);
}

function encode$10(service) {
  return Json_Encode$JsonCombinators.object([
              [
                "orderedServiceUuid",
                EncodeJson.uuid(service.uuid)
              ],
              [
                "serviceUuid",
                EncodeJson.uuid(service.serviceUuid)
              ],
              [
                "required",
                service.required
              ]
            ]);
}

function encode$11(job, wire, payloadObj) {
  return EncodeJson.object(Belt_Array.concatMany([
                  [
                    [
                      "uuid",
                      EncodeJson.optionUuid(job.uuid)
                    ],
                    [
                      "status",
                      EncodeJson.string(JobEdit_JobType.Status.toString(job.status))
                    ],
                    [
                      "externalId",
                      EncodeJson.option(function (a) {
                              return EncodeJson.optionString(Fun.optString(a));
                            })(job.externalId)
                    ],
                    [
                      "copiedFromUuid",
                      EncodeJson.optionUuid(job.copiedFromUuid)
                    ],
                    [
                      "typeUuid",
                      EncodeJson.optionUuid(job.typeUuid)
                    ],
                    [
                      "templateUuid",
                      EncodeJson.optionUuid(job.templateUuid)
                    ],
                    [
                      "initialJobUuid",
                      EncodeJson.optionUuid(job.initialJobUuid)
                    ],
                    [
                      "completionOfUuid",
                      EncodeJson.optionUuid(job.completionOfUuid)
                    ],
                    [
                      "territoryUuid",
                      EncodeJson.optionUuid(job.territoryUuid)
                    ],
                    [
                      "assignees",
                      EncodeJson.jsonArray(job.assignees.flatMap(encode$7))
                    ],
                    [
                      "scheduledAt",
                      EncodeJson.optionString(RCore.$$Option.map(job.scheduledAt, Locale.T.formatM("DateTimeISO")))
                    ],
                    [
                      "apartment",
                      EncodeJson.optionString(job.apartment)
                    ],
                    [
                      "floor",
                      EncodeJson.optionString(job.floor)
                    ],
                    [
                      "entranceNo",
                      EncodeJson.optionString(job.entranceNo)
                    ],
                    [
                      "apartment",
                      EncodeJson.optionString(job.apartment)
                    ],
                    [
                      "addressDescription",
                      EncodeJson.optionString(job.addressDescription)
                    ],
                    [
                      "odometerM",
                      EncodeJson.option(EncodeJson.$$float)(job.odometerM)
                    ],
                    [
                      "scheduledDuration",
                      EncodeJson.$$int(job.scheduledDuration)
                    ],
                    [
                      "description",
                      EncodeJson.optionString(job.description)
                    ],
                    [
                      "addressAttributes",
                      Types_Address.encode(job.address)
                    ],
                    [
                      "contacts",
                      EncodeJson.jsonArray(job.contacts.map(encode$8))
                    ],
                    [
                      "skillUuids",
                      EncodeJson.jsonArray(job.skillUuids.map(function (s) {
                                return EncodeJson.string(Uuid.toString(s));
                              }))
                    ],
                    [
                      "reportFields",
                      encode$1(job.reportFields, wire)
                    ],
                    [
                      "customFields",
                      encode$2(job.customFields, wire)
                    ],
                    [
                      "orderedServices",
                      EncodeJson.jsonArray(job.orderedServices.map(encode$10))
                    ],
                    [
                      "providedServices",
                      EncodeJson.jsonArray(job.providedServices.map(encode$9))
                    ]
                  ],
                  encode$5(job.clientSite),
                  encode$6(job.clientSite),
                  payloadObj
                ]));
}

function encodeChangedData(data, wire, payloadObj) {
  var match = data.assignees;
  var tmp;
  tmp = match !== undefined ? (
      typeof match !== "object" ? EncodeJson.jsonArray([]) : EncodeJson.jsonArray(match._0.flatMap(encode$7))
    ) : undefined;
  var match$1 = data.skillUuids;
  var tmp$1;
  tmp$1 = match$1 !== undefined ? (
      typeof match$1 !== "object" ? EncodeJson.jsonArray([]) : EncodeJson.jsonArray(match$1._0.map(function (s) {
                  return EncodeJson.string(Uuid.toString(s));
                }))
    ) : undefined;
  var match$2 = data.clientSite;
  var tmp$2;
  if (match$2 !== undefined) {
    if (typeof match$2 !== "object") {
      tmp$2 = [
        [
          "clientUuid",
          null
        ],
        [
          "siteUuid",
          null
        ]
      ];
    } else {
      var clientSite = match$2._0;
      tmp$2 = Belt_Array.concatMany([
            encode$5(clientSite),
            encode$6(clientSite)
          ]);
    }
  } else {
    tmp$2 = [];
  }
  return EncodeJson.object(Belt_Array.concatMany([
                  RCore.$$Array.filterMap([
                        [
                          "resolutionUuid",
                          JobEdit_Types.ChangedData.Change.encodeIfChanged(data.resolutionUuid, EncodeJson.uuid)
                        ],
                        [
                          "resolutionComment",
                          JobEdit_Types.ChangedData.Change.encodeIfChanged(data.resolutionComment, EncodeJson.string)
                        ],
                        [
                          "typeUuid",
                          RCore.$$Option.map(data.typeUuid, EncodeJson.uuid)
                        ],
                        [
                          "territoryUuid",
                          JobEdit_Types.ChangedData.Change.encodeIfChanged(data.territoryUuid, EncodeJson.uuid)
                        ],
                        [
                          "assignees",
                          tmp
                        ],
                        [
                          "scheduledAt",
                          JobEdit_Types.ChangedData.Change.encodeIfChanged(data.scheduledAt, (function (s) {
                                  return EncodeJson.string(Locale.T.formatM("DateTimeISO")(s));
                                }))
                        ],
                        [
                          "apartment",
                          JobEdit_Types.ChangedData.Change.encodeIfChanged(data.apartment, EncodeJson.string)
                        ],
                        [
                          "floor",
                          JobEdit_Types.ChangedData.Change.encodeIfChanged(data.floor, EncodeJson.string)
                        ],
                        [
                          "entranceNo",
                          JobEdit_Types.ChangedData.Change.encodeIfChanged(data.entranceNo, EncodeJson.string)
                        ],
                        [
                          "apartment",
                          JobEdit_Types.ChangedData.Change.encodeIfChanged(data.apartment, EncodeJson.string)
                        ],
                        [
                          "addressDescription",
                          JobEdit_Types.ChangedData.Change.encodeIfChanged(data.addressDescription, EncodeJson.string)
                        ],
                        [
                          "odometerM",
                          JobEdit_Types.ChangedData.Change.encodeIfChanged(data.odometerM, EncodeJson.$$float)
                        ],
                        [
                          "scheduledDuration",
                          JobEdit_Types.ChangedData.Change.encodeIfChanged(data.scheduledDuration, EncodeJson.$$int)
                        ],
                        [
                          "description",
                          JobEdit_Types.ChangedData.Change.encodeIfChanged(data.description, EncodeJson.string)
                        ],
                        [
                          "addressAttributes",
                          JobEdit_Types.ChangedData.Change.encodeIfChanged(data.address, (function (a) {
                                  return Types_Address.encode(a);
                                }))
                        ],
                        [
                          "skillUuids",
                          tmp$1
                        ],
                        [
                          "contacts",
                          RCore.$$Option.map(data.contacts, (function (a) {
                                  return EncodeJson.jsonArray(a.map(encode$8));
                                }))
                        ],
                        [
                          "reportFields",
                          encode$1(data.reportFields, wire)
                        ],
                        [
                          "customFields",
                          encode$2(data.customFields, wire)
                        ],
                        [
                          "orderedServices",
                          RCore.$$Option.map(data.orderedServices, (function (a) {
                                  return EncodeJson.jsonArray(a.map(encode$10));
                                }))
                        ],
                        [
                          "providedServices",
                          RCore.$$Option.map(data.providedServices, (function (a) {
                                  return EncodeJson.jsonArray(a.map(encode$9));
                                }))
                        ]
                      ], (function (pair) {
                          var value = pair[1];
                          if (value !== undefined) {
                            return [
                                    pair[0],
                                    value
                                  ];
                          }
                          
                        })),
                  tmp$2,
                  payloadObj
                ]));
}

function encodeNewJob(job, wire, payloadObj) {
  return EncodeJson.object(Belt_Array.concatMany([
                  RCore.$$Array.filterMap([
                        [
                          "uuid",
                          RCore.$$Option.map(job.uuid, EncodeJson.uuid)
                        ],
                        [
                          "status",
                          EncodeJson.string(JobEdit_JobType.Status.toString(job.status))
                        ],
                        [
                          "externalId",
                          RCore.$$Option.map(job.externalId, EncodeJson.string)
                        ],
                        [
                          "copiedFromUuid",
                          RCore.$$Option.map(job.copiedFromUuid, EncodeJson.uuid)
                        ],
                        [
                          "typeUuid",
                          RCore.$$Option.map(job.typeUuid, EncodeJson.uuid)
                        ],
                        [
                          "templateUuid",
                          RCore.$$Option.map(job.templateUuid, EncodeJson.uuid)
                        ],
                        [
                          "initialJobUuid",
                          RCore.$$Option.map(job.initialJobUuid, EncodeJson.uuid)
                        ],
                        [
                          "completionOfUuid",
                          RCore.$$Option.map(job.completionOfUuid, EncodeJson.uuid)
                        ],
                        [
                          "territoryUuid",
                          RCore.$$Option.map(job.territoryUuid, EncodeJson.uuid)
                        ],
                        [
                          "assignees",
                          EncodeJson.jsonArray(job.assignees.flatMap(encode$7))
                        ],
                        [
                          "scheduledAt",
                          RCore.$$Option.map(job.scheduledAt, (function (a) {
                                  return EncodeJson.string(Locale.T.formatM("DateTimeISO")(a));
                                }))
                        ],
                        [
                          "apartment",
                          RCore.$$Option.map(job.apartment, EncodeJson.string)
                        ],
                        [
                          "floor",
                          RCore.$$Option.map(job.floor, EncodeJson.string)
                        ],
                        [
                          "entranceNo",
                          RCore.$$Option.map(job.entranceNo, EncodeJson.string)
                        ],
                        [
                          "apartment",
                          RCore.$$Option.map(job.apartment, EncodeJson.string)
                        ],
                        [
                          "addressDescription",
                          RCore.$$Option.map(job.addressDescription, EncodeJson.string)
                        ],
                        [
                          "odometerM",
                          RCore.$$Option.map(job.odometerM, EncodeJson.$$float)
                        ],
                        [
                          "scheduledDuration",
                          EncodeJson.$$int(job.scheduledDuration)
                        ],
                        [
                          "description",
                          RCore.$$Option.map(job.description, EncodeJson.string)
                        ],
                        [
                          "addressAttributes",
                          RCore.$$Option.map(job.address, (function (a) {
                                  return Types_Address.encode(a);
                                }))
                        ],
                        [
                          "contacts",
                          EncodeJson.jsonArray(job.contacts.map(encode$8))
                        ],
                        [
                          "skillUuids",
                          EncodeJson.jsonArray(job.skillUuids.map(function (s) {
                                    return EncodeJson.string(Uuid.toString(s));
                                  }))
                        ],
                        [
                          "reportFields",
                          encode$1(job.reportFields, wire)
                        ],
                        [
                          "customFields",
                          encode$2(job.customFields, wire)
                        ],
                        [
                          "orderedServices",
                          EncodeJson.jsonArray(job.orderedServices.map(encode$10))
                        ],
                        [
                          "providedServices",
                          EncodeJson.jsonArray(job.providedServices.map(encode$9))
                        ]
                      ], (function (pair) {
                          var value = pair[1];
                          if (value !== undefined) {
                            return [
                                    pair[0],
                                    value
                                  ];
                          }
                          
                        })),
                  RCore.$$Option.getOr(RCore.$$Option.map(job.clientSite, (function (a) {
                              return encode$5(a);
                            })), []),
                  RCore.$$Option.getOr(RCore.$$Option.map(job.clientSite, (function (a) {
                              return encode$6(a);
                            })), []),
                  payloadObj
                ]));
}

var Job_ReportFields = {
  encode: encode$1
};

var Job_CustomFields = {
  encode: encode$2
};

var Job = {
  ReportFields: Job_ReportFields,
  CustomFields: Job_CustomFields
};

export {
  FetchOptions ,
  Job ,
  encode$11 as encode,
  encodeChangedData ,
  encodeNewJob ,
}
/* Uuid Not a pure module */
