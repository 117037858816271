

import * as Url from "../../bindings/url/Url.mjs";
import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Radio from "../inputs/Radio.mjs";
import * as Types from "../../types/Types.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../libraries/Locale.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Checkbox from "../inputs/Checkbox.mjs";
import * as DomUtils from "../../DomUtils.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MultiSelect from "../inputs/MultiSelect.mjs";
import * as Reports_API from "./Reports_API.mjs";
import * as Reports_DatesFilter from "./Reports_DatesFilter.mjs";
import * as ReportsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/reports/Reports.module.css";

var statusPlaceholder$p = Utils.Translations.t("js.reports.jobs.status_filter.placeholder");

var statusFilterTitle$p = Utils.Translations.tr("js.reports.jobs.status_filter.title");

var templatesPlaceholder$p = Utils.Translations.t("js.reports.jobs.templates_filter.placeholder");

var templatesFilterTitle$p = Utils.Translations.tr("js.reports.jobs.templates_filter.title");

var resolutionsPlaceholder$p = Utils.Translations.t("js.reports.jobs.resolutions_filter.placeholder");

var resolutionsFilterTitle$p = Utils.Translations.tr("js.reports.jobs.resolutions_filter.title");

var jobTypesPlaceholder$p = Utils.Translations.t("js.reports.jobs.job_types_filter.placeholder");

var jobTypesFilterTitle$p = Utils.Translations.tr("js.reports.jobs.job_types_filter.title");

var showPreviousPeriod$p = Utils.Translations.t("js.reports.jobs.show_previous_period");

var posted$p = Utils.Translations.t("js.reports.jobs.status_filter.posted");

var scheduled$p = Utils.Translations.t("js.reports.jobs.status_filter.scheduled");

var published$p = Utils.Translations.t("js.reports.jobs.status_filter.published");

var enRoute$p = Utils.Translations.t("js.reports.jobs.status_filter.en_route");

var started$p = Utils.Translations.t("js.reports.jobs.status_filter.started");

var suspended$p = Utils.Translations.t("js.reports.jobs.status_filter.suspended");

var canceled$p = Utils.Translations.t("js.reports.jobs.status_filter.canceled");

var finished$p = Utils.Translations.t("js.reports.jobs.status_filter.finished");

var finishedAtFilter$p = Utils.Translations.t("js.reports.jobs.finished_at_filter.title");

var createdAtFilter$p = Utils.Translations.t("js.reports.jobs.cretated_at_filter.title");

var Translations = {
  statusPlaceholder$p: statusPlaceholder$p,
  statusFilterTitle$p: statusFilterTitle$p,
  templatesPlaceholder$p: templatesPlaceholder$p,
  templatesFilterTitle$p: templatesFilterTitle$p,
  resolutionsPlaceholder$p: resolutionsPlaceholder$p,
  resolutionsFilterTitle$p: resolutionsFilterTitle$p,
  jobTypesPlaceholder$p: jobTypesPlaceholder$p,
  jobTypesFilterTitle$p: jobTypesFilterTitle$p,
  showPreviousPeriod$p: showPreviousPeriod$p,
  posted$p: posted$p,
  scheduled$p: scheduled$p,
  published$p: published$p,
  enRoute$p: enRoute$p,
  started$p: started$p,
  suspended$p: suspended$p,
  canceled$p: canceled$p,
  finished$p: finished$p,
  finishedAtFilter$p: finishedAtFilter$p,
  createdAtFilter$p: createdAtFilter$p
};

function toString(status) {
  switch (status) {
    case "Posted" :
        return "posted";
    case "Scheduled" :
        return "scheduled";
    case "Published" :
        return "published";
    case "EnRoute" :
        return "en_route";
    case "Started" :
        return "started";
    case "Suspended" :
        return "suspended";
    case "Canceled" :
        return "canceled";
    case "Finished" :
        return "finished";
    
  }
}

function fromString(status) {
  switch (status) {
    case "canceled" :
        return "Canceled";
    case "enRoute" :
    case "en_route" :
        return "EnRoute";
    case "finished" :
        return "Finished";
    case "posted" :
        return "Posted";
    case "published" :
        return "Published";
    case "scheduled" :
        return "Scheduled";
    case "started" :
        return "Started";
    case "suspended" :
        return "Suspended";
    default:
      return ;
  }
}

var Status = {
  toString: toString,
  fromString: fromString
};

function defaultPeriod(wire) {
  return [
          Locale.T.startOf("day", Locale.T.next(Locale.T.now(wire), {
                    NAME: "weeks",
                    VAL: -4
                  })),
          Locale.T.endOf("day", Locale.T.now(wire))
        ];
}

var DatesFilter = {
  defaultPeriod: defaultPeriod
};

function $$default(wire) {
  return {
          statuses: [],
          datesFilter: {
            TAG: "CreatedAt",
            _0: defaultPeriod(wire)
          },
          showPreviousPeriod: false,
          templateUuids: [],
          resolutionUuids: [],
          jobTypeUuids: []
        };
}

function isFinishedFilter(filter) {
  var match = filter.datesFilter;
  if (match.TAG === "FinishedAt") {
    return true;
  } else {
    return false;
  }
}

function isCreatedFilter(filter) {
  var match = filter.datesFilter;
  if (match.TAG === "FinishedAt") {
    return false;
  } else {
    return true;
  }
}

function statusOptions(ctx) {
  return [
          {
            value: "posted",
            label: posted$p(ctx)
          },
          {
            value: "scheduled",
            label: scheduled$p(ctx)
          },
          {
            value: "published",
            label: published$p(ctx)
          },
          {
            value: "en_route",
            label: enRoute$p(ctx)
          },
          {
            value: "started",
            label: started$p(ctx)
          },
          {
            value: "suspended",
            label: suspended$p(ctx)
          },
          {
            value: "canceled",
            label: canceled$p(ctx)
          },
          {
            value: "finished",
            label: finished$p(ctx)
          }
        ];
}

function filterOptions(entities) {
  return entities.map(function (t) {
              return {
                      value: Uuid.toString(t.uuid),
                      label: t.name
                    };
            });
}

function encode(filters) {
  var statuses = filters.statuses.map(toString).map(function (s) {
        return s;
      });
  var match = filters.datesFilter;
  var finishedAt;
  if (match.TAG === "FinishedAt") {
    var match$1 = match._0;
    finishedAt = match$1 !== undefined ? Js_dict.fromArray([
            [
              "start",
              Locale.T.isoDate(match$1[0])
            ],
            [
              "end",
              Locale.T.isoDate(match$1[1])
            ]
          ]) : null;
  } else {
    finishedAt = null;
  }
  var match$2 = filters.datesFilter;
  var createdAt;
  if (match$2.TAG === "FinishedAt") {
    createdAt = null;
  } else {
    var match$3 = match$2._0;
    createdAt = match$3 !== undefined ? Js_dict.fromArray([
            [
              "start",
              Locale.T.isoDate(match$3[0])
            ],
            [
              "end",
              Locale.T.isoDate(match$3[1])
            ]
          ]) : null;
  }
  return {
          statusFilter: statuses,
          finishedAtFilter: finishedAt,
          createdAtFilter: createdAt,
          showPreviousPeriod: filters.showPreviousPeriod,
          templateUuids: filters.templateUuids.map(Uuid.toString),
          resolutionUuids: filters.resolutionUuids.map(Uuid.toString),
          jobTypeUuids: filters.jobTypeUuids.map(Uuid.toString)
        };
}

var Filters = {
  Status: Status,
  DatesFilter: DatesFilter,
  $$default: $$default,
  isFinishedFilter: isFinishedFilter,
  isCreatedFilter: isCreatedFilter,
  statusOptions: statusOptions,
  filterOptions: filterOptions,
  encode: encode
};

var styles = ReportsModuleCss;

function Reports_Jobs(Props) {
  var wire = Props.wire;
  var iframeHeight = Props.iframeHeight;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return $$default(wire);
      });
  var setFilters = match[1];
  var filters = match[0];
  var match$1 = React.useState(function () {
        return Reports_API.Index.$$default;
      });
  var setFiltersData = match$1[1];
  var filtersData = match$1[0];
  var match$2 = React.useState(function () {
        var match = defaultPeriod(wire);
        return [
                Caml_option.some(match[0]),
                Caml_option.some(match[1])
              ];
      });
  var setCurrentDates = match$2[1];
  var currentDates = match$2[0];
  var iframeRef = React.useRef(null);
  var setStatusFilter = function (statuses) {
    setFilters(function (param) {
          return {
                  statuses: RCore.$$Array.keepSome(statuses.map(Types.Id.toString).map(fromString)),
                  datesFilter: filters.datesFilter,
                  showPreviousPeriod: filters.showPreviousPeriod,
                  templateUuids: filters.templateUuids,
                  resolutionUuids: filters.resolutionUuids,
                  jobTypeUuids: filters.jobTypeUuids
                };
        });
  };
  var setTemplatesFilter = function (ids) {
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  datesFilter: filters.datesFilter,
                  showPreviousPeriod: filters.showPreviousPeriod,
                  templateUuids: ids.map(Types.Id.toString).map(Uuid.fromString),
                  resolutionUuids: filters.resolutionUuids,
                  jobTypeUuids: filters.jobTypeUuids
                };
        });
  };
  var setResolutionsFilter = function (ids) {
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  datesFilter: filters.datesFilter,
                  showPreviousPeriod: filters.showPreviousPeriod,
                  templateUuids: filters.templateUuids,
                  resolutionUuids: ids.map(Types.Id.toString).map(Uuid.fromString),
                  jobTypeUuids: filters.jobTypeUuids
                };
        });
  };
  var setJobTypesFilter = function (ids) {
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  datesFilter: filters.datesFilter,
                  showPreviousPeriod: filters.showPreviousPeriod,
                  templateUuids: filters.templateUuids,
                  resolutionUuids: filters.resolutionUuids,
                  jobTypeUuids: ids.map(Types.Id.toString).map(Uuid.fromString)
                };
        });
  };
  var setFinishedAt = function (fst, snd) {
    setCurrentDates(function (param) {
          return [
                  fst,
                  snd
                ];
        });
    if (fst === undefined) {
      return ;
    }
    if (snd === undefined) {
      return ;
    }
    var finish = Caml_option.valFromOption(snd);
    var start = Caml_option.valFromOption(fst);
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  datesFilter: {
                    TAG: "FinishedAt",
                    _0: [
                      start,
                      finish
                    ]
                  },
                  showPreviousPeriod: filters.showPreviousPeriod,
                  templateUuids: filters.templateUuids,
                  resolutionUuids: filters.resolutionUuids,
                  jobTypeUuids: filters.jobTypeUuids
                };
        });
  };
  var setCreatedAt = function (fst, snd) {
    setCurrentDates(function (param) {
          return [
                  fst,
                  snd
                ];
        });
    if (fst === undefined) {
      return ;
    }
    if (snd === undefined) {
      return ;
    }
    var finish = Caml_option.valFromOption(snd);
    var start = Caml_option.valFromOption(fst);
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  datesFilter: {
                    TAG: "CreatedAt",
                    _0: [
                      start,
                      finish
                    ]
                  },
                  showPreviousPeriod: filters.showPreviousPeriod,
                  templateUuids: filters.templateUuids,
                  resolutionUuids: filters.resolutionUuids,
                  jobTypeUuids: filters.jobTypeUuids
                };
        });
  };
  var pickCreatedFilter = function () {
    var match = defaultPeriod(wire);
    var snd = match[1];
    var fst = match[0];
    setCurrentDates(function (param) {
          return [
                  Caml_option.some(fst),
                  Caml_option.some(snd)
                ];
        });
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  datesFilter: {
                    TAG: "CreatedAt",
                    _0: [
                      fst,
                      snd
                    ]
                  },
                  showPreviousPeriod: filters.showPreviousPeriod,
                  templateUuids: filters.templateUuids,
                  resolutionUuids: filters.resolutionUuids,
                  jobTypeUuids: filters.jobTypeUuids
                };
        });
  };
  var pickFinsihedFilter = function () {
    var match = defaultPeriod(wire);
    var snd = match[1];
    var fst = match[0];
    setCurrentDates(function (param) {
          return [
                  Caml_option.some(fst),
                  Caml_option.some(snd)
                ];
        });
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  datesFilter: {
                    TAG: "FinishedAt",
                    _0: [
                      fst,
                      snd
                    ]
                  },
                  showPreviousPeriod: filters.showPreviousPeriod,
                  templateUuids: filters.templateUuids,
                  resolutionUuids: filters.resolutionUuids,
                  jobTypeUuids: filters.jobTypeUuids
                };
        });
  };
  var renderDatesFilters = function () {
    var match = filters.datesFilter;
    if (match.TAG === "FinishedAt") {
      return React.createElement(Reports_DatesFilter.make, {
                  wire: wire,
                  period: currentDates,
                  setPeriodDate: setFinishedAt
                });
    } else {
      return React.createElement(Reports_DatesFilter.make, {
                  wire: wire,
                  period: currentDates,
                  setPeriodDate: setCreatedAt
                });
    }
  };
  var postMessage = function (url) {
    var iframe = iframeRef.current;
    if (!(iframe == null)) {
      return DomUtils.$$Window.postMessage(DomUtils.$$Window.contentWindow(iframe), encode(filters), Url.toString(url));
    }
    
  };
  var processAPIResponse = function (response) {
    if (response.TAG !== "Ok") {
      return ;
    }
    var data = response._0;
    setFiltersData(function (param) {
          return data;
        });
  };
  React.useEffect((function () {
          var match = iframeRef.current;
          var match$1 = ctx.reportsUrl;
          var onUnmount;
          if ((match == null) || match$1 === undefined) {
            onUnmount = undefined;
          } else {
            var url = Caml_option.valFromOption(match$1);
            DomUtils.Listeners.onLoad(undefined, match, (function (param) {
                    setTimeout((function () {
                            postMessage(url);
                          }), 100);
                  }));
            onUnmount = (function () {
                DomUtils.Listeners.removeOnLoad(undefined, match, (function (param) {
                        postMessage(url);
                      }));
              });
          }
          Backend.$$finally(Reports_API.Index.index(wire), processAPIResponse);
          return onUnmount;
        }), []);
  React.useEffect((function () {
          var url = ctx.reportsUrl;
          if (url !== undefined) {
            postMessage(Caml_option.valFromOption(url));
          }
          
        }), [filters]);
  var setShowPreviousPeriod = function (showPreviousPeriod) {
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  datesFilter: filters.datesFilter,
                  showPreviousPeriod: showPreviousPeriod,
                  templateUuids: filters.templateUuids,
                  resolutionUuids: filters.resolutionUuids,
                  jobTypeUuids: filters.jobTypeUuids
                };
        });
  };
  var url = wire.ctx.reportsUrl;
  if (url !== undefined) {
    return React.createElement(React.Fragment, undefined, React.createElement("div", {
                    className: styles.filtersContainer
                  }, React.createElement("div", {
                        className: styles.dateFiltersContainer
                      }, React.createElement("div", {
                            className: styles.selectDateMode
                          }, React.createElement(Radio.make, {
                                onChange: (function (param) {
                                    pickCreatedFilter();
                                  }),
                                checked: isCreatedFilter(filters),
                                labelText: createdAtFilter$p(ctx)
                              }), React.createElement(Radio.make, {
                                onChange: (function (param) {
                                    pickFinsihedFilter();
                                  }),
                                checked: isFinishedFilter(filters),
                                labelText: finishedAtFilter$p(ctx)
                              })), React.createElement("div", {
                            className: styles.dateFilter
                          }, renderDatesFilters(), React.createElement(Checkbox.make, {
                                onToggle: setShowPreviousPeriod,
                                checked: filters.showPreviousPeriod,
                                label: showPreviousPeriod$p(ctx),
                                wrapperClasses: Js_dict.fromList({
                                      hd: [
                                        styles.showPreviousPeriod,
                                        true
                                      ],
                                      tl: /* [] */0
                                    })
                              }))), React.createElement("div", {
                        className: styles.row
                      }, React.createElement("div", {
                            className: styles.dropdownContainer
                          }, React.createElement("span", undefined, statusFilterTitle$p(ctx)), React.createElement("div", {
                                className: styles.dropdownFilter
                              }, React.createElement(MultiSelect.make, {
                                    placeholder: statusPlaceholder$p(ctx),
                                    id: "statuses-filter",
                                    onChange: setStatusFilter,
                                    value: filters.statuses.map(toString).map(Types.Id.fromString),
                                    disabled: false,
                                    options: statusOptions(ctx),
                                    multi: true
                                  }))), React.createElement("div", {
                            className: styles.dropdownContainer
                          }, React.createElement("span", undefined, templatesFilterTitle$p(ctx)), React.createElement("div", {
                                className: styles.dropdownFilter
                              }, React.createElement(MultiSelect.make, {
                                    placeholder: templatesPlaceholder$p(ctx),
                                    id: "templates-filter",
                                    onChange: setTemplatesFilter,
                                    value: filters.templateUuids.map(Uuid.toString).map(Types.Id.fromString),
                                    disabled: false,
                                    options: filterOptions(filtersData.templates),
                                    multi: true
                                  }))), React.createElement("div", {
                            className: styles.dropdownContainer
                          }, React.createElement("span", undefined, resolutionsFilterTitle$p(ctx)), React.createElement("div", {
                                className: styles.dropdownFilter
                              }, React.createElement(MultiSelect.make, {
                                    placeholder: resolutionsPlaceholder$p(ctx),
                                    id: "resolutions-filter",
                                    onChange: setResolutionsFilter,
                                    value: filters.resolutionUuids.map(Uuid.toString).map(Types.Id.fromString),
                                    disabled: false,
                                    options: filterOptions(filtersData.resolutions),
                                    multi: true
                                  }))), React.createElement("div", {
                            className: styles.dropdownContainer
                          }, React.createElement("span", undefined, jobTypesFilterTitle$p(ctx)), React.createElement("div", {
                                className: styles.dropdownFilter
                              }, React.createElement(MultiSelect.make, {
                                    placeholder: jobTypesPlaceholder$p(ctx),
                                    id: "types-filter",
                                    onChange: setJobTypesFilter,
                                    value: filters.jobTypeUuids.map(Uuid.toString).map(Types.Id.fromString),
                                    disabled: false,
                                    options: filterOptions(filtersData.types),
                                    multi: true
                                  }))))), React.createElement("iframe", {
                    ref: iframeRef,
                    id: "reports-iframe",
                    style: {
                      border: "none",
                      minHeight: "100vh",
                      overflow: "hidden"
                    },
                    height: iframeHeight,
                    scrolling: "no",
                    src: Url.toString(Caml_option.valFromOption(url)),
                    width: "100%"
                  }));
  } else {
    return null;
  }
}

var make = Reports_Jobs;

export {
  Translations ,
  Filters ,
  styles ,
  make ,
}
/* statusPlaceholder' Not a pure module */
