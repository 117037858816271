

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Link from "../../../components/common/Link/Link.mjs";
import * as React from "react";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as Pages_Territories_SortableList from "../Pages_Territories_SortableList.mjs";
import * as Pages_Territories_TerritoryItemModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/territories/territory_list_item/Pages_Territories_TerritoryItem.module.css";

var styles = Pages_Territories_TerritoryItemModuleCss;

function Pages_Territories_TerritoryItem$FileIcon(Props) {
  return React.createElement("div", {
              className: styles.filename
            }, React.createElement("i", {
                  className: Icon.style(undefined, undefined, "map")
                }));
}

function Pages_Territories_TerritoryItem(Props) {
  var territory = Props.territory;
  var disabled = Props.disabled;
  var wire = AppContext.useWire();
  var hasFlag = Context_Types.Features.hasFlag("territories", wire.ctx.features);
  var match = territory.file;
  return React.createElement(Pages_Territories_SortableList.Item.make, {
              hasActivator: true,
              id: territory.uuid,
              disabled: disabled,
              children: React.createElement("div", {
                    className: styles.territory
                  }, React.createElement(Pages_Territories_SortableList.Activator.make, {
                        id: territory.uuid,
                        disabled: disabled,
                        children: React.createElement("div", {
                              className: styles.dragContainer
                            }, React.createElement("div", {
                                  className: El.Cn.concatIf(styles.dragButton, [
                                        disabled,
                                        styles.noDrag
                                      ])
                                }))
                      }), React.createElement("div", {
                        className: styles.territoryInput
                      }, React.createElement("div", {
                            className: styles.editLink
                          }, hasFlag ? React.createElement(Link.make, {
                                  wire: wire,
                                  route: {
                                    NAME: "Territories",
                                    VAL: {
                                      NAME: "Edit",
                                      VAL: territory.uuid
                                    }
                                  },
                                  children: territory.name
                                }) : territory.name), React.createElement("div", {
                            className: styles.fileLink
                          }, match !== undefined ? React.createElement(Pages_Territories_TerritoryItem$FileIcon, {}) : null), React.createElement("hr", {
                            className: styles.divider
                          })))
            });
}

var make = Pages_Territories_TerritoryItem;

export {
  make ,
}
/* styles Not a pure module */
