

import * as Wire from "../../../types/Wire.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as UI_Icon from "../../../ui/UI_Icon.mjs";
import * as Optional from "../../../components/common/Optional.mjs";
import * as UI_Button from "../../../ui/UI_Button.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as DropDownMenu from "../../../components/common/DropDownMenu/DropDownMenu.mjs";
import * as Pages_Site_HeadingModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/sites/edit/Pages_Site_Heading.module.css";

var add$p = Utils.Translations.tr("js.sites.show.heading.add");

var edit$p = Utils.Translations.tr("js.sites.show.heading.edit");

var addExternalId$p = Utils.Translations.tr("js.sites.show.dropdown.add_external_id");

var styles = Pages_Site_HeadingModuleCss;

function Pages_Site_Heading$SiteTitle(Props) {
  var site = Props.site;
  var initialName = Props.initialName;
  var ctx = Props.ctx;
  if (site.isNewRecord) {
    return React.createElement("div", {
                className: styles.siteFormHeadingTitle
              }, add$p(ctx));
  } else {
    return React.createElement("div", {
                className: styles.siteFormHeadingTitle
              }, RCore.$$Option.getOr(initialName, ""), React.createElement("span", undefined, edit$p(ctx)));
  }
}

function Pages_Site_Heading$SiteExternalId(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var ctx = Props.ctx;
  var site = state.site;
  var onClick = function (evt) {
    evt.preventDefault();
    if (!site.showingExternalId) {
      return setState(function (current) {
                  var init = current.site;
                  return {
                          canManage: current.canManage,
                          locked: current.locked,
                          site: {
                            uuid: init.uuid,
                            isNewRecord: init.isNewRecord,
                            name: init.name,
                            description: init.description,
                            externalId: init.externalId,
                            showingExternalId: true,
                            contacts: init.contacts,
                            floor: init.floor,
                            apartment: init.apartment,
                            addressDescription: init.addressDescription,
                            entranceNo: init.entranceNo,
                            clientUuid: init.clientUuid,
                            clientName: init.clientName,
                            territoryUuid: init.territoryUuid,
                            availableTerritories: init.availableTerritories,
                            createdAt: init.createdAt,
                            address: init.address,
                            creator: init.creator
                          },
                          addressConstraints: current.addressConstraints,
                          errors: current.errors
                        };
                });
    }
    
  };
  return React.createElement(Optional.make, {
              show: !site.showingExternalId && state.canManage && (site.isNewRecord || site.externalId === undefined),
              children: React.createElement(DropDownMenu.make, {
                    position: "right",
                    className: styles.dropdownOptions,
                    children: React.createElement("li", undefined, React.createElement("a", {
                              className: "add_external_id",
                              href: "",
                              onClick: onClick
                            }, addExternalId$p(ctx)))
                  })
            });
}

function Pages_Site_Heading(Props) {
  var state = Props.state;
  var setState = Props.setState;
  var initialName = Props.initialName;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  return React.createElement("div", {
              className: styles.siteFormHeading
            }, React.createElement(Pages_Site_Heading$SiteTitle, {
                  site: state.site,
                  initialName: initialName,
                  ctx: ctx
                }), React.createElement("div", {
                  className: styles.siteFormHeadingRight
                }, React.createElement(Pages_Site_Heading$SiteExternalId, {
                      state: state,
                      setState: setState,
                      ctx: ctx
                    }), React.createElement(UI_Button.make, {
                      flavor: "close",
                      className: styles.closeButton,
                      onClick: (function (_event) {
                          Wire.closeModal(wire);
                        }),
                      children: React.createElement(UI_Icon.make, {
                            icon: "times"
                          })
                    })));
}

var make = Pages_Site_Heading;

export {
  make ,
}
/* add' Not a pure module */
