

import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Billing_Types from "../Billing_Types.mjs";
import * as Billing_Utils from "../Billing_Utils.mjs";

var payCrypto$p = Utils.Translations.txr("js.billing.wizard.confirm.crypto");

var productName$p = Utils.Translations.t("js.billing.wizard.product_name");

var months$p = Utils.Translations.tx("js.billing.wizard.months");

var seats$p = Utils.Translations.tx("js.billing.wizard.seats");

function make(account, profile, onSuccess) {
  new (CryptoChill.setup)({
        account: account,
        profile: profile,
        onSuccess: onSuccess
      });
}

function Billing_Components_Crypto$Widget$WithDataProps(Props) {
  var amount = Props.amount;
  var currency = Props.currency;
  var product = Props.product;
  var passthrough = Props.passthrough;
  var children = Props.children;
  return React.cloneElement(children, {
              "data-amount": amount,
              "data-currency": currency,
              "data-product": product,
              "data-passthrough": JSON.stringify(Utils.objToJson(passthrough))
            });
}

function Billing_Components_Crypto(Props) {
  var bill = Props.bill;
  var order = Props.order;
  var settings = Props.settings;
  var reload = Props.reload;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  React.useEffect(function () {
        make(settings.account, settings.profile, (function (param, param$1) {
                reload();
              }));
      });
  var passthrough = {
    account_uuid: ctx.tenantUuid,
    licenses: order.paidSeats,
    months_cycle: Billing_Types.Order.Cycle.toInt(order.monthsCycle),
    plan_type_id: order.planTypeId
  };
  var productName = Utils.Translations.joinWithComma([
        productName$p(ctx),
        months$p({
              count: Billing_Types.Order.Cycle.toInt(order.monthsCycle)
            }, ctx),
        seats$p({
              count: order.paidSeats
            }, ctx)
      ]);
  var amount = Billing_Utils.Format.formatMoney$p(bill.totalAmount.amount);
  return React.createElement(Billing_Components_Crypto$Widget$WithDataProps, {
              amount: amount,
              currency: settings.currency,
              product: productName,
              passthrough: passthrough,
              children: React.createElement("button", {
                    className: "btn btn-primary cryptochill-button"
                  }, payCrypto$p({
                        amount: amount,
                        currency: settings.currency
                      }, ctx))
            });
}

var make$1 = Billing_Components_Crypto;

export {
  make$1 as make,
}
/* payCrypto' Not a pure module */
