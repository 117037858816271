

import * as Url from "../../../../bindings/url/Url.mjs";
import * as Note from "../../../common/Note/Note.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Route from "../../../../types/Route.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Router from "../../../../Router.mjs";
import * as Backend from "../../../../libraries/backend/Backend.mjs";
import * as Context from "../../../../context/Context.mjs";
import * as DomUtils from "../../../../DomUtils.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Billing_API from "../Billing_API.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Billing_Components_Card from "./Billing_Components_Card.mjs";
import * as Billing_Components_Paypal from "./Billing_Components_Paypal.mjs";
import * as BillingModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/billing/Billing.module.css";

var change$p = Utils.Translations.tr("js.billing.show.change");

var delete$p = Utils.Translations.tr("js.billing.show.delete");

var success$p = Utils.Translations.th("js.billing.pending");

var updatePaddle$p = Utils.Translations.tr("js.billing.wizard.confirm.apply_new_plan");

var styles = BillingModuleCss;

function init(vendor) {
  new (Paddle.Setup)({
        vendor: vendor
      });
}

function Billing_Components_Paddle$Widget(Props) {
  var settings = Props.settings;
  var props = Props.props;
  var children = Props.children;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  React.useEffect(function () {
        init(settings.vendor);
        if (Context.isDev(ctx)) {
          new (Paddle.Environment.set)("sandbox");
        }
        
      });
  return React.createElement(DomUtils.Spread.make, {
              props: props,
              children: children
            });
}

function Billing_Components_Paddle$UpdateCard(Props) {
  var settings = Props.settings;
  var updateLink = Props.updateLink;
  var cancelLink = Props.cancelLink;
  var details = Props.details;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var updateProps = {
    "data-override": Url.href(updateLink),
    "data-theme": "none",
    "data-success": Route.pagePath(undefined, "Billing")
  };
  var cancelProps = {
    "data-override": Url.href(cancelLink),
    "data-theme": "none",
    "data-success": Route.pagePath(undefined, "Billing")
  };
  var buttons = React.createElement(React.Fragment, undefined, React.createElement(Billing_Components_Paddle$Widget, {
            settings: settings,
            props: updateProps,
            children: React.createElement("a", {
                  className: "paddle_button " + styles.changeCard,
                  href: "#"
                }, change$p(ctx))
          }), React.createElement(Billing_Components_Paddle$Widget, {
            settings: settings,
            props: cancelProps,
            children: React.createElement("a", {
                  className: "paddle_button " + styles.changeCard,
                  href: "#"
                }, delete$p(ctx))
          }));
  if (details.TAG === "Card") {
    return React.createElement(Billing_Components_Card.make, {
                ctx: ctx,
                children: buttons,
                details: details._0
              });
  } else {
    return React.createElement(Billing_Components_Paypal.make, {
                children: buttons
              });
  }
}

var UpdateCard = {
  make: Billing_Components_Paddle$UpdateCard
};

function Billing_Components_Paddle$UpdatePlan(Props) {
  var onClick = Props.onClick;
  var match = AppContext.useWire();
  return React.createElement("button", {
              className: "btn btn-primary paddle_button",
              onClick: onClick
            }, updatePaddle$p(match.ctx));
}

var UpdatePlan = {
  make: Billing_Components_Paddle$UpdatePlan
};

function Billing_Components_Paddle$Success(Props) {
  var wire = Props.wire;
  var match = React.useState(function () {
        
      });
  var setPaddleSubscriptionId = match[1];
  var paddleSubscriptionId = match[0];
  var fetched = function (response) {
    if (response.TAG !== "Ok") {
      return ;
    }
    var match = response._0.paddleSubscription;
    if (match === undefined) {
      return ;
    }
    var subscriptionId = match.subscriptionId;
    setPaddleSubscriptionId(function (param) {
          return Caml_option.some(subscriptionId);
        });
  };
  var $$fetch = function () {
    Backend.$$finally(Billing_API.Subscription.GET.show(wire), fetched);
  };
  React.useEffect((function () {
          var intervalId = setInterval((function () {
                  $$fetch();
                }), 2000);
          return (function () {
                    clearInterval(intervalId);
                  });
        }), []);
  React.useEffect((function () {
          if (RCore.$$Option.isSome(paddleSubscriptionId)) {
            Router.redirect(Route.pagePath(undefined, "Billing"));
          }
          
        }), [paddleSubscriptionId]);
  return React.createElement("div", {
              id: "content-wrapper"
            }, React.createElement("div", {
                  className: "page-container narrow"
                }, React.createElement(Note.make, {
                      children: success$p(wire.ctx),
                      flavor: "success"
                    })));
}

var Success = {
  make: Billing_Components_Paddle$Success
};

var Widget = {
  make: Billing_Components_Paddle$Widget
};

export {
  Widget ,
  UpdateCard ,
  UpdatePlan ,
  Success ,
}
/* change' Not a pure module */
