

import * as RCore from "../../../../libraries/RCore.mjs";
import * as React from "react";
import * as WSChannel from "../../../../libraries/WSChannel.mjs";
import * as Websocket from "../../../../libraries/Websocket.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as Context_Auth from "../../../../context/Context_Auth.mjs";
import * as Schedule_DND from "../Schedule_DND.mjs";
import * as Context_Types from "../../../../context/Context_Types.mjs";
import * as Schedule_Storage from "./storage/Schedule_Storage.mjs";
import * as Schedule_Types_Job from "../types/job/Schedule_Types_Job.mjs";
import * as Schedule_StateTypes from "./Schedule_StateTypes.mjs";
import * as Schedule_StateContext from "./Schedule_StateContext.mjs";
import * as Schedule_StateReducer from "./reducer/Schedule_StateReducer.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";

function use(filter, jobDuration, view, state, dispatch) {
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var match$1 = match.route.path;
  var isNewSchedule;
  if (typeof match$1 === "object" && match$1.NAME === "Schedule") {
    var match$2 = match$1.VAL;
    isNewSchedule = typeof match$2 === "object" && match$2.NAME === "Timeline" ? (
        match$2.VAL === "Week" ? Schedule_StateTypes.State.mode(state).week === "New" : Schedule_StateTypes.State.mode(state).day === "New"
      ) : true;
  } else {
    isNewSchedule = true;
  }
  var initialized = React.useRef(false);
  React.useEffect((function () {
          if (!initialized.current && isNewSchedule) {
            dispatch({
                  TAG: "Jobs",
                  _0: {
                    TAG: "FetchScheduledJobs",
                    _0: view
                  }
                });
            dispatch({
                  TAG: "Jobs",
                  _0: "FetchUnscheduledJobs"
                });
            if (Context_Types.Features.hasFlag("shifts", ctx.features)) {
              dispatch("FetchShifts");
            }
            initialized.current = true;
          }
          
        }), [
        isNewSchedule,
        view
      ]);
  React.useEffect((function () {
          if (initialized.current) {
            dispatch({
                  TAG: "Jobs",
                  _0: {
                    TAG: "FetchScheduledJobs",
                    _0: view
                  }
                });
          }
          
        }), [
        filter,
        jobDuration,
        view
      ]);
  React.useEffect((function () {
          if (initialized.current) {
            dispatch({
                  TAG: "Jobs",
                  _0: "FetchUnscheduledJobs"
                });
          }
          
        }), [
        filter.templates,
        filter.territories,
        filter.skills,
        jobDuration
      ]);
  React.useEffect((function () {
          if (initialized.current && Context_Types.Features.hasFlag("shifts", ctx.features)) {
            dispatch("FetchShifts");
          }
          
        }), [filter.period]);
}

function use$1(state, dispatch) {
  var match = AppContext.useWire();
  var match$1 = match.route.path;
  var isNewSchedule;
  if (typeof match$1 === "object" && match$1.NAME === "Schedule") {
    var match$2 = match$1.VAL;
    isNewSchedule = typeof match$2 === "object" && match$2.NAME === "Timeline" ? (
        match$2.VAL === "Week" ? Schedule_StateTypes.State.mode(state).week === "New" : Schedule_StateTypes.State.mode(state).day === "New"
      ) : true;
  } else {
    isNewSchedule = true;
  }
  var tenantUuid = AppContext.useTenantUuid();
  var realSocket = Context_Auth.useSocket();
  var socket = isNewSchedule ? realSocket : Websocket.nullSocket;
  var channel = Websocket.useChannel(socket, {
        NAME: "scheduleV2",
        VAL: tenantUuid
      });
  var jobFetched = function (json) {
    var job = Json_Decode$JsonCombinators.decode(json, Schedule_Types_Job.DecodedJob.decode(state.settings.jobDuration));
    if (job.TAG === "Ok") {
      return dispatch({
                  TAG: "Jobs",
                  _0: {
                    TAG: "JobFetched",
                    _0: job._0
                  }
                });
    }
    console.log(job._0);
  };
  Websocket.useSubscription1(channel, "job_created", jobFetched, state.settings.jobDuration);
  Websocket.useSubscription1(channel, "job_updated", jobFetched, state.settings.jobDuration);
  var jobRemoved = function (json) {
    var error = Json_Decode$JsonCombinators.decode(json, WSChannel.Channel.Message.JobRemoved.decode);
    if (error.TAG === "Ok") {
      return dispatch({
                  TAG: "Jobs",
                  _0: {
                    TAG: "JobRemoved",
                    _0: error._0.uuid
                  }
                });
    }
    console.log(error._0);
  };
  Websocket.useSubscription(channel, "job_removed", jobRemoved);
}

function use$2(dispatch) {
  var match = Schedule_DND.DND.Context.use();
  var subscriptions = match.subscriptions;
  React.useEffect((function () {
          var unsubscribeFromDragStart = subscriptions.onDragStart.subscribe(function () {
                dispatch({
                      TAG: "Settings",
                      _0: "LockSchedule"
                    });
              });
          var unsubscribeFromDragEnd = subscriptions.onDragEnd.subscribe(function () {
                dispatch({
                      TAG: "Settings",
                      _0: "UnlockSchedule"
                    });
              });
          return (function () {
                    unsubscribeFromDragStart();
                    unsubscribeFromDragEnd();
                  });
        }), []);
}

function use$3(state, dispatch) {
  var wire = AppContext.useWire();
  React.useEffect((function () {
          var match = state.settings.jobDuration;
          if (match === "Scheduled") {
            return ;
          } else {
            return wire.subscriptions.timer.subscribe(function (now) {
                        dispatch({
                              TAG: "Jobs",
                              _0: {
                                TAG: "RefineScheduledJobs",
                                _0: now
                              }
                            });
                      });
          }
        }), [state.settings.jobDuration]);
}

function useContext(view) {
  var context = Schedule_StateReducer.use(view);
  var dispatch = context[1];
  var state = context[0];
  Schedule_Storage.use(view, state, dispatch);
  use(state.settings.filter, state.settings.jobDuration, view, state, dispatch);
  use$1(state, dispatch);
  use$2(dispatch);
  use$3(state, dispatch);
  return context;
}

function useState() {
  return Schedule_StateContext.use()[0];
}

function useDispatch() {
  return Schedule_StateContext.use()[1];
}

function useScheduledJobs() {
  var state = useState();
  return Schedule_StateTypes.State.scheduledJobs(state);
}

function useScheduledJobsLoading() {
  var state = useState();
  return Schedule_StateTypes.State.scheduledJobsLoading(state);
}

function useScheduledJobsWarning() {
  var state = useState();
  return Schedule_StateTypes.State.scheduledJobsWarning(state);
}

function useAssignedJobs() {
  var jobs = useScheduledJobs();
  return RCore.$$Array.filterMap(jobs, Schedule_Types_Job.ScheduledJob.assigned);
}

function useWorkersTimelines() {
  return Schedule_StateTypes.State.scheduleTimelines(useState()).workersTimelines;
}

function useTeamTimelines() {
  return Schedule_StateTypes.State.scheduleTimelines(useState()).teamTimelines;
}

function useScheduleTimelines() {
  return Schedule_StateTypes.State.scheduleTimelines(useState());
}

function useUnassignedJobs() {
  var jobs = useScheduledJobs();
  return RCore.$$Array.filterMap(jobs, Schedule_Types_Job.ScheduledJob.unassigned);
}

function useUnscheduledJobs() {
  var state = useState();
  return Schedule_StateTypes.State.unscheduledJobs(state);
}

function useHasNextUnscheduledJobs() {
  var state = useState();
  return Schedule_StateTypes.State.hasNextUnscheduledJobs(state);
}

function useUnscheduledJobsLimit() {
  var state = useState();
  return Schedule_StateTypes.State.unscheduledJobsLimit(state);
}

function useUnscheduledJobsLoading() {
  var state = useState();
  return Schedule_StateTypes.State.unscheduledJobsLoading(state);
}

function useShowUnscheduledJobs() {
  var state = useState();
  return Schedule_StateTypes.State.showUnscheduledJobs(state);
}

function useBusinessHours() {
  var state = useState();
  return Schedule_StateTypes.State.businessHours(state);
}

function useFilter() {
  var state = useState();
  return Schedule_StateTypes.State.filter(state);
}

function usePeriod() {
  var state = useState();
  return Schedule_StateTypes.State.period(state);
}

function useStartOfPeriod() {
  var state = useState();
  return Schedule_StateTypes.State.startOfPeriod(state);
}

function useFinishOfPeriod() {
  var state = useState();
  return Schedule_StateTypes.State.finishOfPeriod(state);
}

function useMode() {
  var state = useState();
  return Schedule_StateTypes.State.mode(state);
}

function useJobDuration() {
  var state = useState();
  return Schedule_StateTypes.State.jobDuration(state);
}

function useAssigneesFilter() {
  var state = useState();
  return Schedule_StateTypes.State.assigneesFilter(state);
}

function useSetFilterTemplates() {
  var match = Schedule_StateContext.use();
  var dispatch = match[1];
  return function (templates) {
    dispatch({
          TAG: "Settings",
          _0: {
            TAG: "SetFilterTemplates",
            _0: templates
          }
        });
  };
}

function useSetFilterTerritories() {
  var match = Schedule_StateContext.use();
  var dispatch = match[1];
  return function (territories) {
    dispatch({
          TAG: "Settings",
          _0: {
            TAG: "SetFilterTerritories",
            _0: territories
          }
        });
  };
}

function useSetFilterSkillsUuids() {
  var match = Schedule_StateContext.use();
  var dispatch = match[1];
  return function (uuids) {
    dispatch({
          TAG: "Settings",
          _0: {
            TAG: "SetFilterSkillsUuids",
            _0: uuids
          }
        });
  };
}

function useSetFilterSkillsMode() {
  var match = Schedule_StateContext.use();
  var dispatch = match[1];
  return function (mode) {
    dispatch({
          TAG: "Settings",
          _0: {
            TAG: "SetFilterSkillsMode",
            _0: mode
          }
        });
  };
}

function useShowJobsWithScheduledDuration() {
  var match = Schedule_StateContext.use();
  var dispatch = match[1];
  return function () {
    dispatch({
          TAG: "Settings",
          _0: "ShowJobsWithScheduledDuration"
        });
  };
}

function useShowJobsWithActualDuration() {
  var match = Schedule_StateContext.use();
  var dispatch = match[1];
  return function () {
    dispatch({
          TAG: "Settings",
          _0: "ShowJobsWithActualDuration"
        });
  };
}

function useToggleDayTimelineMode() {
  var match = Schedule_StateContext.use();
  var dispatch = match[1];
  return function () {
    dispatch({
          TAG: "Settings",
          _0: "ToggleDayTimelineMode"
        });
  };
}

function useToggleWeekTimelineMode() {
  var match = Schedule_StateContext.use();
  var dispatch = match[1];
  return function () {
    dispatch({
          TAG: "Settings",
          _0: "ToggleWeekTimelineMode"
        });
  };
}

function useSetPeriod() {
  var match = Schedule_StateContext.use();
  var dispatch = match[1];
  return function (period) {
    dispatch({
          TAG: "Settings",
          _0: {
            TAG: "SetFilterPeriod",
            _0: period
          }
        });
  };
}

function useSetAssigneesFilter() {
  var match = Schedule_StateContext.use();
  var dispatch = match[1];
  return function (assignees) {
    dispatch({
          TAG: "Settings",
          _0: {
            TAG: "SetAssigneesFilter",
            _0: assignees
          }
        });
  };
}

function useSetBusinessHours() {
  var match = Schedule_StateContext.use();
  var dispatch = match[1];
  return function (businessHours) {
    dispatch({
          TAG: "Settings",
          _0: {
            TAG: "SetBusinessHours",
            _0: businessHours
          }
        });
  };
}

function useToggleUnscheduledJobs() {
  var match = Schedule_StateContext.use();
  var dispatch = match[1];
  return function () {
    dispatch({
          TAG: "Settings",
          _0: "ToggleUnscheduledJobs"
        });
  };
}

function useLocked() {
  var state = useState();
  return Schedule_StateTypes.State.locked(state);
}

function use$4() {
  var match = AppContext.useWire();
  var mode = useMode();
  var match$1 = match.route.path;
  if (typeof match$1 !== "object") {
    return true;
  }
  if (match$1.NAME !== "Schedule") {
    return true;
  }
  var match$2 = match$1.VAL;
  if (typeof match$2 === "object" && match$2.NAME === "Timeline") {
    if (match$2.VAL === "Week") {
      return mode.week === "New";
    } else {
      return mode.day === "New";
    }
  } else {
    return true;
  }
}

var IsNew = {
  use: use$4
};

var useCurrentDay = useStartOfPeriod;

export {
  useContext ,
  useDispatch ,
  useScheduledJobs ,
  useScheduledJobsLoading ,
  useScheduledJobsWarning ,
  useAssignedJobs ,
  useWorkersTimelines ,
  useTeamTimelines ,
  useScheduleTimelines ,
  useUnassignedJobs ,
  useUnscheduledJobs ,
  useHasNextUnscheduledJobs ,
  useUnscheduledJobsLimit ,
  useUnscheduledJobsLoading ,
  useShowUnscheduledJobs ,
  useBusinessHours ,
  useFilter ,
  usePeriod ,
  useStartOfPeriod ,
  useCurrentDay ,
  useFinishOfPeriod ,
  useMode ,
  useJobDuration ,
  useAssigneesFilter ,
  useSetFilterTemplates ,
  useSetFilterTerritories ,
  useSetFilterSkillsUuids ,
  useSetFilterSkillsMode ,
  useShowJobsWithScheduledDuration ,
  useShowJobsWithActualDuration ,
  useToggleDayTimelineMode ,
  useToggleWeekTimelineMode ,
  useSetPeriod ,
  useSetAssigneesFilter ,
  useSetBusinessHours ,
  useToggleUnscheduledJobs ,
  useLocked ,
  IsNew ,
}
/* react Not a pure module */
