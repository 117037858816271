

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as CatalogModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Catalog/Catalog.module.css";

var itemFromCategory$p = Utils.Translations.txr("js.templates.services.item_from_category");

var categoryServices$p = Utils.Translations.txr("js.templates.services.category_services");

var categoryChildren$p = Utils.Translations.txr("js.templates.services.category_children");

var noServices$p = Utils.Translations.tr("js.templates.services.category_services_empty");

var styles = CatalogModuleCss;

function CategoryItem(Props) {
  var ctx = Props.ctx;
  var category = Props.category;
  var onRemove = Props.onRemove;
  var onRemoveClick = function (e) {
    e.preventDefault();
    onRemove();
  };
  var itemCN = category.archived ? El.Cn.concat(styles.selectedItem, styles.archived) : styles.selectedItem;
  var renderItems = function (arr, fieldFn) {
    if (arr.length <= 10) {
      return Utils.Translations.joinWithComma(arr.map(fieldFn));
    }
    var list = Utils.Translations.joinWithComma(arr.slice(0, 10).map(fieldFn));
    return Utils.Translations.concat([
                list,
                " ..."
              ], ctx);
  };
  var descr = category.description;
  var parent = category.parent;
  var serv = category.services;
  var children = category.childCategories;
  return React.createElement("div", {
              className: itemCN
            }, React.createElement("div", {
                  className: styles.row
                }, React.createElement("div", {
                      className: styles.itemLabel
                    }, React.createElement("i", {
                          className: Icon.style("lg", undefined, "list")
                        })), React.createElement("div", {
                      className: styles.itemInfo
                    }, React.createElement("div", {
                          className: styles.header
                        }, category.name), descr !== undefined ? React.createElement("div", {
                            className: styles.description
                          }, descr) : null, parent !== undefined ? React.createElement("div", {
                            className: styles.description
                          }, itemFromCategory$p({
                                name: parent.name
                              }, ctx)) : null), React.createElement("div", {
                      className: styles.itemInfo
                    }, serv.length !== 0 ? categoryServices$p({
                            services: renderItems(serv, (function (c) {
                                    return c.name;
                                  }))
                          }, ctx) : noServices$p(ctx), children.length !== 0 ? React.createElement("div", undefined, categoryChildren$p({
                                categories: renderItems(children, (function (c) {
                                        return c.name;
                                      }))
                              }, ctx)) : null), React.createElement("div", {
                      className: styles.itemDelete,
                      onClick: onRemoveClick
                    }, React.createElement("i", {
                          className: Icon.style(undefined, undefined, "times")
                        }))));
}

var make = CategoryItem;

export {
  make ,
}
/* itemFromCategory' Not a pure module */
