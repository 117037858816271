

import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Billing_Types from "../Billing_Types.mjs";
import * as BillingModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/billing/Billing.module.css";

var months$p = Utils.Translations.txr("js.billing.wizard.months");

var discount$p = Utils.Translations.txr("js.billing.wizard.discount");

var styles = BillingModuleCss;

function Billing_Components_Periods(Props) {
  var ctx = Props.ctx;
  var onClick = Props.onClick;
  var periods = Props.periods;
  var availablePlanTypes = Props.availablePlanTypes;
  var order = Props.order;
  var planType = RCore.$$Array.getBy(availablePlanTypes, (function (t) {
          return Caml_obj.equal(t.id, order.planTypeId);
        }));
  if (planType === undefined) {
    return null;
  }
  var p = function (period, index) {
    var className = period === order.monthsCycle ? styles.current : "";
    var discount = RCore.$$Option.map(planType.periods.find(function (p) {
              return p.monthsCycle === period;
            }), (function (p) {
            return p.discountPercent;
          }));
    var tmp;
    if (discount !== undefined) {
      var discount$1 = Caml_option.valFromOption(discount);
      if (Billing_Types.Percent.pos(discount$1)) {
        var className$1 = styles.discount;
        tmp = React.createElement("div", {
              className: className$1
            }, discount$p({
                  discount: Billing_Types.Percent.toInt(discount$1)
                }, ctx));
      } else {
        tmp = null;
      }
    } else {
      tmp = null;
    }
    return React.createElement("td", {
                key: index.toString(),
                className: className,
                onClick: onClick(period)
              }, React.createElement("div", undefined, months$p({
                        count: Billing_Types.Order.Cycle.toInt(period)
                      }, ctx)), tmp);
  };
  return React.createElement("table", {
              className: styles.periods
            }, React.createElement("tbody", undefined, React.createElement("tr", undefined, periods.map(p))));
}

var Types;

var make = Billing_Components_Periods;

export {
  Types ,
  make ,
}
/* months' Not a pure module */
