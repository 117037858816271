

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Types from "../../../types/Types.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.mjs";
import * as Types_Territory from "../../../types/Types_Territory.mjs";
import * as MultipleAssignee from "../../../types/MultipleAssignee.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";
import Templates from "planado/stores/admin/templates";

var Resolution = {};

function make(uuid, name) {
  return {
          uuid: uuid,
          name: name
        };
}

var TemplateRecurringJob = {
  make: make
};

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var Template = {
  decode: decode
};

var State = {};

var Index = {
  Template: Template,
  State: State
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              name: RCore.$$Option.getOr(field.optional("name", JsonDecode.array(JsonDecode.string)), [])
            };
    });

var Errors = {
  decode: decode$1
};

var decode$2 = JsonDecode.object(function (field) {
      return {
              isNewRecord: field.required("isNewRecord", JsonDecode.bool),
              uuid: field.optional("uuid", Uuid.decode),
              templateId: field.required("templateId", JsonDecode.option(Types.Id.decode)),
              name: field.required("name", JsonDecode.option(JsonDecode.string)),
              typeId: field.required("typeId", JsonDecode.option(Types.Id.decode)),
              description: field.required("description", JsonDecode.option(JsonDecode.string)),
              scheduledDuration: field.required("scheduledDuration", JsonDecode.option(JsonDecode.$$int)),
              maxScheduledDuration: field.required("maxScheduledDuration", JsonDecode.$$int),
              errors: field.required("errors", decode$1),
              assignees: field.required("assignees", MultipleAssignee.decode),
              trackMovements: field.required("trackMovements", JsonDecode.bool),
              canBeUsedOnMobile: field.required("canBeUsedOnMobile", JsonDecode.bool)
            };
    });

function fromJs(js) {
  return {
          isNewRecord: js.isNewRecord,
          uuid: Caml_option.null_to_opt(js.uuid),
          templateId: Caml_option.null_to_opt(js.templateId),
          name: Caml_option.null_to_opt(js.name),
          typeId: Caml_option.null_to_opt(js.typeId),
          description: Caml_option.null_to_opt(js.description),
          scheduledDuration: Caml_option.null_to_opt(js.scheduledDuration),
          maxScheduledDuration: js.maxScheduledDuration,
          errors: js.errors,
          assignees: Core__Result.getOr(Json_Decode$JsonCombinators.decode(js.assignees, MultipleAssignee.decode), []),
          trackMovements: js.trackMovements,
          canBeUsedOnMobile: js.canBeUsedOnMobile
        };
}

var Template$1 = {
  decode: decode$2,
  fromJs: fromJs
};

var decode$3 = JsonDecode.object(function (field) {
      return {
              refId: field.required("refId", Types.Id.decode),
              code: field.required("code", JsonDecode.string)
            };
    });

var decode$4 = JsonDecode.object(function (field) {
      return {
              template: field.required("template", decode$2),
              types: field.required("types", JsonDecode.array(decode$3)),
              availableTerritories: field.required("availableTerritories", JsonDecode.array(Types_Territory.decode))
            };
    });

var Get = {
  decode: decode$4
};

var JobType = {};

var Store = {
  make: (function (prim0, prim1) {
      return Templates(prim0, prim1);
    })
};

export {
  Resolution ,
  TemplateRecurringJob ,
  Index ,
  Errors ,
  Template$1 as Template,
  JobType ,
  Get ,
  Store ,
}
/* decode Not a pure module */
