

import * as El from "../../libraries/El.mjs";
import * as Icon from "../../Icon.mjs";
import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Spinner from "../../components/common/Spinner/Spinner.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../components/common/Optional.mjs";
import * as AddButton from "../../components/common/AddButton/AddButton.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as Context_Types from "../../context/Context_Types.mjs";
import * as UI_FeatureTeaser from "../../ui/UI_FeatureTeaser.mjs";
import * as Pages_Territories_API from "./Pages_Territories_API.mjs";
import * as Pages_Territories_SortableList from "./Pages_Territories_SortableList.mjs";
import * as Pages_Territories_TerritoryItem from "./territory_list_item/Pages_Territories_TerritoryItem.mjs";
import * as Pages_Territories_TerritoryModal from "./territory_modal/Pages_Territories_TerritoryModal.mjs";
import * as Pages_TerritoriesModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/territories/Pages_Territories.module.css";

var title$p = Utils.Translations.tr("js.admin.territories.title");

var add$p = Utils.Translations.tr("js.admin.territories.add");

var noTerritories$p = Utils.Translations.tr("js.admin.territories.no_territories");

var whenTerritoriesExist$p = Utils.Translations.th("js.admin.territories.description.when_territories_exist");

var whenTerritoriesNotExist$p = Utils.Translations.th("js.admin.territories.description.when_territories_not_exist");

var fakeNameOne$p = Utils.Translations.t("js.admin.territories.fake_data.names.one");

var fakeNameTwo$p = Utils.Translations.t("js.admin.territories.fake_data.names.two");

var fakeNameThree$p = Utils.Translations.t("js.admin.territories.fake_data.names.three");

var styles = Pages_TerritoriesModuleCss;

function Pages_Territories(Props) {
  var mode = Props.mode;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setLoaded = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLocked = match$1[1];
  var match$2 = React.useState(function () {
        return [];
      });
  var setTerritories = match$2[1];
  var territories = match$2[0];
  var hasFlag = Context_Types.Features.hasFlag("territories", ctx.features);
  var processResponse = function (territories) {
    if (territories.TAG === "Ok") {
      var territories$1 = territories._0;
      setTerritories(function (param) {
            return territories$1;
          });
    }
    setLoaded(function (param) {
          return true;
        });
    setLocked(function (param) {
          return false;
        });
  };
  var reload = function () {
    Backend.$$finally(Pages_Territories_API.Index.index(wire), processResponse);
  };
  React.useEffect((function () {
          if (hasFlag) {
            reload();
          } else {
            setTerritories(function (param) {
                  return [
                          {
                            uuid: Uuid.createV4(),
                            name: fakeNameOne$p(ctx),
                            orderNo: 0,
                            file: undefined
                          },
                          {
                            uuid: Uuid.createV4(),
                            name: fakeNameTwo$p(ctx),
                            orderNo: 1,
                            file: undefined
                          },
                          {
                            uuid: Uuid.createV4(),
                            name: fakeNameThree$p(ctx),
                            orderNo: 2,
                            file: {
                              name: "Territory.geojson",
                              url: "https://foo.bar"
                            }
                          }
                        ];
                });
            setLoaded(function (param) {
                  return true;
                });
          }
        }), []);
  var changeOrder = function (ters) {
    if (!Caml_obj.notequal(ters, territories)) {
      return ;
    }
    setLocked(function (param) {
          return true;
        });
    var ters$1 = ters.map(function (territory, orderNo) {
          return {
                  uuid: territory.uuid,
                  name: territory.name,
                  orderNo: orderNo,
                  file: territory.file
                };
        });
    setTerritories(function (param) {
          return ters$1;
        });
    Backend.$$finally(Pages_Territories_API.UpdateOrder.update(wire, ters$1), processResponse);
  };
  var tmp;
  var exit = 0;
  if (typeof mode === "object" || mode === "New") {
    exit = 1;
  } else {
    tmp = null;
  }
  if (exit === 1) {
    tmp = React.createElement(Pages_Territories_TerritoryModal.make, {
          mode: mode,
          onAction: reload
        });
  }
  return React.createElement("div", {
              id: "content-wrapper"
            }, React.createElement("div", {
                  className: "page-container narrow"
                }, React.createElement("form", {
                      className: styles.form,
                      disabled: match$1[0]
                    }, React.createElement("header", {
                          className: styles.formHeader
                        }, React.createElement("div", undefined, title$p(ctx))), React.createElement("div", {
                          className: styles.formBody
                        }, match[0] ? (
                            Caml_obj.equal(territories, []) ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                                        className: "note note-info " + styles.description
                                      }, React.createElement("p", undefined, whenTerritoriesNotExist$p(ctx))), React.createElement("div", {
                                        className: styles.noTerritoriesContainer
                                      }, React.createElement("span", {
                                            className: El.Cn.concat(Icon.style(undefined, undefined, "tags"), styles.tags)
                                          }), React.createElement("p", {
                                            className: styles.noTerritories
                                          }, noTerritories$p(ctx)))) : React.createElement(React.Fragment, undefined, React.createElement("div", {
                                        className: "note note-info " + styles.description
                                      }, React.createElement("p", undefined, whenTerritoriesExist$p(ctx))), React.createElement(Optional.make, {
                                        show: !hasFlag,
                                        children: React.createElement("div", {
                                              className: styles.teaserWrapper
                                            }, React.createElement(UI_FeatureTeaser.Large.make, {
                                                  minimalPlan: "standard"
                                                }))
                                      }), React.createElement("div", {
                                        className: styles.territoriesList
                                      }, React.createElement(Pages_Territories_SortableList.make, {
                                            items: territories,
                                            onDragEnd: changeOrder,
                                            children: territories.map(function (territory) {
                                                  return React.createElement(Pages_Territories_TerritoryItem.make, {
                                                              territory: territory,
                                                              disabled: !hasFlag,
                                                              key: Uuid.toString(territory.uuid)
                                                            });
                                                })
                                          })))
                          ) : React.createElement(Spinner.make, {}), React.createElement(AddButton.make, {
                              wire: wire,
                              route: {
                                NAME: "Territories",
                                VAL: "New"
                              },
                              children: add$p(ctx)
                            }))), tmp));
}

var make = Pages_Territories;

export {
  make ,
}
/* title' Not a pure module */
