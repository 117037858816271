

import * as Fun from "./libraries/Fun.mjs";
import * as Tour from "./libraries/tours/Tour.mjs";
import * as User from "./components/admin/user/User.mjs";
import * as Wire from "./types/Wire.mjs";
import * as Login from "./components/login/Login.mjs";
import * as RCore from "./libraries/RCore.mjs";
import * as Route from "./types/Route.mjs";
import * as Teams from "./components/team/Teams.mjs";
import * as Title from "./components/common/Title.mjs";
import * as Tours from "./libraries/tours/Tours.mjs";
import * as React from "react";
import * as Header from "./components/header/Header.mjs";
import * as Loader from "./components/loader/Loader.mjs";
import * as Locale from "./libraries/Locale.mjs";
import * as Modals from "./components/modals/Modals.mjs";
import * as Random from "./libraries/Random.mjs";
import * as Router from "./Router.mjs";
import * as Backend from "./libraries/backend/Backend.mjs";
import * as Context from "./context/Context.mjs";
import * as MapPage from "./components/map/MapPage.mjs";
import * as Recover from "./components/recover/Recover.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DomUtils from "./DomUtils.mjs";
import * as JobIndex from "./components/job/index/JobIndex.mjs";
import * as NotFound from "./components/errors/NotFound.mjs";
import * as Optional from "./components/common/Optional.mjs";
import * as Schedule from "./components/schedule/Schedule.mjs";
import * as Forbidden from "./components/errors/Forbidden.mjs";
import * as Messenger from "./components/messenger/Messenger.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ContextAPI from "./libraries/ContextAPI.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as Dictionary from "./components/admin/dictionary/Dictionary.mjs";
import * as TrialEnded from "./components/black_hole/TrialEnded.mjs";
import * as Pages_Audit from "./pages/audit/Pages_Audit.mjs";
import * as ShiftsIndex from "./components/shifts/index/ShiftsIndex.mjs";
import * as Billing_Page from "./components/admin/billing/Billing_Page.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.mjs";
import * as JobTypeIndex from "./components/admin/job_type/JobTypeIndex.mjs";
import * as Pages_Skills from "./pages/skills/Pages_Skills.mjs";
import * as RecurringJob from "./components/admin/recurring_job/RecurringJob.mjs";
import * as AccessBlocked from "./components/black_hole/AccessBlocked.mjs";
import * as Context_Types from "./context/Context_Types.mjs";
import * as Pages_Context from "./pages/Pages_Context.mjs";
import * as Pages_Wrapper from "./pages/Pages_Wrapper.mjs";
import * as Reports_Index from "./components/reports/Reports_Index.mjs";
import * as ResetPassword from "./components/reset_password/ResetPassword.mjs";
import * as SignInByToken from "./components/sign_in_by_token/SignInByToken.mjs";
import * as Subscriptions from "./types/Subscriptions.mjs";
import * as Tour_Provider from "./libraries/tours/Tour_Provider.mjs";
import * as BackgroundTask from "./types/BackgroundTask.mjs";
import * as MessengerTypes from "./components/messenger/MessengerTypes.mjs";
import * as Products_Index from "./components/admin/catalog/products/Products_Index.mjs";
import * as Services_Index from "./components/admin/catalog/services/Services_Index.mjs";
import * as Settings_Index from "./components/settings/Settings_Index.mjs";
import * as Pages_Addresses from "./pages/addresses/Pages_Addresses.mjs";
import * as ResolutionIndex from "./components/admin/resolution/ResolutionIndex.mjs";
import * as AppSubscriptions from "./AppSubscriptions.mjs";
import * as FileUploadErrors from "./components/errors/FileUploadErrors.mjs";
import * as Pages_Activation from "./pages/activation/Pages_Activation.mjs";
import * as PrintLayoutIndex from "./components/admin/print_layouts/PrintLayoutIndex.mjs";
import * as ActivateFieldUser from "./components/activate_field_user/ActivateFieldUser.mjs";
import * as ClientForms_Index from "./components/admin/client_forms/ClientForms_Index.mjs";
import * as Pages_Territories from "./pages/territories/Pages_Territories.mjs";
import * as Integrations_Index from "./components/admin/integrations/Integrations_Index.mjs";
import * as Shared_Lib_Reducer from "./shared/lib/Shared_Lib_Reducer.mjs";
import * as Template_Component from "./components/admin/template/Template_Component.mjs";
import * as Pages_Authenticated from "./pages/Pages_Authenticated.mjs";
import * as Pages_Client_Portal from "./pages/client_portal/Pages_Client_Portal.mjs";
import * as Pages_Custom_Fields from "./pages/custom_fields/Pages_Custom_Fields.mjs";
import * as Utils_UsedResources from "./utils/Utils_UsedResources.mjs";
import * as BackgroundTasksIndex from "./components/background_task/BackgroundTasksIndex.mjs";
import * as Billing_Components_Paddle from "./components/admin/billing/components/Billing_Components_Paddle.mjs";
import * as AppModuleCss from "/home/runner/work/planado/planado/client/rescript/App.module.css";

function initial_onDestroy(prim) {
  
}

var initial_backgroundTasks = [];

var initial = {
  wire: undefined,
  onDestroy: initial_onDestroy,
  backgroundTasks: initial_backgroundTasks
};

function make(seed, send, ctx, footer, websocketParams, route, tour) {
  return {
          seed: seed,
          ctx: ctx,
          subscriptions: Subscriptions.make(),
          route: route,
          navigate: (function (modalsOpt, path) {
              var modals = modalsOpt !== undefined ? modalsOpt : /* [] */0;
              send({
                    TAG: "Navigate",
                    _0: {
                      path: path,
                      modals: modals
                    }
                  });
            }),
          openModal: (function (modal) {
              send({
                    TAG: "OpenModal",
                    _0: modal
                  });
            }),
          footer: footer,
          reloadContext: (function () {
              send("ReloadContext");
            }),
          websocketParams: websocketParams,
          usedResources: {
            resources: [],
            track: (function (resource) {
                send({
                      TAG: "TrackUsedResource",
                      _0: Wire.UsedResources.Resource.make(resource)
                    });
              }),
            flush: (function () {
                send("FlushUsedResources");
              })
          },
          tour: tour
        };
}

var styles = AppModuleCss;

function displayError(wire, error) {
  var match = Fun.both(wire, error);
  if (match !== undefined) {
    return match[0].subscriptions.messenger.trigger({
                TAG: "Show",
                _0: MessengerTypes.Message.make(undefined, "Danger", {
                      TAG: "Text",
                      _0: match[1]
                    })
              });
  }
  
}

function useShowOnMount(wire) {
  React.useEffect((function () {
          displayError(wire, FileUploadErrors.getErrorMessage(wire, Router.search()));
        }), [wire]);
}

function App(Props) {
  var ctx = Props.ctx;
  var footer = Props.footer;
  var websocketParams = Props.websocketParams;
  var backgroundTasks = Props.backgroundTasks;
  var seed = React.useMemo((function () {
          return Random.Seed.make();
        }), []);
  var tourState = Tour.useState(RCore.$$Option.map(ctx.user, (function (u) {
                return u.uuid;
              })))(seed);
  var reducer = function (param) {
    var action = param[1];
    var state = param[0];
    if (typeof action !== "object") {
      if (action === "CreateWire") {
        return {
                TAG: "SideEffect",
                _0: (function (param) {
                    var send = param.send;
                    var route = Route.parseUrl(Router.pathname(), Router.search());
                    var wire = make(seed, send, ctx, footer, websocketParams, route, tourState);
                    Locale.T.setDefaultTimeZone(wire);
                    if (wire.route.path === "Forbidden") {
                      DomUtils.setHeaderHeight(0);
                    } else if (wire.route.path !== "Billing") {
                      DomUtils.setHeaderHeight(Context.headerHeight(ctx));
                    }
                    var intervalId = setInterval((function () {
                            var now = Locale.T.now(wire);
                            wire.subscriptions.timer.trigger(now);
                          }), 60000);
                    var resourceIntervalId = setInterval((function () {
                            wire.usedResources.flush();
                          }), 300000);
                    var unbind = AppSubscriptions.bind(wire.subscriptions);
                    var onDestroy = function () {
                      clearInterval(intervalId);
                      clearInterval(resourceIntervalId);
                      unbind();
                    };
                    send({
                          TAG: "SetState",
                          _0: {
                            wire: wire,
                            onDestroy: onDestroy,
                            backgroundTasks: backgroundTasks
                          }
                        });
                  })
              };
      }
      
    } else {
      switch (action.TAG) {
        case "SetState" :
            return {
                    TAG: "Update",
                    _0: action._0
                  };
        case "SetWire" :
            return {
                    TAG: "Update",
                    _0: {
                      wire: action._0,
                      onDestroy: state.onDestroy,
                      backgroundTasks: state.backgroundTasks
                    }
                  };
        default:
          
      }
    }
    var wire = state.wire;
    if (wire === undefined) {
      return "NoUpdate";
    }
    if (typeof action !== "object") {
      switch (action) {
        case "ReloadContext" :
            return {
                    TAG: "SideEffect",
                    _0: (function (param) {
                        var send = param.send;
                        Backend.$$finally(ContextAPI.$$fetch(wire), (function (r) {
                                send({
                                      TAG: "Response",
                                      _0: Core__Result.map(r, (function (c) {
                                              return {
                                                      TAG: "Context",
                                                      _0: c
                                                    };
                                            }))
                                    });
                              }));
                      })
                  };
        case "FlushUsedResources" :
            return {
                    TAG: "SideEffect",
                    _0: (function (param) {
                        var wire = param.state.wire;
                        if (wire === undefined) {
                          return ;
                        }
                        var resources = wire.usedResources.resources;
                        if (resources.length <= 0) {
                          return ;
                        }
                        Utils_UsedResources.store(undefined, resources, wire);
                        var init = wire.usedResources;
                        param.send({
                              TAG: "SetWire",
                              _0: {
                                seed: wire.seed,
                                ctx: wire.ctx,
                                subscriptions: wire.subscriptions,
                                route: wire.route,
                                navigate: wire.navigate,
                                openModal: wire.openModal,
                                footer: wire.footer,
                                reloadContext: wire.reloadContext,
                                websocketParams: wire.websocketParams,
                                usedResources: {
                                  resources: [],
                                  track: init.track,
                                  flush: init.flush
                                },
                                tour: wire.tour
                              }
                            });
                      })
                  };
        default:
          return "NoUpdate";
      }
    } else {
      switch (action.TAG) {
        case "Navigate" :
            return {
                    TAG: "UpdateWithSideEffect",
                    _0: {
                      wire: {
                        seed: wire.seed,
                        ctx: wire.ctx,
                        subscriptions: wire.subscriptions,
                        route: action._0,
                        navigate: wire.navigate,
                        openModal: wire.openModal,
                        footer: wire.footer,
                        reloadContext: wire.reloadContext,
                        websocketParams: wire.websocketParams,
                        usedResources: wire.usedResources,
                        tour: wire.tour
                      },
                      onDestroy: state.onDestroy,
                      backgroundTasks: state.backgroundTasks
                    },
                    _1: (function (param) {
                        var updatedWire = param.state.wire;
                        if (updatedWire !== undefined && updatedWire.route !== wire.route) {
                          return Router.push(updatedWire.route);
                        }
                        
                      })
                  };
        case "Navigated" :
            return {
                    TAG: "Update",
                    _0: {
                      wire: {
                        seed: wire.seed,
                        ctx: wire.ctx,
                        subscriptions: wire.subscriptions,
                        route: action._0,
                        navigate: wire.navigate,
                        openModal: wire.openModal,
                        footer: wire.footer,
                        reloadContext: wire.reloadContext,
                        websocketParams: wire.websocketParams,
                        usedResources: wire.usedResources,
                        tour: wire.tour
                      },
                      onDestroy: state.onDestroy,
                      backgroundTasks: state.backgroundTasks
                    }
                  };
        case "OpenModal" :
            var route_path = wire.route.path;
            var route_modals = Core__List.concat({
                  hd: action._0,
                  tl: /* [] */0
                }, wire.route.modals);
            var route = {
              path: route_path,
              modals: route_modals
            };
            return {
                    TAG: "SideEffect",
                    _0: (function (param) {
                        param.send({
                              TAG: "Navigate",
                              _0: route
                            });
                      })
                  };
        case "Response" :
            var _error = action._0;
            if (_error.TAG !== "Ok") {
              return "NoUpdate";
            }
            var ctx$1 = _error._0;
            if (ctx$1.TAG === "Context") {
              return {
                      TAG: "Update",
                      _0: {
                        wire: {
                          seed: wire.seed,
                          ctx: ctx$1._0,
                          subscriptions: wire.subscriptions,
                          route: wire.route,
                          navigate: wire.navigate,
                          openModal: wire.openModal,
                          footer: wire.footer,
                          reloadContext: wire.reloadContext,
                          websocketParams: wire.websocketParams,
                          usedResources: wire.usedResources,
                          tour: wire.tour
                        },
                        onDestroy: state.onDestroy,
                        backgroundTasks: state.backgroundTasks
                      }
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        wire: state.wire,
                        onDestroy: state.onDestroy,
                        backgroundTasks: ctx$1._0.backgroundTasks
                      }
                    };
            }
        case "TrackUsedResource" :
            var addedResource = action._0;
            var i = RCore.$$Array.findIndexOpt(wire.usedResources.resources, (function (r) {
                    if (r.resourceType === addedResource.resourceType) {
                      return Caml_obj.equal(r.unit, addedResource.unit);
                    } else {
                      return false;
                    }
                  }));
            var updated = i !== undefined ? wire.usedResources.resources.map(function (res, storedIndex) {
                    if (storedIndex === i) {
                      return Wire.UsedResources.Resource.mergeDiffs(res, addedResource.diff);
                    } else {
                      return res;
                    }
                  }) : Belt_Array.concatMany([
                    [addedResource],
                    wire.usedResources.resources
                  ]);
            var init = wire.usedResources;
            return {
                    TAG: "Update",
                    _0: {
                      wire: {
                        seed: wire.seed,
                        ctx: wire.ctx,
                        subscriptions: wire.subscriptions,
                        route: wire.route,
                        navigate: wire.navigate,
                        openModal: wire.openModal,
                        footer: wire.footer,
                        reloadContext: wire.reloadContext,
                        websocketParams: wire.websocketParams,
                        usedResources: {
                          resources: updated,
                          track: init.track,
                          flush: init.flush
                        },
                        tour: wire.tour
                      },
                      onDestroy: state.onDestroy,
                      backgroundTasks: state.backgroundTasks
                    }
                  };
        default:
          return "NoUpdate";
      }
    }
  };
  var match = Shared_Lib_Reducer.use(reducer, initial);
  var send = match[1];
  var state = match[0];
  var reloadBackgroundTasks = function (tasks) {
    send({
          TAG: "Response",
          _0: Core__Result.map(tasks, (function (b) {
                  return {
                          TAG: "BackgroundTasks",
                          _0: b
                        };
                }))
        });
  };
  React.useEffect((function () {
          send("CreateWire");
          var onPopState = function (param) {
            var route = Route.parseUrl(Router.pathname(), Router.search());
            send({
                  TAG: "Navigated",
                  _0: route
                });
          };
          Router.onPopState(onPopState);
          return (function () {
                    Router.offPopState(onPopState);
                  });
        }), []);
  useShowOnMount(state.wire);
  var tourSteps = React.useMemo((function () {
          var w = state.wire;
          if (w !== undefined) {
            return Tours.intro(w);
          } else {
            return [];
          }
        }), [RCore.$$Option.isSome(state.wire)]);
  React.useEffect((function () {
          Context.enableFallbacks("en");
        }), []);
  var wire = state.wire;
  if (wire === undefined) {
    return null;
  }
  var user = wire.ctx.user;
  var tmp;
  if (user !== undefined) {
    var match$1 = wire.route.path;
    var match$2 = wire.ctx.permissions;
    var tmp$1;
    var exit = 0;
    var exit$1 = 0;
    var exit$2 = 0;
    if (typeof match$1 === "object") {
      var variant = match$1.NAME;
      if (variant === "MapShow") {
        if (match$2 !== undefined) {
          if (match$2.map === "View") {
            tmp$1 = React.createElement(MapPage.make, {
                  wire: wire,
                  mode: match$1.VAL
                });
          } else {
            exit$2 = 3;
          }
        } else {
          exit = 1;
        }
      } else if (variant === "Job") {
        if (match$2 !== undefined) {
          var match$3 = match$2.jobs;
          if (match$3 === "View" || match$3 === "ViewEditDelete" || match$3 === "ViewEdit") {
            tmp$1 = React.createElement(JobIndex.make, {
                  wire: wire,
                  reloadBackgroundTasks: reloadBackgroundTasks
                });
          } else {
            exit$2 = 3;
          }
        } else {
          exit = 1;
        }
      } else if (variant === "Schedule") {
        if (match$2 !== undefined) {
          var match$4 = match$2.schedule;
          if (match$4 === "View" || match$4 === "ViewEdit") {
            tmp$1 = React.createElement(Schedule.make, {
                  view: match$1.VAL
                });
          } else {
            exit$2 = 3;
          }
        } else {
          exit = 1;
        }
      } else if (variant === "Client" || variant === "Site") {
        if (match$2 !== undefined) {
          var match$5 = match$2.clients;
          if (match$5 === "View" || match$5 === "ViewEdit") {
            var addressType = match$1.NAME === "Client" ? "client" : "site";
            tmp$1 = React.createElement(Pages_Addresses.make, {
                  addressType: addressType
                });
          } else {
            exit$2 = 3;
          }
        } else {
          exit = 1;
        }
      } else if (variant === "Team") {
        if (match$2 !== undefined) {
          var match$6 = match$2.teams;
          if ((match$6 === "View" || match$6 === "ViewEdit") && Context_Types.Features.hasFlag("oldTeamsUi", wire.ctx.features) && !Context_Types.Features.hasFlag("shifts", wire.ctx.features)) {
            tmp$1 = React.createElement(Teams.make, {
                  wire: wire,
                  route: match$1.VAL
                });
          } else {
            exit$2 = 3;
          }
        } else {
          exit = 1;
        }
      } else {
        exit$2 = 3;
      }
    } else if (match$1 === "Shifts") {
      if (match$2 !== undefined) {
        if (match$2.shifts === "View" && Context_Types.Features.hasFlag("shifts", wire.ctx.features)) {
          tmp$1 = React.createElement(ShiftsIndex.make, {});
        } else {
          exit$2 = 3;
        }
      } else {
        exit = 1;
      }
    } else if (match$1 === "TrialEnded") {
      tmp$1 = React.createElement(TrialEnded.make, {
            wire: wire
          });
    } else if (match$1 === "Blocked") {
      tmp$1 = React.createElement(AccessBlocked.make, {
            wire: wire
          });
    } else {
      exit$2 = 3;
    }
    if (exit$2 === 3) {
      if (match$2 !== undefined && match$2.admin === "View") {
        var match$7 = wire.route.path;
        var match$8 = wire.ctx.permissions;
        var exit$3 = 0;
        if (typeof match$7 === "object") {
          var variant$1 = match$7.NAME;
          if (variant$1 === "ClientPortal") {
            tmp$1 = React.createElement(Pages_Client_Portal.make, {});
          } else if (variant$1 === "CustomFields") {
            if (Context_Types.Features.hasFlag("customFields", wire.ctx.features)) {
              tmp$1 = React.createElement(Pages_Custom_Fields.make, {});
            } else {
              exit$3 = 4;
            }
          } else if (variant$1 === "Integrations") {
            if (Context_Types.Features.hasAnyFlag([
                    "amocrm",
                    "bitrix24",
                    "api",
                    "zapier"
                  ], wire.ctx.features)) {
              tmp$1 = React.createElement(Integrations_Index.make, {
                    wire: wire,
                    section: match$7.VAL
                  });
            } else {
              exit$3 = 4;
            }
          } else if (variant$1 === "PrintLayout") {
            var mode = match$7.VAL;
            var exit$4 = 0;
            if (typeof mode === "object") {
              if (mode.NAME === "Edit") {
                exit$4 = 5;
              } else {
                exit$3 = 4;
              }
            } else if (mode === "New" || mode === "Index") {
              exit$4 = 5;
            } else {
              exit$3 = 4;
            }
            if (exit$4 === 5) {
              if (Context_Types.Features.hasFlag("printLayouts", wire.ctx.features)) {
                tmp$1 = React.createElement(PrintLayoutIndex.make, {
                      wire: wire,
                      mode: mode
                    });
              } else {
                exit$3 = 4;
              }
            }
            
          } else if (variant$1 === "Reports") {
            if (Context_Types.Features.hasFlag("reports", wire.ctx.features)) {
              tmp$1 = React.createElement(Reports_Index.make, {
                    wire: wire
                  });
            } else {
              exit$3 = 4;
            }
          } else if (variant$1 === "Template") {
            tmp$1 = React.createElement(Template_Component.make, {
                  wire: wire,
                  route: match$7.VAL
                });
          } else if (variant$1 === "ClientForm") {
            var mode$1 = match$7.VAL;
            var exit$5 = 0;
            if (typeof mode$1 === "object") {
              if (mode$1.NAME === "Edit") {
                exit$5 = 5;
              } else {
                exit$3 = 4;
              }
            } else if (mode$1 === "New" || mode$1 === "Index") {
              exit$5 = 5;
            } else {
              exit$3 = 4;
            }
            if (exit$5 === 5) {
              if (Context_Types.Features.hasFlag("clientTracking", wire.ctx.features)) {
                tmp$1 = React.createElement(ClientForms_Index.make, {
                      wire: wire,
                      mode: mode$1
                    });
              } else {
                exit$3 = 4;
              }
            }
            
          } else if (variant$1 === "Products") {
            tmp$1 = React.createElement(Products_Index.make, {
                  wire: wire
                });
          } else if (variant$1 === "Territories") {
            tmp$1 = React.createElement(Pages_Territories.make, {
                  mode: match$7.VAL
                });
          } else if (variant$1 === "User") {
            tmp$1 = React.createElement(User.make, {
                  wire: wire,
                  route: match$7.VAL
                });
          } else if (variant$1 === "Dictionary") {
            tmp$1 = React.createElement(Dictionary.make, {
                  wire: wire,
                  route: match$7.VAL
                });
          } else if (variant$1 === "Services") {
            tmp$1 = React.createElement(Services_Index.make, {
                  wire: wire
                });
          } else if (variant$1 === "RecurringJob" && match$8 !== undefined && match$8.recurringJobs === "View" && Context_Types.Features.hasFlag("recurringJobs", wire.ctx.features)) {
            tmp$1 = React.createElement(RecurringJob.make, {
                  wire: wire,
                  mode: match$7.VAL
                });
          } else {
            exit$3 = 4;
          }
        } else if (match$7 === "AuditLog") {
          tmp$1 = React.createElement(Pages_Audit.make, {});
        } else if (match$7 === "Shifts") {
          if (Context_Types.Features.hasFlag("shifts", wire.ctx.features)) {
            tmp$1 = React.createElement(ShiftsIndex.make, {});
          } else {
            exit$3 = 4;
          }
        } else if (match$7 === "Forbidden") {
          tmp$1 = React.createElement(Forbidden.make, {
                wire: wire
              });
        } else if (match$7 === "ResolutionIndex") {
          tmp$1 = React.createElement(ResolutionIndex.make, {
                wire: wire
              });
        } else if (match$7 === "Skills") {
          tmp$1 = React.createElement(Pages_Skills.make, {});
        } else if (match$7 === "JobTypeIndex") {
          tmp$1 = React.createElement(JobTypeIndex.make, {
                wire: wire
              });
        } else if (match$7 === "Billing") {
          tmp$1 = React.createElement(Billing_Page.make, {
                wire: wire
              });
        } else if (match$7 === "GeneralSettings") {
          tmp$1 = React.createElement(Settings_Index.make, {
                wire: wire
              });
        } else if (match$7 === "PaddlePaymentSuccess") {
          tmp$1 = React.createElement(Billing_Components_Paddle.Success.make, {
                wire: wire
              });
        } else {
          exit$3 = 4;
        }
        if (exit$3 === 4) {
          tmp$1 = match$7 === "Unknown" ? React.createElement(NotFound.make, {}) : React.createElement(NotFound.make, {});
        }
        
      } else {
        exit$1 = 2;
      }
    }
    if (exit$1 === 2) {
      if (match$1 === "Forbidden") {
        tmp$1 = React.createElement(Forbidden.make, {
              wire: wire
            });
      } else {
        exit = 1;
      }
    }
    if (exit === 1) {
      tmp$1 = React.createElement(NotFound.make, {});
    }
    tmp = React.createElement(Pages_Authenticated.make, {
          user: user,
          wire: wire,
          children: null
        }, React.createElement(Optional.make, {
              show: wire.route.path !== "Forbidden",
              children: React.createElement("div", {
                    className: styles.header
                  }, React.createElement(Header.make, {
                        wire: wire
                      }))
            }), React.createElement(Pages_Wrapper.make, {
              subscriptions: wire.subscriptions,
              children: null
            }, React.createElement("div", {
                  className: styles.content
                }, tmp$1), state.backgroundTasks.length > 0 && wire.route.path !== "Billing" ? React.createElement(BackgroundTasksIndex.make, {
                    tasks: RCore.$$Array.keep(state.backgroundTasks, BackgroundTask.unread),
                    reloadBackgroundTasks: reloadBackgroundTasks,
                    closeAllTasks: (function () {
                        send({
                              TAG: "SetState",
                              _0: {
                                wire: state.wire,
                                onDestroy: state.onDestroy,
                                backgroundTasks: []
                              }
                            });
                      }),
                    wire: wire
                  }) : null));
  } else {
    var match$9 = wire.route.path;
    var exit$6 = 0;
    if (typeof match$9 === "object") {
      var variant$2 = match$9.NAME;
      if (variant$2 === "ResetPassword") {
        tmp = React.createElement(ResetPassword.make, {
              wire: wire,
              token: match$9.VAL
            });
      } else if (variant$2 === "ActivateFieldUser") {
        var match$10 = match$9.VAL;
        tmp = React.createElement(ActivateFieldUser.make, {
              wire: wire,
              token: match$10[1],
              notificationMethod: match$10[0]
            });
      } else if (variant$2 === "SignInByToken") {
        var match$11 = match$9.VAL;
        tmp = React.createElement(SignInByToken.make, {
              wire: wire,
              token: match$11[0],
              redirect: match$11[1]
            });
      } else if (variant$2 === "Activation") {
        tmp = React.createElement(Pages_Activation.make, {
              wire: wire,
              token: match$9.VAL
            });
      } else {
        exit$6 = 1;
      }
    } else if (match$9 === "Forbidden") {
      tmp = React.createElement(Forbidden.make, {
            wire: wire
          });
    } else if (match$9 === "Recover") {
      tmp = React.createElement(Recover.make, {
            wire: wire
          });
    } else if (match$9 === "Login" || match$9 !== "Unknown") {
      exit$6 = 1;
    } else {
      tmp = React.createElement(NotFound.make, {});
    }
    if (exit$6 === 1) {
      tmp = React.createElement(Login.make, {
            wire: wire
          });
    }
    
  }
  return React.createElement(Pages_Context.make, {
              wire: wire,
              ctx: wire.ctx,
              children: React.createElement(Tour_Provider.make, {
                    wire: wire,
                    steps: tourSteps,
                    children: null
                  }, React.createElement(Title.make, {
                        route: wire.route,
                        ctx: wire.ctx,
                        wire: wire
                      }), React.createElement(Loader.make, {
                        subscriptions: wire.subscriptions
                      }), React.createElement(Messenger.make, {
                        wire: wire
                      }), React.createElement(Modals.make, {
                        wire: wire
                      }), React.createElement("div", {
                        className: styles.wrapper,
                        onClick: wire.subscriptions.click.trigger
                      }, tmp))
            });
}

var make$1 = App;

export {
  make$1 as make,
}
/* styles Not a pure module */
