

import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../components/inputs/Checkbox.mjs";
import * as UI_Button from "../../ui/UI_Button.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as Liquid_Editor from "../../libraries/liquid/editor/Liquid_Editor.mjs";
import * as MessengerTypes from "../../components/messenger/MessengerTypes.mjs";
import * as Pages_Client_Portal_API from "./Pages_Client_Portal_API.mjs";
import * as Pages_Client_Portal_SettingsModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/client_portal/Pages_Client_Portal_Settings.module.css";

var enabled$p = Utils.Translations.t("js.client_portal.enabled");

var client$p = Utils.Translations.t("js.client_portal.invitation.substitutions.client");

var clientName$p = Utils.Translations.t("js.client_portal.invitation.substitutions.client_name");

var company$p = Utils.Translations.t("js.client_portal.invitation.substitutions.company");

var companyName$p = Utils.Translations.t("js.client_portal.invitation.substitutions.company_name");

var link$p = Utils.Translations.t("js.client_portal.invitation.substitutions.link");

var email$p = Utils.Translations.t("js.client_portal.invitation.email");

var sms$p = Utils.Translations.t("js.client_portal.invitation.sms");

var save$p = Utils.Translations.tr("common.save");

var updated$p = Utils.Translations.t("js.client_portal.settings.updated");

var internalError$p = Utils.Translations.t("js.client_portal.errors.internal_error");

var Translations = {
  enabled$p: enabled$p,
  client$p: client$p,
  clientName$p: clientName$p,
  company$p: company$p,
  companyName$p: companyName$p,
  link$p: link$p,
  email$p: email$p,
  sms$p: sms$p,
  save$p: save$p,
  updated$p: updated$p,
  internalError$p: internalError$p
};

var styles = Pages_Client_Portal_SettingsModuleCss;

function substitutions(ctx) {
  var clientSubstitutions = [{
      TAG: "String",
      _0: {
        name: clientName$p(ctx),
        key: "name"
      }
    }];
  var companySubstitutions = [{
      TAG: "String",
      _0: {
        name: companyName$p(ctx),
        key: "name"
      }
    }];
  return [
          {
            TAG: "Object",
            _0: {
              children: clientSubstitutions,
              name: client$p(ctx),
              key: "client",
              filters: []
            }
          },
          {
            TAG: "Object",
            _0: {
              children: companySubstitutions,
              name: company$p(ctx),
              key: "company",
              filters: []
            }
          },
          {
            TAG: "String",
            _0: {
              name: link$p(ctx),
              key: "link"
            }
          }
        ];
}

var Errors = {};

function Pages_Client_Portal_Settings(Props) {
  var portal = Props.portal;
  var setPortal = Props.setPortal;
  var initialPortal = React.useMemo((function () {
          return portal;
        }), []);
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return {
                email: [],
                sms: []
              };
      });
  var setErrors = match[1];
  var errors = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setDisabled = match$1[1];
  var showSuccessMessage = function () {
    wire.subscriptions.messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, "Success", {
                TAG: "Text",
                _0: updated$p(ctx)
              })
        });
  };
  var showFailMessage = function () {
    wire.subscriptions.messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, "Danger", {
                TAG: "Text",
                _0: internalError$p(ctx)
              })
        });
  };
  var onToggleEnabled = function (portalEnabled) {
    setDisabled(function (param) {
          return true;
        });
    var value = portalEnabled ? "Enabled" : "Disabled";
    Backend.$$finally(Pages_Client_Portal_API.UpdateStatus.patch(wire, portal.uuid, value), (function (r) {
            if (r.TAG === "Ok") {
              var status = r._0.status;
              setPortal(function (current) {
                    return {
                            uuid: current.uuid,
                            logo: current.logo,
                            users: current.users,
                            settings: current.settings,
                            status: status,
                            emailInvitationStencil: current.emailInvitationStencil,
                            smsInvitationStencil: current.smsInvitationStencil
                          };
                  });
              showSuccessMessage();
            } else {
              console.log(r._0);
              showFailMessage();
            }
            setDisabled(function (param) {
                  return false;
                });
          }));
  };
  var onSubmit = function ($$event) {
    $$event.preventDefault();
    setDisabled(function (param) {
          return true;
        });
    Backend.$$finally(Pages_Client_Portal_API.Settings.Update.patch(wire, portal), (function (r) {
            if (r.TAG === "Ok") {
              showSuccessMessage();
            } else {
              console.log(r._0);
              showFailMessage();
            }
            setDisabled(function (param) {
                  return false;
                });
          }));
  };
  return React.createElement("div", {
              className: styles.wrapper
            }, React.createElement("div", {
                  className: styles.checkboxWrapper
                }, React.createElement(Checkbox.make, {
                      onToggle: onToggleEnabled,
                      checked: portal.status === "Enabled",
                      label: enabled$p(ctx),
                      disabled: false,
                      wrapperClasses: Js_dict.fromList({
                            hd: [
                              styles.enabledCheckbox,
                              true
                            ],
                            tl: /* [] */0
                          })
                    })), React.createElement(Liquid_Editor.make, {
                  maxLength: 1000,
                  label: email$p(ctx),
                  value: portal.emailInvitationStencil,
                  onValueChange: (function (v) {
                      setPortal(function (current) {
                            return {
                                    uuid: current.uuid,
                                    logo: current.logo,
                                    users: current.users,
                                    settings: current.settings,
                                    status: current.status,
                                    emailInvitationStencil: v,
                                    smsInvitationStencil: current.smsInvitationStencil
                                  };
                          });
                    }),
                  substitutions: substitutions(ctx),
                  errors: errors.email,
                  onErrorsChange: (function (email) {
                      setErrors(function (current) {
                            return {
                                    email: email,
                                    sms: current.sms
                                  };
                          });
                    })
                }), React.createElement(Liquid_Editor.make, {
                  maxLength: 250,
                  label: sms$p(ctx),
                  value: portal.smsInvitationStencil,
                  onValueChange: (function (v) {
                      setPortal(function (current) {
                            return {
                                    uuid: current.uuid,
                                    logo: current.logo,
                                    users: current.users,
                                    settings: current.settings,
                                    status: current.status,
                                    emailInvitationStencil: current.emailInvitationStencil,
                                    smsInvitationStencil: v
                                  };
                          });
                    }),
                  substitutions: substitutions(ctx),
                  errors: errors.sms,
                  onErrorsChange: (function (sms) {
                      setErrors(function (current) {
                            return {
                                    email: current.email,
                                    sms: sms
                                  };
                          });
                    })
                }), React.createElement("div", {
                  className: styles.footer
                }, React.createElement(UI_Button.make, {
                      purpose: "primary",
                      className: styles.saveButton,
                      disabled: portal.emailInvitationStencil === initialPortal.emailInvitationStencil && portal.smsInvitationStencil === initialPortal.smsInvitationStencil || match$1[0] || Caml_obj.notequal(errors, {
                            email: [],
                            sms: []
                          }),
                      onClick: onSubmit,
                      children: save$p(ctx)
                    })));
}

var Types;

var make = Pages_Client_Portal_Settings;

export {
  Types ,
  Translations ,
  styles ,
  substitutions ,
  Errors ,
  make ,
}
/* enabled' Not a pure module */
