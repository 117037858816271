

import * as RCore from "../../libraries/RCore.mjs";
import * as React from "react";
import * as Locale from "../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as JsContext from "../../libraries/JsContext.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Shared_Lib_Option from "../../shared/lib/Shared_Lib_Option.mjs";

function DateInput(Props) {
  var value = Props.value;
  var onFocus = Props.onFocus;
  var onDateChange = Props.onDateChange;
  var disabled = Props.disabled;
  var match = React.useState(function () {
        return value;
      });
  var setInputValue = match[1];
  var wire = AppContext.useWire();
  var onChange = function (e) {
    e.preventDefault();
    var value = e.target.value;
    setInputValue(function (param) {
          return value;
        });
  };
  var onBlur = function (e) {
    var newValue = e.target.value;
    if (!Caml_obj.notequal(value, newValue)) {
      return ;
    }
    var parsedDate = Locale.T.parse("Date", JsContext.get(wire))(newValue);
    if (Locale.T.isValid(parsedDate)) {
      return onDateChange(Caml_option.some(parsedDate));
    } else if (value !== undefined) {
      return onDateChange(undefined);
    } else {
      return ;
    }
  };
  var displayValue = RCore.$$Option.getOr(Shared_Lib_Option.alt(match[0], value), "");
  React.useEffect((function () {
          setInputValue(function (param) {
                return value;
              });
        }), [value]);
  return React.createElement("input", {
              className: "j-scheduled__input",
              disabled: disabled,
              type: "text",
              value: displayValue,
              onFocus: onFocus,
              onBlur: onBlur,
              onChange: onChange
            });
}

var make = DateInput;

export {
  make ,
}
/* react Not a pure module */
