

import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as JsonDecode from "../../../../../libraries/JsonDecode.mjs";
import * as Schedule_Types_Mode from "../../types/Schedule_Types_Mode.mjs";
import * as Schedule_StorageType from "./Schedule_StorageType.mjs";
import * as Schedule_Types_Settings from "../../types/Schedule_Types_Settings.mjs";
import * as Schedule_Types_JobDuration from "../../types/Schedule_Types_JobDuration.mjs";

var decodeFilter = JsonDecode.object(function (field) {
      return {
              templates: [],
              territories: field.required("territories", JsonDecode.array(Uuid.decode)),
              skills: field.required("skills", Schedule_Types_Settings.Filter.Skills.decode),
              assigneesFilter: field.required("assignees", JsonDecode.oneOf([
                        Schedule_Types_Settings.Filter.Assignees.decode,
                        JsonDecode.custom(function (param) {
                              return "All";
                            })
                      ]))
            };
    });

function decode(wire) {
  return JsonDecode.object(function (field) {
              return {
                      filter: field.required("filter", decodeFilter),
                      view: field.required("view", Schedule_StorageType.View.decode),
                      mode: Schedule_Types_Mode.initial(wire),
                      jobDuration: "Scheduled"
                    };
            });
}

var decodeFilter$1 = JsonDecode.object(function (field) {
      return {
              templates: RCore.$$Option.getOr(field.optional("templates", JsonDecode.array(Uuid.decode)), []),
              territories: field.required("territories", JsonDecode.array(Uuid.decode)),
              skills: field.required("skills", Schedule_Types_Settings.Filter.Skills.decode),
              assigneesFilter: field.required("assignees", JsonDecode.oneOf([
                        Schedule_Types_Settings.Filter.Assignees.decode,
                        JsonDecode.custom(function (param) {
                              return "All";
                            })
                      ]))
            };
    });

var decode$1 = JsonDecode.object(function (field) {
      return {
              filter: field.required("filter", decodeFilter$1),
              view: field.required("view", Schedule_StorageType.View.decode),
              mode: field.required("mode", Schedule_Types_Mode.decode),
              jobDuration: field.required("jobDuration", Schedule_Types_JobDuration.decode)
            };
    });

function decode$2(wire) {
  return JsonDecode.object(function (field) {
              var match = field.required("version", Schedule_StorageType.Version.decode);
              if (match === "V1") {
                return field.required("data", decode(wire));
              } else {
                return field.required("data", decode$1);
              }
            });
}

export {
  decode$2 as decode,
}
/* decodeFilter Not a pure module */
