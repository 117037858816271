

import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as UI_Page from "../../ui/UI_Page.mjs";
import * as DomUtils from "../../DomUtils.mjs";
import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as UI_PageTabs from "../../ui/UI_PageTabs.mjs";
import * as Reports_Jobs from "./Reports_Jobs.mjs";
import * as Reports_Workers from "./Reports_Workers.mjs";
import * as ReportsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/reports/Reports.module.css";

var pageTitle$p = Utils.Translations.tr("js.reports.title");

var jobsTab$p = Utils.Translations.tr("js.reports.jobs.title");

var workersTab$p = Utils.Translations.tr("js.reports.workers.title");

var styles = ReportsModuleCss;

function Reports_Index(Props) {
  var wire = Props.wire;
  var match = React.useState(function () {
        return "100vh";
      });
  var setIframeHeight = match[1];
  var iframeHeight = match[0];
  var handleSizeEvent = function (evt) {
    var data = evt.data;
    setIframeHeight(function (param) {
          return PervasivesU.string_of_float(data.height) + "px";
        });
  };
  React.useEffect((function () {
          DomUtils.Listeners.onMessage(handleSizeEvent);
          return (function () {
                    DomUtils.Listeners.removeOnMessage(handleSizeEvent);
                  });
        }), []);
  return React.createElement(UI_Page.make, {
              children: null
            }, React.createElement("div", {
                  className: styles.header
                }, React.createElement("h1", undefined, pageTitle$p(wire.ctx))), React.createElement(UI_PageTabs.make, {
                  children: null
                }, React.createElement(UI_PageTabs.Tab.make, {
                      route: {
                        NAME: "Reports",
                        VAL: "Jobs"
                      },
                      renderLabel: (function () {
                          return jobsTab$p(wire.ctx);
                        }),
                      children: React.createElement(Reports_Jobs.make, {
                            wire: wire,
                            iframeHeight: iframeHeight
                          })
                    }), React.createElement(UI_PageTabs.Tab.make, {
                      route: {
                        NAME: "Reports",
                        VAL: "Workers"
                      },
                      renderLabel: (function () {
                          return workersTab$p(wire.ctx);
                        }),
                      children: React.createElement(Reports_Workers.make, {
                            wire: wire,
                            iframeHeight: iframeHeight
                          })
                    })));
}

var make = Reports_Index;

export {
  make ,
}
/* pageTitle' Not a pure module */
