

import * as Uuid from "../bindings/uuid/Uuid.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as JsonDecode from "../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function make(uuid, name) {
  return {
          uuid: uuid,
          name: name
        };
}

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var Category = {
  make: make,
  decode: decode
};

function fromJs(js) {
  return {
          serviceUuid: js.serviceUuid,
          name: js.name,
          description: Caml_option.null_to_opt(js.description),
          priceDefault: Caml_option.null_to_opt(js.priceDefault),
          currency: js.currency,
          category: Caml_option.null_to_opt(js.category),
          archived: js.archived
        };
}

function toJs(service) {
  return {
          serviceUuid: service.serviceUuid,
          name: service.name,
          description: Js_null.fromOption(service.description),
          priceDefault: Js_null.fromOption(service.priceDefault),
          currency: service.currency,
          category: Js_null.fromOption(service.category),
          archived: service.archived
        };
}

function make$1(serviceUuid, name, description, priceDefault, currency, category, archived) {
  return {
          serviceUuid: serviceUuid,
          name: name,
          description: description,
          priceDefault: priceDefault,
          currency: currency,
          category: category,
          archived: archived
        };
}

var Service = {
  Category: Category,
  fromJs: fromJs,
  toJs: toJs,
  make: make$1
};

var OrderedService = {};

export {
  Service ,
  OrderedService ,
}
/* decode Not a pure module */
