

import * as El from "../../../../../../../../../libraries/El.mjs";
import * as Utils from "../../../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as $$Number from "../../../../../../../../../libraries/Number.mjs";
import * as JobForm_HistoryTypes from "../../../../../../lib/types/JobForm_HistoryTypes.mjs";
import * as JobForm_HistoryFieldUpdatedEvent from "../field_updated_event/JobForm_HistoryFieldUpdatedEvent.mjs";
import * as JobForm_HistoryEventValueModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/history/components/events/event_value/JobForm_HistoryEventValue.module.css";

var jobCreated$p = Utils.Translations.tr("js.jobs.history.events.job_created");

var jobUpdated$p = Utils.Translations.tr("js.jobs.history.events.job_updated");

var buttonTriggered$p = Utils.Translations.tr("js.jobs.history.events.button_triggered");

var jobViewed$p = Utils.Translations.tr("js.jobs.history.events.job_viewed");

var jobPrinted$p = Utils.Translations.tr("js.jobs.history.events.job_printed");

var messageSent$p = Utils.Translations.tr("js.jobs.history.events.message_sent");

var serviceProvided$p = Utils.Translations.txr("js.jobs.history.events.service_provided");

var downloadPrintView$p = Utils.Translations.tr("js.jobs.history.events.download_print_view");

var canceled$p = Utils.Translations.tr("js.jobs.history.events.statuses.canceled");

var finished$p = Utils.Translations.tr("js.jobs.history.events.statuses.finished");

var movementFinished$p = Utils.Translations.tr("js.jobs.history.events.movement_finished");

function meters$p(distance, ctx) {
  return Utils.Translations.txr("js.jobs.history.distance.meter")({
              distance: distance
            }, ctx);
}

function kilometers$p(distance, ctx) {
  return Utils.Translations.txr("js.jobs.history.distance.kilometer")({
              distance: distance
            }, ctx);
}

function feet$p(distance, ctx) {
  return Utils.Translations.txr("js.jobs.history.distance.foot")({
              distance: distance
            }, ctx);
}

function miles$p(distance, ctx) {
  return Utils.Translations.txr("js.jobs.history.distance.mile")({
              distance: distance
            }, ctx);
}

var styles = JobForm_HistoryEventValueModuleCss;

function JobForm_HistoryEventValue$StatusUpdatedComponent(Props) {
  var param = Props.wire;
  var status = Props.status;
  var status$1 = JobForm_HistoryTypes.StatusUpdated.toString(status);
  var c = param.ctx;
  return Utils.Translations.tr("js.jobs.history.events.statuses." + status$1)(c);
}

function JobForm_HistoryEventValue$JobFinishedComponent(Props) {
  var param = Props.wire;
  var param$1 = Props.value;
  var resolution = param$1.resolution;
  var comment = param$1.comment;
  var className = El.Cn.concat(styles.resolution, resolution.successful ? styles.successful : styles.unsuccessful);
  return React.createElement("div", undefined, React.createElement("div", undefined, finished$p(param.ctx)), React.createElement("div", {
                  className: className
                }, resolution.name), React.createElement("div", undefined, comment !== undefined ? React.createElement("div", {
                        className: styles.comment
                      }, React.createElement("div", undefined, comment)) : null));
}

function JobForm_HistoryEventValue$JobCanceledComponent(Props) {
  var param = Props.wire;
  var param$1 = Props.value;
  var comment = param$1.comment;
  return React.createElement("div", undefined, React.createElement("div", undefined, canceled$p(param.ctx)), React.createElement("div", {
                  className: El.Cn.concat(styles.resolution, styles.unsuccessful)
                }, param$1.resolution.name), React.createElement("div", undefined, comment !== undefined ? React.createElement("div", {
                        className: styles.comment
                      }, React.createElement("div", undefined, comment)) : null));
}

var icon = React.createElement("svg", {
      height: "11",
      width: "11",
      fill: "none",
      viewBox: "0 0 11 11",
      xmlns: "http://www.w3.org/2000/svg"
    }, React.createElement("path", {
          clipRule: "evenodd",
          d: "M6.87437 6.87463H8.93675C10.0732 6.87463 10.9991 7.80055 10.9991 8.93702C10.9991 10.0735 10.0732 10.9994 8.93675 10.9994H2.96872C3.25874 10.6492 3.64114 10.1573 3.98487 9.62448H8.93675C9.31486 9.62448 9.62422 9.31512 9.62422 8.93702C9.62422 8.55891 9.31486 8.24955 8.93675 8.24955H6.87437C5.7379 8.24955 4.81198 7.32363 4.81198 6.18717C4.81198 5.0507 5.7379 4.12478 6.87437 4.12478H7.84756C7.35129 3.42442 6.87437 2.61021 6.87437 2.06239C6.87437 0.923778 7.79814 0 8.93675 0C10.0754 0 10.9991 0.923778 10.9991 2.06239C10.9991 3.201 8.93675 5.4997 8.93675 5.4997H6.87437C6.49626 5.4997 6.1869 5.80906 6.1869 6.18717C6.1869 6.56527 6.49626 6.87463 6.87437 6.87463ZM9.62443 2.06239C9.62443 1.68213 9.31722 1.37492 8.93696 1.37492C8.55671 1.37492 8.2495 1.68213 8.2495 2.06239C8.2495 2.44264 8.55671 2.74985 8.93696 2.74985C9.31722 2.74985 9.62443 2.44264 9.62443 2.06239ZM2.06237 5.49971C0.923755 5.49971 -2.30163e-05 6.42349 -2.30163e-05 7.5621C-2.30163e-05 8.70071 2.06237 10.9994 2.06237 10.9994C2.06237 10.9994 4.12475 8.70071 4.12475 7.5621C4.12475 6.42349 3.20098 5.49971 2.06237 5.49971ZM1.37496 7.5621C1.37496 7.94235 1.68217 8.24956 2.06242 8.24956C2.44267 8.24956 2.74988 7.94235 2.74988 7.5621C2.74988 7.18184 2.44267 6.87463 2.06242 6.87463C1.68217 6.87463 1.37496 7.18184 1.37496 7.5621Z",
          fill: "#CCCCCC",
          fillRule: "evenodd"
        }));

function JobForm_HistoryEventValue$MovementFinished(Props) {
  var param = Props.wire;
  var value = Props.value;
  var ctx = param.ctx;
  var value$1;
  switch (value.TAG) {
    case "Meters" :
        value$1 = meters$p(value._0, ctx);
        break;
    case "Kilometers" :
        var formatted = $$Number.formatFloat(ctx, value._0);
        value$1 = kilometers$p(formatted, ctx);
        break;
    case "Feet" :
        value$1 = feet$p(value._0, ctx);
        break;
    case "Miles" :
        var formatted$1 = $$Number.formatFloat(ctx, value._0);
        value$1 = miles$p(formatted$1, ctx);
        break;
    
  }
  return React.createElement("div", {
              className: styles.movement
            }, icon, React.createElement("p", {
                  className: styles.movementText
                }, movementFinished$p(ctx), value$1));
}

function onClickLink($$event) {
  $$event.stopPropagation();
}

function JobForm_HistoryEventValue$JobPrinted(Props) {
  var param = Props.wire;
  var url = Props.url;
  var ctx = param.ctx;
  return React.createElement("div", {
              className: styles.jobPrinted
            }, jobPrinted$p(ctx), React.createElement("a", {
                  href: url,
                  target: "_blank",
                  onClick: onClickLink
                }, downloadPrintView$p(ctx)));
}

function JobForm_HistoryEventValue$MessageSent(Props) {
  var ctx = Props.ctx;
  var text = Props.text;
  return React.createElement("div", {
              className: styles.messageSent
            }, messageSent$p(ctx), React.createElement("div", {
                  className: styles.messageSentText
                }, text));
}

function JobForm_HistoryEventValue(Props) {
  var wire = Props.wire;
  var value = Props.value;
  var allImages = Props.allImages;
  var ctx = wire.ctx;
  if (typeof value !== "object") {
    if (value === "JobViewed") {
      return jobViewed$p(ctx);
    } else if (value === "JobCreated") {
      return jobCreated$p(ctx);
    } else if (value === "JobUpdated") {
      return jobUpdated$p(ctx);
    } else if (value === "Unknown") {
      return null;
    } else {
      return buttonTriggered$p(ctx);
    }
  }
  var variant = value.NAME;
  if (variant === "StatusUpdated") {
    return React.createElement(JobForm_HistoryEventValue$StatusUpdatedComponent, {
                wire: wire,
                status: value.VAL
              });
  } else if (variant === "MessageSent") {
    return React.createElement(JobForm_HistoryEventValue$MessageSent, {
                ctx: wire.ctx,
                text: value.VAL
              });
  } else if (variant === "ServiceProvided") {
    return serviceProvided$p({
                name: value.VAL
              }, ctx);
  } else if (variant === "Finished") {
    return React.createElement(JobForm_HistoryEventValue$JobFinishedComponent, {
                wire: wire,
                value: value.VAL
              });
  } else if (variant === "FieldUpdated") {
    return React.createElement(JobForm_HistoryFieldUpdatedEvent.make, {
                wire: wire,
                field: value.VAL,
                allImages: allImages
              });
  } else if (variant === "Canceled") {
    return React.createElement(JobForm_HistoryEventValue$JobCanceledComponent, {
                wire: wire,
                value: value.VAL
              });
  } else if (variant === "MovementFinished") {
    return React.createElement(JobForm_HistoryEventValue$MovementFinished, {
                wire: wire,
                value: value.VAL
              });
  } else {
    return React.createElement(JobForm_HistoryEventValue$JobPrinted, {
                wire: wire,
                url: value.VAL
              });
  }
}

var make = JobForm_HistoryEventValue;

export {
  make ,
}
/* jobCreated' Not a pure module */
