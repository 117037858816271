

import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Template_Types from "./Template_Types.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              templates: field.required("templates", JsonDecode.array(Template_Types.Index.Template.decode))
            };
    });

function index(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Template",
                    VAL: "Index"
                  }
                }), decode);
}

var decode$1 = JsonDecode.object(function (field) {
      return field.required("template", Template_Types.Index.Template.decode);
    });

var Get = {
  decode: decode$1
};

function new_(wire, copy) {
  return Backend.get(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Template",
                VAL: {
                  NAME: "New",
                  VAL: copy
                }
              }
            });
}

function show(wire, uuid) {
  return Backend.get(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Template",
                VAL: {
                  NAME: "Edit",
                  VAL: uuid
                }
              }
            });
}

function destroy(wire, uuid) {
  return Backend.$$delete(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Template",
                VAL: {
                  NAME: "Edit",
                  VAL: uuid
                }
              }
            });
}

var Show = {
  new_: new_,
  show: show,
  destroy: destroy
};

var IndexTemplate;

var Index_Response = {};

var Index = {
  $$Response: Index_Response,
  index: index
};

export {
  IndexTemplate ,
  Index ,
  Get ,
  Show ,
}
/* decode Not a pure module */
