

import * as Link from "../../components/common/Link/Link.mjs";
import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Modal from "../../components/modal/Modal.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as UI_Icon from "../../ui/UI_Icon.mjs";
import * as MomentRe from "../../bindings/moment/MomentRe.mjs";
import * as Optional from "../../components/common/Optional.mjs";
import * as UI_Button from "../../ui/UI_Button.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Backend_Errors from "../../libraries/backend/Backend_Errors.mjs";
import * as MessengerTypes from "../../components/messenger/MessengerTypes.mjs";
import * as Pages_Client_API from "../clients/edit/Pages_Client_API.mjs";
import * as Pages_Client_Types from "../clients/edit/Pages_Client_Types.mjs";
import * as Pages_Client_Portal_API from "./Pages_Client_Portal_API.mjs";
import * as Pages_Client_Portal_Types from "./Pages_Client_Portal_Types.mjs";
import * as Pages_Client_Portal_UsersModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/client_portal/Pages_Client_Portal_Users.module.css";

var addClient$p = Utils.Translations.tr("js.client_portal.users.add_client");

var noClients$p = Utils.Translations.tr("js.client_portal.users.no_clients");

var name$p = Utils.Translations.tr("js.client_portal.users.name");

var phoneNumber$p = Utils.Translations.tr("js.client_portal.users.phone_number");

var email$p = Utils.Translations.tr("js.client_portal.users.email");

var lastSignInAt$p = Utils.Translations.tr("js.client_portal.users.last_sign_in_at");

var resendInvite$p = Utils.Translations.tr("js.client_portal.users.resend_invite");

var cancel$p = Utils.Translations.tr("common.cancel");

var search$p = Utils.Translations.tr("js.client_portal.users.search");

var searchPlaceholder$p = Utils.Translations.t("js.client_portal.users.search_placeholder");

var inviteSentEmail$p = Utils.Translations.t("js.client_portal.users.invite_sent_email");

var inviteSentPhone$p = Utils.Translations.t("js.client_portal.users.invite_sent_phone");

var internalError$p = Utils.Translations.t("js.client_portal.errors.internal_error");

var emailTaken$p = Utils.Translations.t("js.client_portal.errors.email_taken");

var phoneTaken$p = Utils.Translations.t("js.client_portal.errors.phone_taken");

var noEmailOrPhone$p = Utils.Translations.t("js.client_portal.errors.no_email_or_phone");

var Translations = {
  addClient$p: addClient$p,
  noClients$p: noClients$p,
  name$p: name$p,
  phoneNumber$p: phoneNumber$p,
  email$p: email$p,
  lastSignInAt$p: lastSignInAt$p,
  resendInvite$p: resendInvite$p,
  cancel$p: cancel$p,
  search$p: search$p,
  searchPlaceholder$p: searchPlaceholder$p,
  inviteSentEmail$p: inviteSentEmail$p,
  inviteSentPhone$p: inviteSentPhone$p,
  internalError$p: internalError$p,
  emailTaken$p: emailTaken$p,
  phoneTaken$p: phoneTaken$p,
  noEmailOrPhone$p: noEmailOrPhone$p
};

var styles = Pages_Client_Portal_UsersModuleCss;

function Pages_Client_Portal_Users$TableHeading(Props) {
  var ctx = Props.ctx;
  return React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", {
                      className: styles.clientsTableName
                    }, name$p(ctx)), React.createElement("th", {
                      className: styles.clientsTablePhone
                    }, phoneNumber$p(ctx)), React.createElement("th", {
                      className: styles.clientsTableEmail
                    }, email$p(ctx)), React.createElement("th", {
                      className: styles.clientsTableLastSignInAt
                    }, lastSignInAt$p(ctx)), React.createElement("th", {
                      className: styles.clientsTableDelete
                    })));
}

var TableHeading = {
  make: Pages_Client_Portal_Users$TableHeading
};

function Pages_Client_Portal_Users$Row(Props) {
  var user = Props.user;
  var onClientAdd = Props.onClientAdd;
  var onClientDelete = Props.onClientDelete;
  var wire = Props.wire;
  var at = user.lastSignInAt;
  return React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement(Link.make, {
                      wire: wire,
                      modals: {
                        hd: {
                          NAME: "Client",
                          VAL: {
                            NAME: "Edit",
                            VAL: user.clientUuid
                          }
                        },
                        tl: /* [] */0
                      },
                      children: user.name
                    })), React.createElement("td", undefined, RCore.$$Option.getOr(user.phoneNumber, "")), React.createElement("td", undefined, RCore.$$Option.getOr(user.email, "")), React.createElement("td", undefined, at !== undefined ? MomentRe.Moment.format(Caml_option.valFromOption(at), "D MMMM HH:mm:ss") : React.createElement(UI_Button.make, {
                        flavor: "link",
                        className: styles.resendButton,
                        onClick: (function (e) {
                            onClientAdd(e, user.clientUuid);
                          }),
                        children: resendInvite$p(wire.ctx)
                      })), React.createElement("td", undefined, React.createElement(UI_Button.make, {
                      flavor: "link",
                      className: styles.cancelButton,
                      onClick: (function (e) {
                          e.preventDefault();
                          onClientDelete(user);
                        }),
                      children: cancel$p(wire.ctx)
                    })));
}

var Row = {
  make: Pages_Client_Portal_Users$Row
};

function Pages_Client_Portal_Users$ClientTable(Props) {
  var users = Props.users;
  var onClientAdd = Props.onClientAdd;
  var onClientDelete = Props.onClientDelete;
  var wire = Props.wire;
  return React.createElement("table", {
              className: styles.clientsTable
            }, React.createElement(Pages_Client_Portal_Users$TableHeading, {
                  ctx: wire.ctx
                }), React.createElement("tbody", undefined, users.map(function (user) {
                      return React.createElement(Pages_Client_Portal_Users$Row, {
                                  user: user,
                                  onClientAdd: onClientAdd,
                                  onClientDelete: onClientDelete,
                                  wire: wire,
                                  key: Uuid.toString(user.uuid)
                                });
                    })));
}

var ClientTable = {
  make: Pages_Client_Portal_Users$ClientTable
};

function Pages_Client_Portal_Users$SearchModal(Props) {
  var portal = Props.portal;
  var showLookup = Props.showLookup;
  var setShowLookup = Props.setShowLookup;
  var onClientAdd = Props.onClientAdd;
  var wire = Props.wire;
  var match = React.useState(function () {
        return [];
      });
  var setLookupOptions = match[1];
  var lookupOptions = match[0];
  var closeLookup = function (param) {
    setShowLookup(function (param) {
          return false;
        });
    setLookupOptions(function (param) {
          return [];
        });
  };
  var onInput = function (e) {
    e.preventDefault();
    var value = e.target.value;
    if (value.length > 2) {
      return Backend.$$finally(Backend.decode(Pages_Client_API.Lookup.send(wire, value), Pages_Client_Types.Lookup.decode), (function (result) {
                    if (result.TAG === "Ok") {
                      var lookup = result._0;
                      return setLookupOptions(function (param) {
                                  return RCore.$$Array.keep(lookup, (function (o) {
                                                return !portal.users.some(function (c) {
                                                            return Uuid.equal(c.clientUuid, o.clientUuid);
                                                          });
                                              }));
                                });
                    }
                    console.log(result._0);
                  }));
    } else {
      return setLookupOptions(function (param) {
                  return [];
                });
    }
  };
  return React.createElement(Modal.make, {
              wire: wire,
              onClose: closeLookup,
              show: showLookup,
              children: React.createElement("div", {
                    className: styles.clientSearchModal
                  }, React.createElement("div", {
                        className: styles.clientSearchModalHeader
                      }, search$p(wire.ctx), React.createElement(UI_Button.make, {
                            flavor: "close",
                            onClick: closeLookup,
                            children: React.createElement(UI_Icon.make, {
                                  icon: "times"
                                })
                          })), React.createElement("div", {
                        className: styles.clientSearchModalBody
                      }, React.createElement("input", {
                            className: styles.clientSearchInput,
                            placeholder: searchPlaceholder$p(wire.ctx),
                            type: "text",
                            onChange: onInput
                          }), React.createElement(Optional.make, {
                            show: lookupOptions.length > 0,
                            children: React.createElement("div", {
                                  className: styles.clientSearchOptions
                                }, lookupOptions.map(function (o) {
                                      return React.createElement("div", {
                                                  key: Uuid.toString(o.clientUuid),
                                                  className: styles.clientSearchOption,
                                                  onClick: (function (e) {
                                                      setLookupOptions(function (param) {
                                                            return [];
                                                          });
                                                      onClientAdd(e, o.clientUuid);
                                                    })
                                                }, React.createElement("div", undefined, o.name), React.createElement("div", undefined, o.address));
                                    }))
                          })))
            });
}

var SearchModal = {
  make: Pages_Client_Portal_Users$SearchModal
};

function Pages_Client_Portal_Users(Props) {
  var portal = Props.portal;
  var setPortal = Props.setPortal;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setDisabled = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowLookup = match$1[1];
  var onClientAdd = function (e, clientUuid) {
    e.preventDefault();
    setDisabled(function (param) {
          return true;
        });
    setShowLookup(function (param) {
          return false;
        });
    Backend.$$finally(Backend.decode(Pages_Client_Portal_API.Users.Create.post(wire, portal.uuid, clientUuid), Pages_Client_Portal_Types.State.Portal.User.decode), (function (result) {
            if (result.TAG === "Ok") {
              var user = result._0;
              setPortal(function (current) {
                    var u = RCore.$$Array.getBy(current.users, (function (c) {
                            return Uuid.equal(c.clientUuid, user.clientUuid);
                          }));
                    var users = u !== undefined ? RCore.$$Array.keepMap(current.users, (function (c) {
                              if (Uuid.equal(c.uuid, u.uuid)) {
                                return user;
                              } else {
                                return c;
                              }
                            })) : Belt_Array.concatMany([
                            [user],
                            current.users
                          ]);
                    return {
                            uuid: current.uuid,
                            logo: current.logo,
                            users: users,
                            settings: current.settings,
                            status: current.status,
                            emailInvitationStencil: current.emailInvitationStencil,
                            smsInvitationStencil: current.smsInvitationStencil
                          };
                  });
              var match = user.phoneNumber;
              return wire.subscriptions.messenger.trigger({
                          TAG: "Show",
                          _0: MessengerTypes.Message.make(undefined, "Success", {
                                TAG: "Text",
                                _0: match !== undefined ? inviteSentPhone$p(wire.ctx) : inviteSentEmail$p(wire.ctx)
                              })
                        });
            }
            var errors = result._0;
            if (typeof errors === "object" && errors.TAG === "ValidationError") {
              var match$1 = Backend_Errors.get(errors._0, "clientUuid");
              var tmp;
              if (match$1 !== undefined && match$1.length === 1) {
                var match$2 = match$1[0];
                switch (match$2) {
                  case "email taken" :
                      tmp = emailTaken$p(ctx);
                      break;
                  case "phone taken" :
                      tmp = phoneTaken$p(ctx);
                      break;
                  default:
                    tmp = noEmailOrPhone$p(wire.ctx);
                }
              } else {
                tmp = noEmailOrPhone$p(wire.ctx);
              }
              return wire.subscriptions.messenger.trigger({
                          TAG: "Show",
                          _0: MessengerTypes.Message.make(undefined, "Danger", {
                                TAG: "Text",
                                _0: tmp
                              })
                        });
            }
            console.log(errors);
            wire.subscriptions.messenger.trigger({
                  TAG: "Show",
                  _0: MessengerTypes.Message.make(undefined, "Danger", {
                        TAG: "Text",
                        _0: internalError$p(wire.ctx)
                      })
                });
          }));
    setDisabled(function (param) {
          return false;
        });
  };
  var onClientDelete = function (user) {
    setDisabled(function (param) {
          return true;
        });
    Backend.$$finally(Pages_Client_Portal_API.Users.Delete.$$delete(wire, user.uuid), (function (r) {
            if (r.TAG === "Ok") {
              setPortal(function (current) {
                    return {
                            uuid: current.uuid,
                            logo: current.logo,
                            users: RCore.$$Array.keep(current.users, (function (c) {
                                    return !Uuid.equal(c.uuid, user.uuid);
                                  })),
                            settings: current.settings,
                            status: current.status,
                            emailInvitationStencil: current.emailInvitationStencil,
                            smsInvitationStencil: current.smsInvitationStencil
                          };
                  });
            } else {
              console.log(r._0);
            }
            setDisabled(function (param) {
                  return false;
                });
          }));
  };
  return React.createElement("div", {
              className: styles.wrapper
            }, React.createElement(UI_Button.make, {
                  flavor: "link",
                  disabled: match[0],
                  onClick: (function (e) {
                      e.preventDefault();
                      setShowLookup(function (param) {
                            return true;
                          });
                    }),
                  children: null
                }, React.createElement(UI_Icon.make, {
                      className: styles.addClientButtonIcon,
                      icon: "plus"
                    }), addClient$p(ctx)), React.createElement(Pages_Client_Portal_Users$SearchModal, {
                  portal: portal,
                  showLookup: match$1[0],
                  setShowLookup: setShowLookup,
                  onClientAdd: onClientAdd,
                  wire: wire
                }), portal.users.length === 0 ? React.createElement("div", {
                    className: styles.noClients
                  }, noClients$p(ctx)) : React.createElement(Pages_Client_Portal_Users$ClientTable, {
                    users: portal.users,
                    onClientAdd: onClientAdd,
                    onClientDelete: onClientDelete,
                    wire: wire
                  }));
}

var Types;

var make = Pages_Client_Portal_Users;

export {
  Types ,
  Translations ,
  styles ,
  TableHeading ,
  Row ,
  ClientTable ,
  SearchModal ,
  make ,
}
/* addClient' Not a pure module */
