

import * as Hint from "../../common/Hint/Hint.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Select from "../../inputs/Select.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as UI_Form from "../../../ui/UI_Form.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../common/Optional.mjs";
import * as UI_Label from "../../../ui/UI_Label.mjs";
import * as FormInput from "../../inputs/FormInput.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as SkillsSelect from "../../inputs/SkillsSelect.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as MultipleAssignee from "../../../types/MultipleAssignee.mjs";
import * as DurationSpinnerJs from "../../common/DurationSpinnerJs.mjs";
import * as MultipleAssignees from "../../common/MultipleAssignees/MultipleAssignees.mjs";
import * as TerritoriesSelect from "../../inputs/TerritoriesSelect.mjs";
import * as JobEdit_PossibleAssigneesUtils from "../../job/edit/lib/utils/JobEdit_PossibleAssigneesUtils.mjs";
import * as Template_Job_InfoModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/template/Template_Job_Info.module.css";

var empty$p = Utils.Translations.tr("js.templates.info.errors.name_empty");

var noAssigneesWithSkills$p = Utils.Translations.tr("js.templates.messages.no_assignees_with_skills");

var noAssigneesOnTerritory$p = Utils.Translations.tr("js.templates.messages.no_assignees_on_territory");

var name$p = Utils.Translations.tr("js.templates.info.labels.name");

var type$p = Utils.Translations.tr("js.templates.info.labels.type");

var description$p = Utils.Translations.t("js.templates.info.labels.description");

var duration$p = Utils.Translations.tr("js.templates.info.labels.duration");

var skills$p = Utils.Translations.tr("js.templates.info.labels.assignee_skills");

var territory$p = Utils.Translations.tr("js.templates.info.labels.territory");

var assignee$p = Utils.Translations.tr("js.templates.info.labels.assignee_subject");

var nameHint$p = Utils.Translations.t("js.templates.info.hints.name");

var typePlaceholder$p = Utils.Translations.t("js.templates.info.placeholders.type");

var skillsHint$p = Utils.Translations.t("js.jobs.hints.skills");

var skillsPlaceholder$p = Utils.Translations.t("js.components.skills_select.placeholder");

var territoryHint$p = Utils.Translations.t("js.jobs.hints.territories");

var territoryPlaceholder$p = Utils.Translations.t("js.components.territories_select.placeholder");

var Translations = {
  empty$p: empty$p,
  noAssigneesWithSkills$p: noAssigneesWithSkills$p,
  noAssigneesOnTerritory$p: noAssigneesOnTerritory$p,
  name$p: name$p,
  type$p: type$p,
  description$p: description$p,
  duration$p: duration$p,
  skills$p: skills$p,
  territory$p: territory$p,
  assignee$p: assignee$p,
  nameHint$p: nameHint$p,
  typePlaceholder$p: typePlaceholder$p,
  skillsHint$p: skillsHint$p,
  skillsPlaceholder$p: skillsPlaceholder$p,
  territoryHint$p: territoryHint$p,
  territoryPlaceholder$p: territoryPlaceholder$p
};

var Limits = {};

var styles = Template_Job_InfoModuleCss;

function Template_Job_Info(Props) {
  var template = Props.template;
  var types = Props.types;
  var setField = Props.setField;
  var changeSkills = Props.changeSkills;
  var errors = Props.errors;
  var skillUuids = Props.skillUuids;
  var territoryUuid = Props.territoryUuid;
  var changeTerritory = Props.changeTerritory;
  var availableTerritories = Props.availableTerritories;
  var setAssignees = Props.setAssignees;
  var checkTemplateName = Props.checkTemplateName;
  var possibleAssignees = Props.possibleAssignees;
  var setError = Props.setError;
  var children = Props.children;
  var match = React.useState(function () {
        return false;
      });
  var setCanValidate = match[1];
  var canValidate = match[0];
  var match$1 = AppContext.useWire();
  var ctx = match$1.ctx;
  React.useEffect((function () {
          if (Caml_obj.notequal(errors.name, [])) {
            setCanValidate(function (param) {
                  return true;
                });
          }
          
        }), [errors]);
  var onNameBlur = function (param) {
    var name = template.name;
    var trimmed = RCore.$$Option.mapOr(name, "", (function (n) {
            return n.trim();
          }));
    if (name !== undefined && trimmed !== "") {
      return checkTemplateName(trimmed, template.uuid, template.isNewRecord);
    }
    
  };
  var onNameChange = function (value) {
    setField("name", Js_null.fromOption(value));
    var trimmed = value.trim();
    if (canValidate && trimmed !== "") {
      return checkTemplateName(value, template.uuid, template.isNewRecord);
    } else if (canValidate) {
      return setError("name", [empty$p(ctx)]);
    } else {
      return ;
    }
  };
  var onDescriptionChange = function (value) {
    if (Utils.stringLengthWithNewLines(value) <= 15000) {
      return setField("description", Js_null.fromOption(value));
    }
    
  };
  var typeOptions = types.map(function (param) {
        return {
                label: param.code,
                value: param.refId
              };
      });
  var availableSkills = ctx.availableSkills.map(function (s) {
        return {
                uuid: s.uuid,
                name: s.name
              };
      });
  var nameInputId = Hooks.useId(undefined);
  var descriptionInputId = Hooks.useId(undefined);
  var durationInputId = Hooks.useId(undefined);
  var territoryInputId = Hooks.useId(undefined);
  return React.createElement(UI_Form.Section.make, {
              children: React.createElement(UI_Form.Container.make, {
                    children: null
                  }, React.createElement(UI_Label.make, {
                        htmlFor: nameInputId,
                        className: styles.nameLabelPadding,
                        children: name$p(ctx)
                      }), React.createElement(Hint.make, {
                        content: nameHint$p(ctx),
                        classes: styles.hint
                      }), React.createElement(FormInput.make, {
                        _type: "input",
                        value: RCore.$$Option.getOr(template.name, ""),
                        onValueChange: onNameChange,
                        onBlur: onNameBlur,
                        id: nameInputId,
                        name: "template[name]",
                        maxLength: 80,
                        errors: errors.name
                      }), React.createElement(UI_Form.Group.make, {
                        children: null
                      }, React.createElement(UI_Label.make, {
                            children: type$p(ctx)
                          }), React.createElement(Select.make, {
                            placeholder: typePlaceholder$p(ctx),
                            name: "template[type_id]",
                            onChange: (function (refId) {
                                setField("typeId", Js_null.bind(refId, (function (prim) {
                                            return prim;
                                          })));
                              }),
                            value: Js_null.fromOption(template.typeId),
                            autosize: false,
                            clearable: true,
                            options: typeOptions
                          })), React.createElement(FormInput.make, {
                        _type: "textarea",
                        value: RCore.$$Option.getOr(template.description, ""),
                        onValueChange: onDescriptionChange,
                        id: descriptionInputId,
                        name: "template[description]",
                        labelText: description$p(ctx),
                        maxLength: 15000
                      }), React.createElement(UI_Form.Group.make, {
                        children: null
                      }, React.createElement(UI_Label.make, {
                            htmlFor: durationInputId,
                            children: duration$p(ctx)
                          }), React.createElement(DurationSpinnerJs.make, {
                            id: durationInputId,
                            min: 5,
                            max: template.maxScheduledDuration,
                            name: "template[scheduled_duration][]",
                            step: 5,
                            defaultValue: RCore.$$Option.mapOr(template.scheduledDuration, 60, (function (d) {
                                    return d / 60 | 0;
                                  })),
                            onValueChange: (function (value) {
                                setField("scheduledDuration", Js_null.fromOption(value));
                              }),
                            disabled: false
                          })), React.createElement(UI_Form.Group.make, {
                        children: null
                      }, React.createElement(UI_Label.make, {
                            children: skills$p(ctx)
                          }), React.createElement(Hint.make, {
                            content: skillsHint$p(ctx),
                            classes: styles.hint
                          }), React.createElement(SkillsSelect.make, {
                            inputName: "template[skills]",
                            selectedSkillUuids: skillUuids,
                            onChange: (function (uuids) {
                                changeSkills(uuids, possibleAssignees);
                              }),
                            availableSkills: availableSkills,
                            placeholder: skillsPlaceholder$p(ctx),
                            disabled: false
                          }), React.createElement(Optional.make, {
                            show: !JobEdit_PossibleAssigneesUtils.hasAssigneeWithRequiredSkills(possibleAssignees, skillUuids),
                            children: React.createElement("span", {
                                  className: styles.msgWarn
                                }, noAssigneesWithSkills$p(ctx))
                          })), React.createElement(Optional.make, {
                        show: Context_Types.Features.hasFlag("territories", ctx.features),
                        children: React.createElement(UI_Form.Group.make, {
                              children: null
                            }, React.createElement(UI_Label.make, {
                                  htmlFor: territoryInputId,
                                  children: territory$p(ctx)
                                }), React.createElement(Hint.make, {
                                  content: territoryHint$p(ctx),
                                  classes: styles.hint
                                }), React.createElement(TerritoriesSelect.make, {
                                  id: territoryInputId,
                                  inputName: "template[territory]",
                                  selectedTerritoryUuid: Js_null.fromOption(territoryUuid),
                                  onChange: (function (uuid) {
                                      changeTerritory(uuid);
                                    }),
                                  availableTerritories: availableTerritories,
                                  placeholder: territoryPlaceholder$p(ctx),
                                  disabled: false
                                }), React.createElement(Optional.make, {
                                  show: territoryUuid !== undefined && JobEdit_PossibleAssigneesUtils.hasAssigneeWithRequiredTerritory(possibleAssignees, Js_null.fromOption(territoryUuid), availableTerritories),
                                  children: React.createElement("span", {
                                        className: styles.msgWarn
                                      }, noAssigneesOnTerritory$p(ctx))
                                }))
                      }), React.createElement(UI_Form.Group.make, {
                        children: null
                      }, React.createElement(UI_Label.make, {
                            children: assignee$p(ctx)
                          }), React.createElement(MultipleAssignees.make, {
                            selectedAssignees: template.assignees,
                            territoryUuid: territoryUuid,
                            skillUuids: skillUuids,
                            availableSkills: ctx.availableSkills,
                            disabled: false,
                            onChange: (function (a) {
                                setAssignees(MultipleAssignee.encode(a));
                              }),
                            openUp: false
                          })), children)
            });
}

var limits = {
  description: 15000
};

var maxInputLength = 80;

var make = Template_Job_Info;

var $$default = Template_Job_Info;

export {
  Translations ,
  Limits ,
  limits ,
  maxInputLength ,
  styles ,
  make ,
  $$default as default,
}
/* empty' Not a pure module */
