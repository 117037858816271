// extracted by mini-css-extract-plugin
export var description = "description-RIoHC82PRguS5XxZ6opi";
export var form = "form-fSX7Pz9UwX3Grb3s9xOg";
export var formBody = "form-body-JUNeJDJAm589O4LKW5H5";
export var formFooter = "form-footer-LnGU3klUq182rlEjEk1f";
export var formHeader = "form-header-CqlzpA_1a5FqMyy5QBzA";
export var noTerritories = "no-territories-XEAUoqEIRBxEwIMzhVbu";
export var noTerritoriesContainer = "no-territories-container-Ftymr6725nEElaLcE6rc";
export var tags = "tags-NkTN0XsWfTIxDgqEpCkb";
export var teaserWrapper = "teaser-wrapper-kpsdn2qHCIQqbOLEthEA";
export var territoriesList = "territories-list-J2lsc4qQ4TQtdEB5QkuH";