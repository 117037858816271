

import * as Url from "../../../../../bindings/url/Url.mjs";
import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Types from "../../../../../types/Types.mjs";
import * as JsonDecode from "../../../../../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils_IsImage from "../../../../../utils/Utils_IsImage.mjs";
import * as Backend_Errors from "../../../../../libraries/backend/Backend_Errors.mjs";
import * as JobEdit_JobType from "./JobEdit_JobType.mjs";

function fromString(str) {
  switch (str) {
    case "canceled" :
        return "Canceled";
    case "created" :
        return "Created";
    case "deleted" :
        return "Deleted";
    case "updated" :
        return "Updated";
    default:
      return ;
  }
}

var decode = JsonDecode.fromVariant(JsonDecode.string, fromString);

var Status = {
  fromString: fromString,
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              serialNo: field.required("serialNo", JsonDecode.$$int),
              status: field.required("status", decode)
            };
    });

var PostResponse = {
  Status: Status,
  decode: decode$1
};

var FieldError = {};

var Validation = {
  FieldError: FieldError
};

var Payload = {};

function toJs(param) {
  return {
          resolutionUuid: Uuid.toString(param[0]),
          comment: param[1]
        };
}

var CancelJob = {
  toJs: toJs
};

var empty_customFields = [];

var empty = {
  job: /* [] */0,
  response: Backend_Errors.empty,
  customFields: empty_customFields
};

var SubmitWorkflowError = {};

function make(uuid) {
  return {
          requestUuid: uuid
        };
}

var decode$2 = JsonDecode.object(function (field) {
      return {
              requestUuid: field.required("uuid", Uuid.decode)
            };
    });

var PostResponse$1 = {
  make: make,
  decode: decode$2
};

function fromString$1(x) {
  switch (x) {
    case "complete" :
        return "Complete";
    case "failed" :
        return "Failed";
    case "pending" :
        return "Pending";
    case "postprocessing" :
        return "PostProcessing";
    case "processed" :
        return "Processed";
    case "processing" :
        return "Processing";
    default:
      return ;
  }
}

var decode$3 = JsonDecode.fromVariant(JsonDecode.string, fromString$1);

var decode$4 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              status: field.required("status", decode$3),
              url: RCore.$$Option.flatten(field.optional("url", JsonDecode.option(Url.decode))),
              errors: RCore.$$Option.flatten(field.optional("errors", JsonDecode.option(JsonDecode.array(JsonDecode.string))))
            };
    });

function make$1(workerUuids, period, except) {
  return {
          workerUuids: workerUuids,
          period: period,
          except: except
        };
}

var OverlapRequestParams = {
  make: make$1
};

var decode$5 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              serialNo: field.required("serialNo", Types.Id.decodeOpaque)
            };
    });

var Job = {
  decode: decode$5
};

var OverlappingJobs = {
  Job: Job
};

function make$2(workerUuids, period) {
  return {
          workerUuids: workerUuids,
          period: period
        };
}

var ShiftsRequestParams = {
  make: make$2
};

var decode$6 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var $$Worker = {
  decode: decode$6
};

var OutOfShiftWorkers = {
  $$Worker: $$Worker
};

function count(reportFields) {
  return reportFields.flatMap(function (field) {
              var match = field.value;
              switch (match.TAG) {
                case "File" :
                case "Image" :
                    break;
                default:
                  return [];
              }
              return RCore.$$Array.filterMap(match._0, (function (file) {
                            var url = file.url;
                            if (url !== undefined && Utils_IsImage.isImage(url)) {
                              return Caml_option.some(undefined);
                            }
                            
                          }));
            }).length;
}

var ReportFieldsImages = {
  count: count
};

function fromOption(value) {
  if (value !== undefined) {
    return {
            TAG: "Data",
            _0: Caml_option.valFromOption(value)
          };
  } else {
    return "Delete";
  }
}

function encodeIfChanged(value, encoder) {
  if (value !== undefined) {
    if (typeof value !== "object") {
      return null;
    } else {
      return encoder(value._0);
    }
  }
  
}

var Change = {
  fromOption: fromOption,
  encodeIfChanged: encodeIfChanged
};

var empty_customFields$1 = [];

var empty_reportFields = [];

var empty$1 = {
  resolutionUuid: undefined,
  typeUuid: undefined,
  resolutionComment: undefined,
  odometerM: undefined,
  territoryUuid: undefined,
  scheduledAt: undefined,
  scheduledDuration: undefined,
  clientSite: undefined,
  description: undefined,
  address: undefined,
  apartment: undefined,
  floor: undefined,
  entranceNo: undefined,
  addressDescription: undefined,
  skillUuids: undefined,
  assignees: undefined,
  contacts: undefined,
  customFields: empty_customFields$1,
  reportFields: empty_reportFields,
  orderedServices: undefined,
  providedServices: undefined
};

var ChangedData = {
  Change: Change,
  empty: empty$1
};

var Job$1 = {
  ExternalLink: JobEdit_JobType.ExternalLink,
  Status: JobEdit_JobType.Status,
  Resolution: JobEdit_JobType.Resolution,
  PrintLayout: JobEdit_JobType.PrintLayout,
  NextJob: JobEdit_JobType.NextJob,
  CompletionJob: JobEdit_JobType.CompletionJob,
  Assignees: JobEdit_JobType.Assignees,
  Field: JobEdit_JobType.Field,
  ReportField: JobEdit_JobType.ReportField,
  CustomField: JobEdit_JobType.CustomField,
  JobType: JobEdit_JobType.JobType,
  Skill: JobEdit_JobType.Skill,
  Client: JobEdit_JobType.Client,
  Site: JobEdit_JobType.Site,
  ClientSite: JobEdit_JobType.ClientSite,
  PossibleAssignee: JobEdit_JobType.PossibleAssignee,
  AvailableService: JobEdit_JobType.AvailableService,
  ProvidedService: JobEdit_JobType.ProvidedService,
  OrderedService: JobEdit_JobType.OrderedService,
  decode: JobEdit_JobType.decode,
  PostResponse: PostResponse
};

var $$History;

var Form_Errors = {
  empty: empty,
  fromJson: (function (prim) {
      return prim;
    }),
  toJson: (function (prim) {
      return prim;
    })
};

var Form = {
  Payload: Payload,
  CancelJob: CancelJob,
  Errors: Form_Errors,
  SubmitWorkflowError: SubmitWorkflowError
};

var PrintRequest_GetResponse = {
  Status: {},
  decode: decode$4
};

var PrintRequest = {
  PostResponse: PostResponse$1,
  GetResponse: PrintRequest_GetResponse
};

export {
  Job$1 as Job,
  $$History ,
  Validation ,
  Form ,
  PrintRequest ,
  OverlapRequestParams ,
  OverlappingJobs ,
  ShiftsRequestParams ,
  OutOfShiftWorkers ,
  ReportFieldsImages ,
  ChangedData ,
}
/* decode Not a pure module */
