

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Types from "../../../types/Types.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Billing_Types from "./Billing_Types.mjs";

var availableStandardType_name = Billing_Types.PlanName.fromString("Standard");

var availableStandardType_id = Types.Id.fromInt(4);

var availableStandardType_features = {
  features: [
    "teams",
    "territories"
  ],
  smsPerSeat: 100,
  seatsLimit: undefined
};

var availableStandardType_combinedFeatures = {
  features: [
    "teams",
    "territories"
  ],
  smsPerSeat: 100,
  seatsLimit: undefined
};

var availableStandardType_seatPrice = Billing_Types.Money.make(1200, "usd");

var availableStandardType_periods = [
  {
    monthsCycle: 1,
    discountPercent: Billing_Types.Percent.fromInt(0)
  },
  {
    monthsCycle: 3,
    discountPercent: Billing_Types.Percent.fromInt(5)
  }
];

var availableStandardType = {
  name: availableStandardType_name,
  kind: "regular",
  id: availableStandardType_id,
  features: availableStandardType_features,
  combinedFeatures: availableStandardType_combinedFeatures,
  seatPrice: availableStandardType_seatPrice,
  periods: availableStandardType_periods
};

var proType_name = Billing_Types.PlanName.fromString("Pro+");

var proType_id = Types.Id.fromInt(14);

var proType_features = {
  features: [
    "api",
    "amocrm",
    "bitrix24",
    "shifts",
    "teams",
    "territories"
  ],
  smsPerSeat: 100,
  seatsLimit: undefined
};

var proType_combinedFeatures = {
  features: [
    "api",
    "amocrm",
    "bitrix24",
    "shifts",
    "teams",
    "territories"
  ],
  smsPerSeat: 100,
  seatsLimit: undefined
};

var proType_seatPrice = Billing_Types.Money.make(1200, "usd");

var proType_periods = [
  {
    monthsCycle: 1,
    discountPercent: Billing_Types.Percent.fromInt(0)
  },
  {
    monthsCycle: 3,
    discountPercent: Billing_Types.Percent.fromInt(5)
  }
];

var proType = {
  name: proType_name,
  kind: "regular",
  id: proType_id,
  features: proType_features,
  combinedFeatures: proType_combinedFeatures,
  seatPrice: proType_seatPrice,
  periods: proType_periods
};

var order_planTypeId = availableStandardType_id;

var order_organizationName = "Cool job, awesome results LTD";

var order_billingAddress = "Illinois, Chicago, Lake Shore Drive 175";

var order_extraFeatures = {
  features: [],
  smsPerSeat: undefined,
  seatsLimit: undefined
};

var order = {
  planTypeId: order_planTypeId,
  paidSeats: 42,
  freeSeats: 0,
  monthsCycle: 1,
  organizationName: order_organizationName,
  billingAddress: order_billingAddress,
  paymentMethod: "Invoice",
  currency: "usd",
  technicalDiscount: undefined,
  saleDiscount: undefined,
  extraFeatures: order_extraFeatures
};

var state_availablePlanTypes = [];

var state_subscription = {
  uuid: Uuid.fromString("13c0a6ea-6491-4f62-8230-9fab4d25c179"),
  kind: "regular",
  nextBilling: "2000-01-01T00:00:00+00:00",
  billingDay: 1,
  lastDayOfMonth: false,
  paidTill: "2000-01-01T00:00:00+00:00",
  nextBill: "2000-01-01T00:00:00+00:00",
  nextBillDue: "2200-01-01T00:00:00+00:00",
  paidSeats: 42,
  freeSeats: 0,
  planTypeId: availableStandardType_id,
  monthsCycle: 1,
  monthlyPayment: {
    amount: 0,
    currency: "usd"
  },
  extraFeatures: {
    features: [],
    smsPerSeat: undefined,
    seatsLimit: undefined
  }
};

var state_unpaidBill = {
  uuid: Uuid.fromString("4536c676-6059-4ab0-9f5c-019139095873"),
  no: "789123456789",
  totalAmount: {
    amount: 1200,
    currency: "usd"
  },
  periodStart: "2000-01-01T00:00:00+00:00",
  periodEnd: "2200-01-01T00:00:00+00:00",
  due: "2200-01-01T00:00:00+00:00",
  status: "Due"
};

var state_latestPayments = [
  {
    uuid: Uuid.fromString("a05b67eb-4491-44ab-a416-23d8dacc001c"),
    status: "Succeeded",
    paymentScreenFileUuid: Caml_option.some(Uuid.fromString("f67ba4b7-2996-43cb-b1aa-e2e93c5cb933")),
    billUuid: Caml_option.some(Uuid.fromString("007a11c2-aa17-4254-9428-ebec4968c5b0")),
    billNo: "27322633",
    billScreenFileUuid: Caml_option.some(Uuid.fromString("f67ba4b7-2996-43cb-b1aa-e2e93c5cb933")),
    billPaymentAccount: {
      accountType: "Paddle"
    },
    invoiceUuid: Caml_option.some(Uuid.fromString("f67ba4b7-2996-43cb-b1aa-e2e93c5cb933")),
    invoiceNo: "789123456789",
    invoiceScreenFileUuid: Caml_option.some(Uuid.fromString("f67ba4b7-2996-43cb-b1aa-e2e93c5cb933")),
    invoicePaymentAccount: {
      accountType: "Paddle"
    },
    amount: Billing_Types.Money.make(1200, "usd"),
    createdAt: "1999-12-01T00:00:00+00:00"
  },
  {
    uuid: Uuid.fromString("55d44238-f936-40c6-9f54-49efa0894a79"),
    status: "Prepared",
    paymentScreenFileUuid: Caml_option.some(Uuid.fromString("f67ba4b7-2996-43cb-b1aa-e2e93c5cb933")),
    billUuid: Caml_option.some(Uuid.fromString("f67ba4b7-2996-43cb-b1aa-e2e93c5cb933")),
    billNo: "27322618",
    billScreenFileUuid: Caml_option.some(Uuid.fromString("f67ba4b7-2996-43cb-b1aa-e2e93c5cb933")),
    billPaymentAccount: {
      accountType: "BankRub"
    },
    invoiceUuid: Caml_option.some(Uuid.fromString("f67ba4b7-2996-43cb-b1aa-e2e93c5cb933")),
    invoiceNo: "789123456789",
    invoiceScreenFileUuid: Caml_option.some(Uuid.fromString("f67ba4b7-2996-43cb-b1aa-e2e93c5cb933")),
    invoicePaymentAccount: {
      accountType: "BankRub"
    },
    amount: Billing_Types.Money.make(180000, "rub"),
    createdAt: "1999-11-01T00:00:00+00:00"
  }
];

var state_availablePaymentMethods = [
  "Invoice",
  "Card"
];

var state_actualPriceList = {
  currency: "rub",
  prices: {
    features: []
  }
};

var state = {
  availablePlanTypes: state_availablePlanTypes,
  subscription: state_subscription,
  order: order,
  currentSeats: 42,
  enterprise: undefined,
  free: undefined,
  unpaidBill: state_unpaidBill,
  latestPayments: state_latestPayments,
  canChangePlan: true,
  card: undefined,
  availablePaymentMethods: state_availablePaymentMethods,
  cryptoChillSettings: undefined,
  paddleSettings: undefined,
  paddleSubscription: undefined,
  actualPriceList: state_actualPriceList
};

export {
  availableStandardType ,
  proType ,
  order ,
  state ,
}
/* availableStandardType Not a pure module */
