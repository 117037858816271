

import * as RCore from "../../../libraries/RCore.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Billing_Types from "./Billing_Types.mjs";

function countSeats(order) {
  return order.paidSeats;
}

function featuresSeatPrice(features, freeFeatures, priceList) {
  var missingFeatures = features.features.filter(function (feature) {
        return !freeFeatures.features.includes(feature);
      });
  return RCore.$$Array.reduce(missingFeatures, Billing_Types.Money.zero(priceList.currency), (function (acc, feature) {
                var featurePrice = priceList.prices.features.find(function (param) {
                      return param.feature === feature;
                    });
                if (featurePrice !== undefined) {
                  return Billing_Types.Money.add(acc, featurePrice.price);
                } else {
                  return acc;
                }
              }));
}

function seatPriceForOrder(currentSubscriptionPlanTypeId, planType, order, seats, freeFeatures, priceList) {
  var featuresSeatPrice$1 = featuresSeatPrice(order.extraFeatures, freeFeatures, priceList);
  var seatWithFeaturesPrice = Billing_Types.Money.add(planType.seatPrice, featuresSeatPrice$1);
  var periodDiscount = RCore.$$Option.map(planType.periods.find(function (p) {
            return p.monthsCycle === order.monthsCycle;
          }), (function (p) {
          return p.discountPercent;
        }));
  var sd = order.saleDiscount;
  var saleDiscountPercent = sd !== undefined ? Caml_option.some(Billing_Types.SaleDiscount.forSeats(Caml_option.valFromOption(sd), seats)) : undefined;
  var match = order.technicalDiscount;
  var technicalDiscount;
  if (match !== undefined) {
    var td = Caml_option.valFromOption(match);
    technicalDiscount = currentSubscriptionPlanTypeId !== undefined && !Caml_obj.equal(Caml_option.valFromOption(currentSubscriptionPlanTypeId), planType.id) ? undefined : Caml_option.some(td);
  } else {
    technicalDiscount = undefined;
  }
  return Billing_Types.Money.applyDiscounts(seatWithFeaturesPrice, RCore.$$Array.keepSome([
                  periodDiscount,
                  technicalDiscount,
                  saleDiscountPercent
                ]));
}

function currentOrderPrice(currentSubscriptionPlanTypeId, order, planType, priceList) {
  return seatPriceForOrder(currentSubscriptionPlanTypeId, planType, order, order.paidSeats, planType.features, priceList);
}

function monthlyAmount(currentSubscriptionPlanTypeId, currentOrder, seats, planType, priceList) {
  var price = seatPriceForOrder(currentSubscriptionPlanTypeId, planType, currentOrder, seats, planType.features, priceList);
  return Billing_Types.Money.mult(price, seats);
}

function billAmount(currentSubscriptionPlanTypeId, currentOrder, seats, planType, priceList) {
  var ma = monthlyAmount(currentSubscriptionPlanTypeId, currentOrder, seats, planType, priceList);
  if (ma === undefined) {
    return ;
  }
  var months = Billing_Types.Order.Cycle.toInt(currentOrder.monthsCycle);
  return Billing_Types.Money.mult(ma, months);
}

var Types;

export {
  Types ,
  countSeats ,
  currentOrderPrice ,
  monthlyAmount ,
  billAmount ,
}
/* Billing_Types Not a pure module */
