

import * as El from "../../../../libraries/El.mjs";
import * as Fun from "../../../../libraries/Fun.mjs";
import * as Note from "../../../common/Note/Note.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Route from "../../../../types/Route.mjs";
import * as Types from "../../../../types/Types.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Script from "../../../../Script.mjs";
import * as Spinner from "../../../common/Spinner/Spinner.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Billing_Math from "../Billing_Math.mjs";
import * as Billing_Types from "../Billing_Types.mjs";
import * as Billing_Utils from "../Billing_Utils.mjs";
import * as Billing_Components_Paddle from "./Billing_Components_Paddle.mjs";
import * as BillingModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/billing/Billing.module.css";

function date$p(v, w) {
  return Utils.Translations.dateFromDate(Utils.Translations.dateFromIsoStr(v), w);
}

var styles = BillingModuleCss;

var plan$p = Utils.Translations.tr("js.billing.show.plan");

var currentPlan$p = Utils.Translations.tr("js.billing.order.current_plan");

var planSince$p = Utils.Translations.txr("js.billing.order.plan_since");

var change$p = Utils.Translations.tr("js.billing.show.change");

var extraFeatures$p = Utils.Translations.tx("js.billing.show.extra_features_ordered");

function planName$p(param, ctx) {
  var suffix = param.suffix;
  var name = Utils.Translations.t("js.billing.plan_name." + param.code)(ctx);
  var fullName = suffix !== undefined ? name + " (" + suffix + ")" : name;
  return Utils.Translations.tx("js.billing.plan_named")({
              name: fullName
            }, ctx);
}

var total$p = Utils.Translations.tr("js.billing.show.total");

var perMonth$p = Utils.Translations.tr("js.billing.show.per_month");

function monthlyTotal$p(amount, ctx) {
  return React.createElement("span", undefined, total$p(ctx), El.space, Billing_Utils.Format.money$p(amount), El.space, perMonth$p(ctx));
}

var payment$p = Utils.Translations.tr("js.billing.order.payment");

var everyNMonth$p = Utils.Translations.txh("js.billing.order.every_n_month");

var everyYear$p = Utils.Translations.th("js.billing.order.every_year");

function Billing_Components_Blocks$Order(Props) {
  var onChange = Props.onChange;
  var availablePlanTypes = Props.availablePlanTypes;
  var $staropt$star = Props.current;
  var startsAt = Props.startsAt;
  var order = Props.order;
  var monthlyPayment = Props.monthlyPayment;
  var wire = Props.wire;
  var actualPriceList = Props.actualPriceList;
  var currentSubscriptionPlanTypeId = Props.currentSubscriptionPlanTypeId;
  var ctx = wire.ctx;
  var current = $staropt$star !== undefined ? $staropt$star : false;
  var planType = RCore.$$Array.getBy(availablePlanTypes, (function (t) {
          return Types.Id.equal(t.id, order.planTypeId);
        }));
  if (planType === undefined) {
    return null;
  }
  var text;
  if (startsAt !== undefined) {
    var at = Caml_option.valFromOption(startsAt);
    text = (function (ctx) {
        return planSince$p({
                    since: Utils.Translations.dateFromDate(at, wire)
                  }, ctx);
      });
  } else {
    text = current ? currentPlan$p : plan$p;
  }
  var amount = monthlyPayment !== undefined ? monthlyPayment : Billing_Math.monthlyAmount(currentSubscriptionPlanTypeId, order, Billing_Math.countSeats(order), planType, actualPriceList);
  var size = order.extraFeatures.features.length;
  var amount$1 = monthlyPayment !== undefined ? Billing_Types.Money.mult(monthlyPayment, Billing_Types.Order.Cycle.toInt(order.monthsCycle)) : Billing_Math.billAmount(currentSubscriptionPlanTypeId, order, Billing_Math.countSeats(order), planType, actualPriceList);
  var tmp;
  if (amount$1 !== undefined) {
    var cycle = order.monthsCycle;
    tmp = cycle === 1 ? null : React.createElement("div", {
            className: "col-md-4"
          }, payment$p(ctx), El.space, Billing_Utils.Format.money$p(amount$1), El.space, cycle === 12 ? everyYear$p(ctx) : everyNMonth$p({
                  count: Billing_Types.Order.Cycle.toInt(cycle)
                }, ctx));
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: styles.billingBlock
            }, React.createElement("div", {
                  className: El.Cn.concat("row", styles.title)
                }, React.createElement("div", {
                      className: "col-md-6"
                    }, React.createElement("h4", undefined, text(ctx))), onChange !== undefined ? React.createElement("div", {
                        className: El.Cn.concat("col-md-6", styles.change)
                      }, React.createElement("a", {
                            href: Route.pagePath(undefined, "Billing"),
                            onClick: onChange
                          }, change$p(ctx))) : null), React.createElement("div", {
                  className: El.Cn.concat("row", styles.content)
                }, amount !== undefined ? React.createElement("div", {
                        className: "col-md-4"
                      }, React.createElement("strong", undefined, monthlyTotal$p(amount, ctx))) : null, React.createElement("div", {
                      className: "col-md-4"
                    }, planName$p(planType.name, ctx), size > 0 ? " + " + extraFeatures$p({
                            count: size
                          }, ctx) : null), tmp));
}

var organizationName$p = Utils.Translations.txr("js.billing.payment_method.organization_name");

var paymentByInvoice$p = Utils.Translations.tr("js.billing.show.payment_by_invoice");

var billingDayLast$p = Utils.Translations.tr("js.billing.subscription.billing_day_last");

var billingDay$p = Utils.Translations.txr("js.billing.subscription.billing_day");

function billingPeriod$p(p, ctx) {
  return Utils.Translations.tr("js.billing.subscription.billing_period.months_" + p)(ctx);
}

var nextCharge$p = Utils.Translations.txr("js.billing.subscription.next_charge");

var nextBilling$p = Utils.Translations.txr("js.billing.subscription.next_billing_day");

var billTerms$p = Utils.Translations.th("js.billing.subscription.bill_terms");

var conctactSupport$p = Utils.Translations.tr("js.billing.wizard.contact_support");

function Billing_Components_Blocks$PaymentMethod(Props) {
  var subscription = Props.subscription;
  var order = Props.order;
  var wire = Props.wire;
  var paddleSubscription = Props.paddleSubscription;
  var paddleSettings = Props.paddleSettings;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setPaddleLoaded = match[1];
  var match$1 = order.paymentMethod;
  var tmp;
  switch (match$1) {
    case "Card" :
        tmp = React.createElement(Script.make, {
              src: "https://cdn.paddle.com/paddle/paddle.js",
              root: wire.footer,
              onload: (function (param) {
                  setPaddleLoaded(function (param) {
                        return true;
                      });
                })
            });
        break;
    case "Invoice" :
    case "Crypto" :
        tmp = null;
        break;
    
  }
  var match$2 = order.paymentMethod;
  var tmp$1;
  switch (match$2) {
    case "Invoice" :
        var name = order.organizationName;
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("strong", undefined, paymentByInvoice$p(ctx)), React.createElement("div", {
                  className: styles.billingDetails
                }, name !== undefined ? organizationName$p({
                        name: name
                      }, ctx) : null));
        break;
    case "Card" :
        if (match[0]) {
          var match$3 = Fun.both(paddleSubscription, paddleSettings);
          if (match$3 !== undefined) {
            var account = match$3[0];
            tmp$1 = React.createElement(Billing_Components_Paddle.UpdateCard.make, {
                  settings: match$3[1],
                  updateLink: account.updateUrl,
                  cancelLink: account.cancelUrl,
                  details: account.paymentInformation
                });
          } else {
            tmp$1 = RCore.$$Option.isSome(subscription) ? conctactSupport$p(ctx) : null;
          }
        } else {
          tmp$1 = React.createElement(Spinner.make, {});
        }
        break;
    case "Crypto" :
        tmp$1 = null;
        break;
    
  }
  var tmp$2;
  if (subscription !== undefined) {
    var nextBilling = subscription.nextBilling;
    var match$4 = order.paymentMethod;
    var tmp$3;
    var exit = 0;
    switch (match$4) {
      case "Card" :
          tmp$3 = nextCharge$p({
                next_charge: date$p(nextBilling, wire)
              }, ctx);
          break;
      case "Invoice" :
      case "Crypto" :
          exit = 1;
          break;
      
    }
    if (exit === 1) {
      tmp$3 = React.createElement(React.Fragment, undefined, nextBilling$p({
                date: date$p(nextBilling, wire)
              }, ctx), React.createElement("br", undefined), React.createElement("br", undefined), billTerms$p(ctx));
    }
    tmp$2 = React.createElement("div", {
          className: El.Cn.concat("row", styles.subscriptionInfo)
        }, React.createElement("div", {
              className: "col-md-12"
            }, subscription.lastDayOfMonth ? billingDayLast$p(ctx) : billingDay$p({
                    day: subscription.billingDay
                  }, ctx), React.createElement("br", undefined), billingPeriod$p(Billing_Types.Order.Cycle.toInt(order.monthsCycle).toString(), ctx), React.createElement("br", undefined), tmp$3));
  } else {
    tmp$2 = null;
  }
  return React.createElement("div", {
              className: styles.paymentMethodBlock
            }, tmp, React.createElement("div", {
                  className: El.Cn.concat("row", styles.content)
                }, React.createElement("div", {
                      className: "col-md-12"
                    }, tmp$1)), tmp$2);
}

var trialEnded$p = Utils.Translations.tr("js.billing.trial_ended.trial_ended");

var choosePlanToContinue$p = Utils.Translations.tr("js.billing.trial_ended.choose_plan");

function choosePlanToContinueWhiteLabel$p(name, ctx) {
  return Utils.Translations.txr("js.billing.trial_ended.choose_plan_white_label")({
              name: name
            }, ctx);
}

function Billing_Components_Blocks$TrialEnded(Props) {
  var ctx = Props.ctx;
  var match = ctx.trial;
  if (match === undefined) {
    return null;
  }
  if (!match.expired) {
    return null;
  }
  var match$1 = ctx.branding;
  var tmp;
  if (match$1 !== undefined) {
    var brandName = match$1.brandName;
    tmp = brandName !== undefined ? choosePlanToContinueWhiteLabel$p(brandName, ctx) : choosePlanToContinue$p(ctx);
  } else {
    tmp = choosePlanToContinue$p(ctx);
  }
  return React.createElement(Note.make, {
              title: trialEnded$p(ctx),
              children: tmp,
              flavor: "danger"
            });
}

var availableTill$p = Utils.Translations.txr("js.billing.trial_ends.available_till");

var trialIsActive$p = Utils.Translations.tr("js.billing.trial_ends.trial_is_active");

function trialIsActiveWhiteLabel$p(name, ctx) {
  return Utils.Translations.txr("js.billing.trial_ends.trial_is_active_white_label")({
              name: name
            }, ctx);
}

function Billing_Components_Blocks$TrialEnds(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  var match = ctx.trial;
  if (match === undefined) {
    return null;
  }
  var expiresAt = match.expiresAt;
  if (expiresAt === undefined) {
    return null;
  }
  if (match.expired) {
    return null;
  }
  var match$1 = ctx.branding;
  var tmp;
  if (match$1 !== undefined) {
    var brandName = match$1.brandName;
    tmp = brandName !== undefined ? trialIsActiveWhiteLabel$p(brandName, ctx) : trialIsActive$p(ctx);
  } else {
    tmp = trialIsActive$p(ctx);
  }
  return React.createElement(Note.make, {
              title: availableTill$p({
                    expires_at: date$p(expiresAt, wire)
                  }, ctx),
              children: tmp,
              flavor: "warning"
            });
}

var onFreePlan$p = Utils.Translations.tr("js.billing.free_plan.on_free_plan");

var paymentQuestions$p = Utils.Translations.th("js.billing.show.questions");

function Billing_Components_Blocks$OnFreePlan(Props) {
  var subscription = Props.subscription;
  var ctx = Props.ctx;
  if (subscription !== undefined) {
    return React.createElement(Note.make, {
                title: onFreePlan$p(ctx),
                children: paymentQuestions$p(ctx),
                flavor: "success"
              });
  } else {
    return null;
  }
}

var Types$1;

var Order = {
  make: Billing_Components_Blocks$Order
};

var PaymentMethod = {
  make: Billing_Components_Blocks$PaymentMethod
};

var TrialEnded = {
  make: Billing_Components_Blocks$TrialEnded
};

var TrialEnds = {
  make: Billing_Components_Blocks$TrialEnds
};

var OnFreePlan = {
  make: Billing_Components_Blocks$OnFreePlan
};

export {
  Types$1 as Types,
  Order ,
  PaymentMethod ,
  TrialEnded ,
  TrialEnds ,
  OnFreePlan ,
}
/* styles Not a pure module */
