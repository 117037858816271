

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Shared_Lib_IO from "../../shared/lib/Shared_Lib_IO.mjs";

function setItem(key, json) {
  return Shared_Lib_IO.suspend(function () {
              localStorage.setItem(key, JSON.stringify(json));
            });
}

function getItem(key) {
  return Shared_Lib_IO.map(Shared_Lib_IO.suspend(function () {
                  return Caml_option.nullable_to_opt(localStorage.getItem(key));
                }), (function (result) {
                if (result === undefined) {
                  return ;
                }
                try {
                  return JSON.parse(result);
                }
                catch (exn){
                  return ;
                }
              }));
}

function removeItem(key) {
  return Shared_Lib_IO.suspend(function () {
              localStorage.removeItem(key);
            });
}

function clear() {
  return Shared_Lib_IO.suspend(function (prim) {
              localStorage.clear();
            });
}

export {
  setItem ,
  getItem ,
  removeItem ,
  clear ,
}
/* No side effect */
