

import * as El from "../../../../../../libraries/El.mjs";
import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../../../common/Optional.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as CurrencyText from "../../../../../inputs/CurrencyText.mjs";
import * as JobForm_ServicesModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/services/JobForm_Services.module.css";

var styles = JobForm_ServicesModuleCss;

var inputPlaceholder$p = Utils.Translations.t("js.jobs.services.placeholder");

var emptyFilter$p = Utils.Translations.tr("js.jobs.services.empty_filter");

var itemFromCategory$p = Utils.Translations.txr("js.jobs.services.item_from_category");

function JobForm_ServicesSelect(Props) {
  var onSelect = Props.onSelect;
  var availableServices = Props.availableServices;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var inputId = Hooks.useId(undefined);
  var match$1 = React.useState(function () {
        return false;
      });
  var setFocused = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setFilterQuery = match$2[1];
  var filterQuery = match$2[0];
  var filterByQuery = function (list) {
    if (filterQuery !== undefined) {
      return RCore.$$Array.keep(list, (function (item) {
                    return Js_string.includes(filterQuery.toLowerCase(), item.name.toLowerCase());
                  }));
    } else {
      return list;
    }
  };
  var onQueryChange = function ($$event) {
    var value = $$event.target.value;
    setFilterQuery(function (param) {
          return Fun.optString(value);
        });
  };
  var listItems = filterByQuery(availableServices);
  var renderItem = function (serv) {
    var match = serv.priceDefault;
    var match$1 = serv.description;
    var match$2 = serv.category;
    return React.createElement("div", {
                key: Uuid.toString(serv.serviceUuid),
                className: styles.option,
                onClick: (function (e) {
                    e.preventDefault();
                    onSelect(serv);
                  })
              }, React.createElement("div", undefined, serv.name), React.createElement("div", {
                    className: styles.description
                  }, match !== undefined ? React.createElement(CurrencyText.make, {
                          ctx: ctx,
                          currency: serv.currency,
                          value: match.toString()
                        }) : (
                      match$1 !== undefined ? match$1 : (
                          match$2 !== undefined ? itemFromCategory$p({
                                  name: match$2.name
                                }, ctx) : null
                        )
                    )));
  };
  return React.createElement("div", {
              className: styles.inputContainer
            }, React.createElement(Optional.make, {
                  show: match$1[0],
                  children: React.createElement("div", {
                        className: styles.options
                      }, listItems.length === 0 ? React.createElement("div", {
                              className: styles.emptyFilter
                            }, emptyFilter$p(ctx)) : listItems.map(renderItem))
                }), React.createElement("input", {
                  className: El.Cn.concat(styles.input, "form-control"),
                  id: inputId,
                  autoFocus: true,
                  placeholder: inputPlaceholder$p(ctx),
                  value: RCore.$$Option.getOr(filterQuery, ""),
                  onFocus: (function (param) {
                      setFocused(function (param) {
                            return true;
                          });
                    }),
                  onChange: onQueryChange
                }));
}

var make = JobForm_ServicesSelect;

export {
  make ,
}
/* styles Not a pure module */
