

import * as El from "../../libraries/El.mjs";
import * as Route from "../../types/Route.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as HeaderTypes from "./HeaderTypes.mjs";
import * as HeaderModuleCss from "/home/runner/work/planado/planado/client/rescript/components/header/Header.module.css";

var schedule$p = Utils.Translations.tr("js.menu.sections.schedule.title");

var map$p = Utils.Translations.tr("js.menu.sections.map");

var addresses$p = Utils.Translations.tr("js.menu.sections.addresses");

var teams$p = Utils.Translations.tr("js.menu.sections.teams");

var shifts$p = Utils.Translations.tr("js.menu.sections.shifts");

var settings$p = Utils.Translations.tr("js.menu.sections.settings");

var general$p = Utils.Translations.tr("js.menu.settings.general");

var jobs$p = Utils.Translations.tr("js.menu.sections.jobs");

var reports$p = Utils.Translations.tr("js.menu.sections.reports");

var templates$p = Utils.Translations.tr("js.menu.settings.templates");

var resolutions$p = Utils.Translations.tr("js.menu.settings.resolutions");

var jobTypes$p = Utils.Translations.tr("js.menu.settings.job_types");

var recurringJobs$p = Utils.Translations.tr("js.menu.settings.recurring_jobs");

var products$p = Utils.Translations.tr("js.menu.settings.products");

var services$p = Utils.Translations.tr("js.menu.settings.services");

var users$p = Utils.Translations.tr("js.menu.settings.users");

var territories$p = Utils.Translations.tr("js.menu.settings.territories");

var skills$p = Utils.Translations.tr("js.menu.settings.skills");

var dictionaries$p = Utils.Translations.tr("js.menu.settings.dictionaries");

var customFields$p = Utils.Translations.tr("js.menu.settings.custom_fields");

var auditLog$p = Utils.Translations.tr("js.menu.settings.audit_log");

var integrations$p = Utils.Translations.tr("js.menu.settings.integrations");

var printLayouts$p = Utils.Translations.tr("js.menu.settings.print_layouts");

var clientForms$p = Utils.Translations.tr("js.menu.settings.client_forms");

var clientPortal$p = Utils.Translations.tr("js.menu.settings.client_portal");

var billing$p = Utils.Translations.tr("js.menu.settings.billing");

var help$p = Utils.Translations.tr("js.menu.help");

var successPath$p = Utils.Translations.tr("js.menu.success_path");

var styles = HeaderModuleCss;

function active(path) {
  var match = path[0];
  if (typeof match === "object") {
    var variant = match.NAME;
    if (variant === "ClientPortal") {
      var match$1 = path[1];
      if (typeof match$1 !== "object" || match$1._0 !== "ClientPortal") {
        return false;
      } else {
        return true;
      }
    }
    if (variant === "CustomFields") {
      if (match.VAL !== "Users") {
        return false;
      }
      var match$2 = path[1];
      if (typeof match$2 !== "object" || match$2._0 !== "CustomFields") {
        return false;
      } else {
        return true;
      }
    }
    if (variant === "Integrations") {
      var match$3 = path[1];
      if (typeof match$3 === "object" && match$3._0 === "Integrations") {
        return true;
      }
      
    } else {
      if (variant === "PrintLayout") {
        if (match.VAL !== "Index") {
          return false;
        }
        var match$4 = path[1];
        if (typeof match$4 !== "object" || match$4._0 !== "PrintLayouts") {
          return false;
        } else {
          return true;
        }
      }
      if (variant === "Reports") {
        var tmp = path[1];
        if (typeof tmp !== "object" && tmp === "Reports") {
          return true;
        } else {
          return false;
        }
      }
      if (variant === "MapShow") {
        var tmp$1 = path[1];
        if (typeof tmp$1 !== "object" && tmp$1 === "Map") {
          return true;
        } else {
          return false;
        }
      }
      if (variant === "Job") {
        var tmp$2 = path[1];
        if (typeof tmp$2 !== "object" && tmp$2 === "Jobs") {
          return true;
        } else {
          return false;
        }
      }
      if (variant === "Template") {
        var match$5 = path[1];
        if (typeof match$5 === "object" && match$5._0 === "Templates") {
          return true;
        }
        
      } else {
        if (variant === "Schedule") {
          var tmp$3 = path[1];
          if (typeof tmp$3 !== "object" && tmp$3 === "Schedule") {
            return true;
          } else {
            return false;
          }
        }
        if (variant === "ClientForm") {
          if (match.VAL !== "Index") {
            return false;
          }
          var match$6 = path[1];
          if (typeof match$6 !== "object" || match$6._0 !== "ClientForms") {
            return false;
          } else {
            return true;
          }
        }
        if (variant === "Products") {
          var match$7 = path[1];
          if (typeof match$7 !== "object" || match$7._0 !== "Products") {
            return false;
          } else {
            return true;
          }
        }
        if (variant === "Territories") {
          if (match.VAL === "Index") {
            var match$8 = path[1];
            if (typeof match$8 === "object" && match$8._0 === "Territories") {
              return true;
            }
            
          }
          
        } else {
          if (variant === "Client" || variant === "Site") {
            var tmp$4 = path[1];
            if (typeof tmp$4 !== "object" && tmp$4 === "Addresses") {
              return true;
            } else {
              return false;
            }
          }
          if (variant === "Team") {
            var tmp$5 = path[1];
            if (typeof tmp$5 !== "object" && tmp$5 === "Teams") {
              return true;
            } else {
              return false;
            }
          }
          if (variant === "User") {
            var match$9 = path[1];
            if (typeof match$9 === "object" && match$9._0 === "Users") {
              return true;
            }
            
          } else if (variant === "Dictionary") {
            var match$10 = path[1];
            if (typeof match$10 === "object" && match$10._0 === "Dictionaries") {
              return true;
            }
            
          } else {
            if (variant === "Services") {
              var match$11 = path[1];
              if (typeof match$11 !== "object" || match$11._0 !== "Services") {
                return false;
              } else {
                return true;
              }
            }
            if (variant !== "RecurringJob") {
              return false;
            }
            var match$12 = path[1];
            if (typeof match$12 === "object" && match$12._0 === "RecurringJobs") {
              return true;
            }
            
          }
        }
      }
    }
  } else {
    if (match === "AuditLog") {
      var match$13 = path[1];
      if (typeof match$13 !== "object" || match$13._0 !== "AuditLog") {
        return false;
      } else {
        return true;
      }
    }
    if (match === "Shifts") {
      var tmp$6 = path[1];
      if (typeof tmp$6 !== "object" && tmp$6 === "Shifts") {
        return true;
      } else {
        return false;
      }
    }
    if (match === "ResolutionIndex") {
      var match$14 = path[1];
      if (typeof match$14 === "object" && match$14._0 === "Resolutions") {
        return true;
      }
      
    } else if (match === "Skills") {
      var match$15 = path[1];
      if (typeof match$15 === "object" && match$15._0 === "Skills") {
        return true;
      }
      
    } else if (match === "JobTypeIndex") {
      var match$16 = path[1];
      if (typeof match$16 === "object" && match$16._0 === "JobTypes") {
        return true;
      }
      
    } else if (match === "Billing") {
      var match$17 = path[1];
      if (typeof match$17 === "object" && match$17._0 === "Billing") {
        return true;
      }
      
    } else {
      if (match !== "GeneralSettings") {
        return false;
      }
      var match$18 = path[1];
      if (typeof match$18 === "object" && match$18._0 === "General") {
        return true;
      }
      
    }
  }
  var match$19 = path[1];
  if (typeof match$19 !== "object" || match$19._0 !== "Top") {
    return false;
  } else {
    return true;
  }
}

function opened(path) {
  var match = path[0];
  if (typeof match !== "object") {
    switch (match) {
      case "SuccessPath" :
          var match$1 = path[1];
          if (match$1 === "SuccessPath") {
            return true;
          } else {
            return false;
          }
      case "Support" :
          var match$2 = path[1];
          if (match$2 === "Support") {
            return true;
          } else {
            return false;
          }
      case "Profile" :
          var match$3 = path[1];
          if (match$3 === "Profile") {
            return true;
          } else {
            return false;
          }
      default:
        return false;
    }
  } else {
    if (match._0 !== "Top") {
      return false;
    }
    var match$4 = path[1];
    if (match$4 === "Settings") {
      return true;
    } else {
      return false;
    }
  }
}

function class_(section) {
  if (typeof section !== "object") {
    switch (section) {
      case "SuccessPath" :
      case "Support" :
      case "Profile" :
          return styles.menuDrop;
      default:
        return styles.menuItem;
    }
  } else {
    switch (section._0) {
      case "Top" :
          return styles.menuDrop;
      case "Templates" :
      case "Resolutions" :
      case "JobTypes" :
      case "RecurringJobs" :
      case "PrintLayouts" :
      case "ClientForms" :
      case "Products" :
      case "Services" :
          return styles.groupItem;
      default:
        return styles.dropItem;
    }
  }
}

function matchItemClass(route, openedSection, section) {
  return El.Cn.fromList(El.Cn.cons([
                  styles.isOpen,
                  opened([
                        section,
                        openedSection
                      ])
                ], El.Cn.cons([
                      styles.isActive,
                      active([
                            route,
                            section
                          ])
                    ], {
                      hd: [
                        class_(section),
                        true
                      ],
                      tl: {
                        hd: [
                          HeaderTypes.Section.name(section),
                          true
                        ],
                        tl: /* [] */0
                      }
                    })));
}

function linkText(section) {
  if (typeof section !== "object") {
    switch (section) {
      case "Map" :
          return map$p;
      case "Schedule" :
          return schedule$p;
      case "Jobs" :
          return jobs$p;
      case "Teams" :
          return teams$p;
      case "Shifts" :
          return shifts$p;
      case "Addresses" :
          return addresses$p;
      case "SuccessPath" :
          return successPath$p;
      case "Support" :
          return help$p;
      case "Profile" :
          return function (ctx) {
            var user = ctx.user;
            if (user !== undefined) {
              return user.name;
            } else {
              return "";
            }
          };
      case "Reports" :
          return reports$p;
      
    }
  } else {
    switch (section._0) {
      case "Top" :
          return settings$p;
      case "General" :
          return general$p;
      case "Templates" :
          return templates$p;
      case "Resolutions" :
          return resolutions$p;
      case "JobTypes" :
          return jobTypes$p;
      case "RecurringJobs" :
          return recurringJobs$p;
      case "PrintLayouts" :
          return printLayouts$p;
      case "ClientForms" :
          return clientForms$p;
      case "Users" :
          return users$p;
      case "Territories" :
          return territories$p;
      case "Skills" :
          return skills$p;
      case "Dictionaries" :
          return dictionaries$p;
      case "CustomFields" :
          return customFields$p;
      case "ClientPortal" :
          return clientPortal$p;
      case "AuditLog" :
          return auditLog$p;
      case "Integrations" :
          return integrations$p;
      case "Billing" :
          return billing$p;
      case "Products" :
          return products$p;
      case "Services" :
          return services$p;
      
    }
  }
}

function HeaderLink(Props) {
  var route = Props.route;
  var wire = Props.wire;
  var onClick = Props.onClick;
  var section = Props.section;
  var openedSection = Props.openedSection;
  var onLinkClick = function ($$event) {
    if (!active([
            wire.route.path,
            section
          ])) {
      return onClick(route)($$event);
    }
    var meta = $$event.metaKey;
    if (!meta) {
      $$event.preventDefault();
      return ;
    }
    
  };
  return React.createElement("div", {
              className: matchItemClass(wire.route.path, openedSection, section)
            }, React.createElement("a", {
                  href: Route.pagePath(undefined, route),
                  onClick: onLinkClick
                }, linkText(section)(wire.ctx)));
}

var make = HeaderLink;

export {
  matchItemClass ,
  linkText ,
  make ,
}
/* schedule' Not a pure module */
