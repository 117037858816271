

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var FilterEntity = {
  decode: decode
};

var default_templates = [];

var default_resolutions = [];

var default_types = [];

var $$default = {
  templates: default_templates,
  resolutions: default_resolutions,
  types: default_types
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              templates: field.required("templates", JsonDecode.array(decode)),
              resolutions: field.required("resolutions", JsonDecode.array(decode)),
              types: field.required("types", JsonDecode.array(decode))
            };
    });

function index(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Reports",
                    VAL: "Index"
                  }
                }), decode$1);
}

var Index = {
  $$default: $$default,
  decode: decode$1,
  index: index
};

export {
  FilterEntity ,
  Index ,
}
/* decode Not a pure module */
