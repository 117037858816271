

import * as El from "../../../../../libraries/El.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Locale from "../../../../../libraries/Locale.mjs";
import * as DomUtils from "../../../../../DomUtils.mjs";
import * as Core__Int from "@rescript/core/lib/es6/src/Core__Int.mjs";
import * as DateInput from "../../../../inputs/DateInput.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as DatePicker from "../../../../inputs/DatePicker.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobIndexFilter_DatesInputsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/job_index_filter/common/JobIndexFilter_DatesInputs.module.css";

var styles = JobIndexFilter_DatesInputsModuleCss;

function openUp(containerRef, offset) {
  var container = containerRef.current;
  if (container == null) {
    return false;
  }
  var position = DomUtils.BoundingClientRect.get(container);
  return ((position.bottom | 0) + (offset | 0) | 0) >= DomUtils.windowInnerHeight();
}

function JobIndexFilter_DatesInputs$Date(Props) {
  var date = Props.date;
  var onDateChange = Props.onDateChange;
  var disabled = Props.disabled;
  var wire = AppContext.useWire();
  var containerRef = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setFocused = match[1];
  var calenfarStyle = match[0] ? El.Cn.concat(styles.calendarPopover, styles.isActive) : styles.calendarPopover;
  var calendarCN = openUp(containerRef, 350) ? El.Cn.concat(calenfarStyle, styles.calendarDateUp) : El.Cn.concat(calenfarStyle, styles.calendarDown);
  var tmp = {
    onDateChange: (function (v) {
        onDateChange(Caml_option.some(v));
      }),
    numberOfMonths: 1,
    navPrev: React.createElement("span", undefined),
    navNext: React.createElement("span", undefined),
    daySize: 31,
    hideKeyboardShortcutsPanel: true,
    onOutsideClick: (function (evt) {
        var onBlur = function () {
          setFocused(function (param) {
                return false;
              });
        };
        var node = containerRef.current;
        if (node == null) {
          return ;
        }
        var target = evt.target;
        if (DomUtils.contains(node, target)) {
          return ;
        } else {
          return onBlur();
        }
      })
  };
  if (date !== undefined) {
    tmp.date = Caml_option.valFromOption(date);
  }
  return React.createElement("div", {
              ref: containerRef,
              className: styles.dateContainer
            }, React.createElement(DateInput.make, {
                  value: RCore.$$Option.map(date, Locale.T.fmtM("Date", wire)),
                  onFocus: (function (param) {
                      setFocused(function (param) {
                            return true;
                          });
                    }),
                  onDateChange: onDateChange,
                  disabled: disabled
                }), React.createElement("div", {
                  className: calendarCN
                }, React.createElement(DatePicker.make, tmp)));
}

var $$Date = {
  make: JobIndexFilter_DatesInputs$Date
};

function JobIndexFilter_DatesInputs$Days(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var onBlur = Props.onBlur;
  var onKeyPress = Props.onKeyPress;
  var containerClass = Props.containerClass;
  var disabled = Props.disabled;
  var label = Props.label;
  var stringValue = RCore.$$Option.mapWithDefault(value, "", (function (i) {
          return i.toString();
        }));
  return React.createElement("div", {
              className: El.Cn.concatOpt(styles.labelContainer, containerClass)
            }, React.createElement("input", {
                  className: styles.daysInput,
                  disabled: disabled,
                  min: "1",
                  type: "number",
                  value: stringValue,
                  onKeyPress: onKeyPress,
                  onBlur: onBlur,
                  onChange: (function (evt) {
                      var value = evt.target.value;
                      onChange(RCore.$$Option.flatMap(value, (function (x) {
                                  return Core__Int.fromString(x, undefined);
                                })));
                    })
                }), label);
}

var Days = {
  make: JobIndexFilter_DatesInputs$Days
};

export {
  $$Date ,
  Days ,
}
/* styles Not a pure module */
