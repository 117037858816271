

import * as El from "../libraries/El.mjs";
import * as Link from "../components/common/Link/Link.mjs";
import * as RCore from "../libraries/RCore.mjs";
import * as Utils from "../utils/Utils.mjs";
import * as React from "react";
import * as Context from "../context/Context.mjs";
import * as UI_Icon from "./UI_Icon.mjs";
import * as Optional from "../components/common/Optional.mjs";
import * as AppContext from "../context/AppContext.mjs";
import * as UI_FeatureTeaserModuleCss from "/home/runner/work/planado/planado/client/rescript/ui/UI_FeatureTeaser.module.css";

var atLeastStandard$p = Utils.Translations.tr("js.feature_teaser.at_least_standard");

var atLeastPro$p = Utils.Translations.tr("js.feature_teaser.at_least_pro");

var upgradePlan$p = Utils.Translations.tr("js.feature_teaser.upgrade_plan");

function toText(plan) {
  if (plan === "pro") {
    return atLeastPro$p;
  } else {
    return atLeastStandard$p;
  }
}

var RequiredPlan = {
  toText: toText
};

var styles = UI_FeatureTeaserModuleCss;

function UI_FeatureTeaser$Large(Props) {
  var className = Props.className;
  var description = Props.description;
  var minimalPlan = Props.minimalPlan;
  var children = Props.children;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var classes = El.Cn.concatOpt(styles.teaserDescription, className);
  return React.createElement("div", {
              className: classes
            }, description !== undefined ? React.createElement(React.Fragment, undefined, description, React.createElement("br", undefined), React.createElement("br", undefined)) : null, RCore.$$Option.mapOr(children, null, (function (el) {
                    return React.createElement(React.Fragment, undefined, el, React.createElement("br", undefined));
                  })), React.createElement("span", undefined, React.createElement(UI_Icon.make, {
                      icon: "angles-up"
                    }), " ", toText(minimalPlan)(ctx), " ", React.createElement(Optional.make, {
                      show: Context.canManageSubscription(ctx),
                      children: React.createElement("span", undefined, React.createElement(Link.make, {
                                wire: wire,
                                route: "Billing",
                                children: upgradePlan$p(ctx)
                              }), ".")
                    })));
}

var Large = {
  make: UI_FeatureTeaser$Large
};

export {
  RequiredPlan ,
  Large ,
}
/* atLeastStandard' Not a pure module */
