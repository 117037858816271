

import * as Js_null from "rescript/lib/es6/js_null.js";
import * as JobEdit_JobType from "../types/JobEdit_JobType.mjs";
import * as Possible_assignees from "planado/utils/possible_assignees";

function sortAssigneesBySkillsAndTerritories(assignees, requiredSkillUuids, requiredTerritoryUuid) {
  var updatedAssignees = Possible_assignees.sortAssigneesBySkillsAndTerritories(assignees.map(function (a) {
            return JobEdit_JobType.PossibleAssignee.makeJs(a);
          }), requiredSkillUuids, Js_null.fromOption(requiredTerritoryUuid));
  return updatedAssignees.map(function (a) {
              return JobEdit_JobType.PossibleAssignee.makeFromJs(a);
            });
}

function hasAssigneeWithRequiredSkills(prim0, prim1) {
  return Possible_assignees.hasAssigneeWithRequiredSkills(prim0, prim1);
}

function hasAssigneeWithRequiredTerritory(prim0, prim1, prim2) {
  return Possible_assignees.hasAssigneeWithRequiredTerritory(prim0, prim1, prim2);
}

export {
  hasAssigneeWithRequiredSkills ,
  hasAssigneeWithRequiredTerritory ,
  sortAssigneesBySkillsAndTerritories ,
}
/* JobEdit_JobType Not a pure module */
