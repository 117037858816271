

import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as Locale from "../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as JsonDecode from "../../../../libraries/JsonDecode.mjs";
import * as Types_Territory from "../../../../types/Types_Territory.mjs";
import * as Schedule_Types_JobCore from "./job/Schedule_Types_JobCore.mjs";

function initial(wire, view) {
  if (view.NAME === "Calendar") {
    if (view.VAL !== "Week") {
      return [
              Locale.T.Schedule.startOfCurrentMonth(wire),
              Locale.T.Schedule.endOfCurrentMonth(wire)
            ];
    }
    
  } else if (view.VAL !== "Week") {
    return [
            Locale.T.Schedule.startOfCurrentDay(wire),
            Locale.T.Schedule.endOfCurrentDay(wire)
          ];
  }
  return [
          Locale.T.Schedule.startOfCurrentWeek(wire),
          Locale.T.Schedule.endOfCurrentWeek(wire)
        ];
}

var Period = {
  initial: initial
};

function toString(filter) {
  if (filter === "Teams") {
    return "teams";
  } else {
    return "all";
  }
}

function fromString(filter) {
  switch (filter) {
    case "all" :
        return "All";
    case "teams" :
        return "Teams";
    default:
      return ;
  }
}

var possibleModes = [
  "All",
  "Teams"
];

var decode = JsonDecode.fromVariant(JsonDecode.string, fromString);

var Assignees = {
  toString: toString,
  fromString: fromString,
  possibleModes: possibleModes,
  decode: decode
};

function toString$1(mode) {
  if (mode === "Any") {
    return "any";
  } else {
    return "all";
  }
}

function fromString$1(mode) {
  switch (mode) {
    case "all" :
        return "All";
    case "any" :
        return "Any";
    default:
      return ;
  }
}

var possibleModes$1 = [
  "Any",
  "All"
];

var decode$1 = JsonDecode.fromVariant(JsonDecode.string, fromString$1);

var Mode = {
  toString: toString$1,
  fromString: fromString$1,
  possibleModes: possibleModes$1,
  decode: decode$1
};

var initial_uuids = [];

var initial$1 = {
  uuids: initial_uuids,
  mode: "Any"
};

function isEmpty(skills) {
  return Caml_obj.equal(skills.uuids, []);
}

var decode$2 = JsonDecode.object(function (field) {
      return {
              uuids: field.required("uuids", JsonDecode.array(Uuid.decode)),
              mode: field.required("mode", decode$1)
            };
    });

var Skills = {
  Mode: Mode,
  initial: initial$1,
  isEmpty: isEmpty,
  decode: decode$2
};

function initial$2(wire, view) {
  return {
          templates: [],
          territories: [],
          skills: initial$1,
          period: initial(wire, view),
          assignees: "All"
        };
}

function templates(filter) {
  return filter.templates;
}

function territories(filter) {
  return filter.territories;
}

function skillUuids(filter) {
  return filter.skills.uuids;
}

function period(filter) {
  return filter.period;
}

function isEmpty$1(param) {
  if (Caml_obj.equal(param.territories, [])) {
    return Caml_obj.equal(param.skills.uuids, []);
  } else {
    return false;
  }
}

var Filter = {
  Assignees: Assignees,
  Skills: Skills,
  initial: initial$2,
  templates: templates,
  territories: territories,
  skillUuids: skillUuids,
  period: period,
  isEmpty: isEmpty$1
};

var decode$3 = JsonDecode.object(function (field) {
      return {
              territories: field.required("territories", JsonDecode.array(Types_Territory.decode)),
              skills: field.required("skills", JsonDecode.array(JsonDecode.object(Schedule_Types_JobCore.Skill.decode)))
            };
    });

var FilterVariants = {
  decode: decode$3
};

export {
  Period ,
  Filter ,
  FilterVariants ,
}
/* decode Not a pure module */
