

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Locale from "../../libraries/Locale.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as EncodeJson from "../../libraries/EncodeJson.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Backend_Headers from "../../libraries/backend/Backend_Headers.mjs";
import * as Pages_Audit_Types from "./Pages_Audit_Types.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              logs: field.required("logs", JsonDecode.array(Pages_Audit_Types.Log.decode))
            };
    });

var $$Response = {
  decode: decode
};

function encodeCreatedAt(createdAt) {
  var from = createdAt.dateFrom;
  if (from === undefined) {
    return null;
  }
  var to = createdAt.dateTo;
  if (to !== undefined) {
    return EncodeJson.object([
                [
                  "from",
                  EncodeJson.string(Locale.T.toIsoM(Locale.T.startOf("day", Caml_option.valFromOption(from))))
                ],
                [
                  "to",
                  EncodeJson.string(Locale.T.toIsoM(Locale.T.endOf("day", Caml_option.valFromOption(to))))
                ]
              ]);
  } else {
    return null;
  }
}

function encodeParams(params) {
  var u = params.after;
  return EncodeJson.object(Belt_Array.concatMany([
                  [
                    [
                      "users",
                      EncodeJson.array(function (u) {
                              return EncodeJson.string(Uuid.toString(u));
                            })(params.filter.users)
                    ],
                    [
                      "actions",
                      EncodeJson.array(function (a) {
                              return EncodeJson.string(Pages_Audit_Types.Log.Action.toString(a));
                            })(params.filter.actions)
                    ],
                    [
                      "created_at",
                      encodeCreatedAt(params.filter.createdAt)
                    ]
                  ],
                  u !== undefined ? [[
                        "after",
                        EncodeJson.string(Uuid.toString(Caml_option.valFromOption(u)))
                      ]] : []
                ]));
}

function getViaPost(wire, params) {
  return Backend.decode(Backend.post(Backend_Headers.contentJsonOverridden, undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: "AuditLog"
                }, {
                  NAME: "Json",
                  VAL: encodeParams(params)
                }), decode);
}

var Index = {
  $$Response: $$Response,
  getViaPost: getViaPost
};

export {
  Index ,
}
/* decode Not a pure module */
